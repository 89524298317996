import { useState } from 'react';

import toast from 'react-hot-toast';

const BuyerNumberInputs = ({ formData, setFormData }) => {
	const [inputFields, setInputFields] = useState([{ buyerNumber: '' }]);

	const handleInputChange = (event, index) => {
		const values = [...inputFields];

		values[index].buyerNumber = event.target.value;

		setInputFields(values);
		setFormData({ ...formData, buyerNumbers: inputFields });
	};

	const addBuyerNumber = () => {
		if (inputFields.length === 5) {
			toast.error('Maximum of 5 Buyer Numbers allowed per request.');
		} else {
			setInputFields([...inputFields, { buyerNumber: '' }]);
			setFormData({ ...formData, buyerNumbers: [...formData.buyerNumbers, inputFields] });
		}
	};

	const removeBuyerNumber = () => {
		inputFields.pop();

		setInputFields([...inputFields]);
		setFormData({ ...formData, buyerNumbers: inputFields });
	};

	const handleControlChanges = () => {
		if (inputFields.length === 1) {
			return <input type='button' value='Add Buyer Number' className='btn btn-primary-outline mt-md' onClick={addBuyerNumber} />;
		}

		if (inputFields.length > 1 && inputFields.length < 5) {
			// Side by side
			return (
				<div className='inputs-control'>
					<input type='button' value='Add Buyer Number' className='btn-md btn-primary-outline' onClick={addBuyerNumber} />
					<input type='button' value='Remove Buyer Number' className='btn-md btn-danger-outline' onClick={removeBuyerNumber} />
				</div>
			);
		}

		if (inputFields.length === 5) {
			return <input type='button' value='Remove Buyer Number' className='btn btn-danger-outline mt-md' onClick={removeBuyerNumber} />;
		}
	};

	return (
		<div className='registration-control-container'>
			{inputFields.map((inputField, index) => {
				return (
					<div className='registration-control' key={index}>
						<label className='registration-control-label'>Buyer Number</label>
						<input
							type='text'
							className='registration-code-input'
							value={inputField.buyerNumber}
							onChange={(event) => handleInputChange(event, index)}
						/>
					</div>
				);
			})}
			{handleControlChanges()}
		</div>
	);
};

export default BuyerNumberInputs;
