import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestToken } from '../../pushNotifications';
import toast from 'react-hot-toast';
import useJwt from '../../hooks/useJwt';
import { isMobile, isBrowser } from 'react-device-detect';

const NotificationsPromptScreen = () => {
	const navigate = useNavigate();
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const jwt = useJwt();

	useEffect(
		() => {
			if (Notification.permission === 'granted') {
				navigate('/home');
			}
		},
		// eslint-disable-next-line
		[],
	);

	const enableNotifications = async () => {
		const loadingToast = toast.loading('Generating token...');
		const fcmToken = await requestToken();

		setIsButtonDisabled(true);
		toast.dismiss(loadingToast);

		if (fcmToken) {
			let postFormData = new FormData();
			let deviceDescription = '';

			if (isMobile) {
				deviceDescription = 'Phone or Tablet';
			}

			if (isBrowser) {
				deviceDescription = 'Laptop or Desktop';
			}

			postFormData.append('token', fcmToken);
			postFormData.append('uuid', jwt.data.id);
			postFormData.append('device', deviceDescription);

			const loadingToast = toast.loading('Enabling Notifications...');

			const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/add-fcm-token', {
				method: 'POST',
				body: postFormData,
			});

			const responseData = await response.json();

			toast.dismiss(loadingToast);

			if (responseData.message === 'success') {
				navigate('/home');
			} else {
				toast.error('Unable to enable notifications at this time. Please try again.');
			}
		}
	};

	const maybeLater = () => {
		localStorage.setItem('notificationsPrompt', 'Y');
		navigate('/home');
	};

	return (
		<div className='notifications-prompt-form-container'>
			<div className='notifications-prompt'>
				<h1 className='form-header'>Notifications</h1>
				<p className='notifications-prompt-info'>
					Thank you for registering to use the Market App. This application was developed with information communication in mind. In order to get the
					most out of your Market App experience, we highly recommend you enable Push Notifications.
				</p>
				<p className='notifications-prompt-info'>
					This is not mandatory, but it will greatly improve the communication between you and any of the Fresh Produce Markets you will be registered
					with.
				</p>
				<div className='notifications-prompt-actions'>
					<input
						type='button'
						className='btn-md btn-primary-solid'
						value='Enable Notifications'
						onClick={enableNotifications}
						disabled={isButtonDisabled}
					/>
					<input type='button' className='btn-md btn-danger-solid' value='Maybe Later' onClick={maybeLater} />
				</div>
			</div>
		</div>
	);
};

export default NotificationsPromptScreen;
