import { useState } from 'react';

import './Notifications.css';

import toast from 'react-hot-toast';
import FormData from 'form-data';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';
import ConfirmAlert from '../../components/ConfirmAlert';
import useJwt from '../../hooks/useJwt';

const BroadcastNotificationScreen = () => {
	const [formData, setFormData] = useState({
		title: '',
		body: '',
		isFormValid: false,
		isTitleValid: true,
		isBodyValid: true,
		isMarketChecked: false,
		isAgentChecked: false,
		isBuyerChecked: false,
		isSupplierChecked: false,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [overlayState, setOverlayState] = useState('overlay-animate-out');
	const [overlayStateBnExempt, setOverlayStateBnExempt] = useState('overlay-animate-out');
	const jwt = useJwt();

	const handleInputChange = (event) => {
		setFormData({ ...formData, [event.target.id]: event.target.value });
	};

	const validateForm = () => {
		setIsButtonDisabled(true);

		let validForm = true;
		let validTitle = true;
		let validBody = true;

		// Validate subject
		if (formData.title === '') {
			validForm = false;
			validTitle = false;
		}

		// Validate body
		if (formData.body === '') {
			validForm = false;
			validBody = false;
		}

		if (!formData.isMarketChecked && !formData.isAgentChecked && !formData.isBuyerChecked && !formData.isSupplierChecked) {
			validForm = false;
		}

		setFormData({ ...formData, isFormValid: validForm, isTitleValid: validTitle, isBodyValid: validBody });

		if (!validForm) {
			toast.error('One or more form inputs are invalid. Please check your inputs carefully and ensure you have selected recipients.');
			setIsButtonDisabled(false);
		} else {
			// submitNotificationForReview();
			if (jwt.data.bn === 'Y') {
				showConfirmAlertBnExempt();
			} else {
				showConfirmAlert();
			}
		}
	};

	const showConfirmAlert = () => {
		setOverlayState('overlay-animate-in');
	};

	const showConfirmAlertBnExempt = () => {
		setOverlayStateBnExempt('overlay-animate-in');
	};

	const cancelConfirmAlert = () => {
		setOverlayState('overlay-animate-out');
		setIsButtonDisabled(false);
	};

	const cancelConfirmAlertBnExempt = () => {
		setOverlayStateBnExempt('overlay-animate-out');
		setIsButtonDisabled(false);
	};

	const submitNotificationForReview = async () => {
		setIsButtonDisabled(true);
		let postFormData = new FormData();

		postFormData.append('title', formData.title);
		postFormData.append('body', formData.body);
		postFormData.append('uuid', jwt.data.id);
		postFormData.append('markets', convertBooleanToString(formData.isMarketChecked));
		postFormData.append('agents', convertBooleanToString(formData.isAgentChecked));
		postFormData.append('buyers', convertBooleanToString(formData.isBuyerChecked));
		postFormData.append('suppliers', convertBooleanToString(formData.isSupplierChecked));

		setOverlayState('overlay-animate-out');

		const loadingToast = toast.loading('Sending request for broadcast notification...');

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/request-broadcast-notification', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			toast.dismiss(loadingToast);
			setIsButtonDisabled(false);

			setFormData({
				title: '',
				body: '',
				isFormValid: false,
				isTitleValid: true,
				isBodyValid: true,
				isMarketChecked: false,
				isAgentChecked: false,
				isBuyerChecked: false,
				isSupplierChecked: false,
			});

			toast.success('Your request to send this notification has been logged. You will receive notification when it has been processed.');
		} else {
			setIsButtonDisabled(false);
			toast.error('This request could not be processed at this time. Please try again');
		}
	};

	const broadcastNotification = async () => {
		setIsButtonDisabled(true);
		let postFormData = new FormData();

		postFormData.append('title', formData.title);
		postFormData.append('body', formData.body);
		postFormData.append('uuid', jwt.data.id);
		postFormData.append('markets', convertBooleanToString(formData.isMarketChecked));
		postFormData.append('agents', convertBooleanToString(formData.isAgentChecked));
		postFormData.append('buyers', convertBooleanToString(formData.isBuyerChecked));
		postFormData.append('suppliers', convertBooleanToString(formData.isSupplierChecked));

		setOverlayState('overlay-animate-out');

		const loadingToast = toast.loading('Sending broadcast notification...');

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/send-broadcast-notification', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setOverlayStateBnExempt('overlay-animate-out');
			toast.dismiss(loadingToast);
			setIsButtonDisabled(false);
			toast.success('Sent!');

			setFormData({
				title: '',
				body: '',
				isFormValid: false,
				isTitleValid: true,
				isBodyValid: true,
				isMarketChecked: false,
				isAgentChecked: false,
				isBuyerChecked: false,
				isSupplierChecked: false,
			});
		} else {
			setOverlayStateBnExempt('overlay-animate-out');
			toast.dismiss(loadingToast);
			setIsButtonDisabled(false);
			toast.error('Something went wrong! Please contact support.');
		}
	};

	const convertBooleanToString = (bool) => {
		if (bool) {
			return 'Y';
		}

		return 'N';
	};

	return (
		<>
			<ConfirmAlert
				text='Please note that this notification will be submitted for review before reaching the end user(s).'
				overlayState={overlayState}
				acceptClick={submitNotificationForReview}
				cancelClick={cancelConfirmAlert}
				buttonValue='Submit'
			/>
			<ConfirmAlert
				text='Are you sure you would like to broadcast this notification?'
				overlayState={overlayStateBnExempt}
				acceptClick={broadcastNotification}
				cancelClick={cancelConfirmAlertBnExempt}
				buttonValue='Send'
			/>
			<NavigationBack />
			<ScreenHeading heading='Broadcast Notification' />
			<div className='broadcast-notification-screen'>
				<div className='broadcast-notification-container'>
					<div className='form-control'>
						<label className='form-control-label'>Notification Title</label>
						<input className='form-input' id='title' onChange={handleInputChange} value={formData.title} />
						{!formData.isTitleValid && <p className='form-input-error'>Invalid notification title.</p>}
					</div>
					<div className='form-control'>
						<label className='form-control-label'>Notification Body</label>
						<textarea className='form-input form-textarea' id='body' rows='10' onChange={handleInputChange} value={formData.body}></textarea>
						{!formData.isBodyValid && <p className='form-input-error'>Invalid notification body.</p>}
					</div>

					<div className='checkbox-group'>
						<label className='form-control-label'>Notification Recipients</label>
						<div className='checkbox-control'>
							<input
								type='checkbox'
								name='market'
								checked={formData.isMarketChecked}
								onChange={() => setFormData({ ...formData, isMarketChecked: !formData.isMarketChecked })}
							/>
							<label htmlFor='market' className='checkbox-control-label'>
								Market Staff
							</label>
						</div>
						<div className='checkbox-control'>
							<input
								type='checkbox'
								name='agent'
								checked={formData.isAgentChecked}
								onChange={() => setFormData({ ...formData, isAgentChecked: !formData.isAgentChecked })}
							/>
							<label htmlFor='agent' className='checkbox-control-label'>
								Agents
							</label>
						</div>
						<div className='checkbox-control'>
							<input
								type='checkbox'
								name='buyer'
								checked={formData.isBuyerChecked}
								onChange={() => setFormData({ ...formData, isBuyerChecked: !formData.isBuyerChecked })}
							/>
							<label htmlFor='buyer' className='checkbox-control-label'>
								Buyers
							</label>
						</div>
						<div className='checkbox-control'>
							<input
								type='checkbox'
								name='supplier'
								checked={formData.isSupplierChecked}
								onChange={() => setFormData({ ...formData, isSupplierChecked: !formData.isSupplierChecked })}
							/>
							<label htmlFor='supplier' className='checkbox-control-label'>
								Suppliers
							</label>
						</div>
						<input
							type='submit'
							value='Submit For Review'
							className='btn btn-primary-solid mt-md'
							onClick={validateForm}
							disabled={isButtonDisabled}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default BroadcastNotificationScreen;
