import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BackButtonListener = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.onpopstate = (event) => {
			event.preventDefault();

			navigate('/home', { replace: true });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export default BackButtonListener;
