import { useState, useEffect } from 'react';
import './Auth.css';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import FormData from 'form-data';
import useAuth from '../../hooks/useAuth';
import AuthLogo from '../../components/AuthLogo';

const LoginScreen = () => {
	// Set initial form state
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		rememberEmail: false,
		isFormValid: false,
		isEmailValid: true,
		isPasswordValid: true,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const auth = useAuth();

	// navigate function from react router
	const navigate = useNavigate();

	// If user is authed, go straight to home screen
	useEffect(
		() => {
			if (auth) {
				const notification = localStorage.getItem('notRoute');

				if (notification) {
					if (notification !== '/sign-in') {
						navigate(notification);
					} else {
						navigate('/home');
					}
				} else {
					// Check if not iPhone
					if (navigator.appVersion.indexOf('iPhone') === -1) {
						if (Notification.permission === 'granted') {
							navigate('/home');
						} else {
							navigate('/notifications-prompt');
						}
					} else {
						navigate('/home');
					}
				}
			}
		},
		// eslint-disable-next-line
		[],
	);

	// Function that handles the change of each input in the form
	// Idea here is that has we change the values of the inputs, they get updated in state
	const handleInputChange = (event, indicator) => {
		if (indicator === 'email') {
			// use the spread operator to copy current state, and only add state that changes
			setFormData({ ...formData, email: event.target.value });
		} else {
			setFormData({ ...formData, password: event.target.value });
		}
	};

	// Function that validates form input
	const validateForm = (event) => {
		event.preventDefault();

		setIsButtonDisabled(true);

		let validForm = true;
		let validEmail = true;
		let validPassword = true;

		// Validate email
		if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
			validEmail = false;
			validForm = false;
		}

		if (formData.password.length < 8) {
			validPassword = false;
			validForm = false;
		}

		setFormData({ ...formData, isFormValid: validForm, isEmailValid: validEmail, isPasswordValid: validPassword });

		if (!validForm) {
			// form is not valid, show toast
			toast.error('One or more form inputs are invalid. Please check your inputs carefully.');
			setIsButtonDisabled(false);
		} else {
			// form is valid, send login request
			// check if rememberEmail is true, if it is, add it to local storage
			authenticateUser();
		}
	};

	const authenticateUser = async () => {
		let postFormData = new FormData();

		postFormData.append('email', formData.email);
		postFormData.append('password', formData.password);

		const loadingToast = toast.loading('Authenticating...');

		// ! POSSIBLE RESPONSES: success, pc not verified, r not verified, fail
		const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/authenticate-user', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		switch (responseData.message) {
			case 'success':
				// Registration successful, show toast, and navigate to verification screen with name and email as parameters
				toast.dismiss(loadingToast);
				toast.success('Authenticated!', { duration: 2000 });

				// store jwt in localStorage
				localStorage.setItem('token', responseData.jwt);

				// check if not route is stored
				const notification = localStorage.getItem('notRoute');

				setTimeout(() => {
					if (notification) {
						navigate(notification);
					} else {
						// Check if not iPhone
						if (navigator.appVersion.indexOf('iPhone') === -1) {
							if (Notification.permission === 'granted') {
								navigate('/home');
							} else {
								const prompted = localStorage.getItem('notificationsPrompt');

								if (prompted === undefined || prompted === null) {
									navigate('/notifications-prompt');
								} else {
									navigate('/home');
								}
							}
						} else {
							navigate('/home');
						}
					}
				}, 2000);
				break;
			case 'r not verified':
				// Account exists, but has not been verified
				toast.dismiss(loadingToast);
				toast.success(
					`Account exists, but has not been verified. Please look for the verification code sent to ${formData.email}, and verify your account.`,
				);

				setTimeout(() => {
					navigate('/verify-account', { state: { email: formData.email, indicator: '' } });
				}, 5000);

				setIsButtonDisabled(false);
				break;
			case 'pc not verified':
				// Account exists, but a request for password change has not been verified
				toast.dismiss(loadingToast);
				toast.success(
					`Account exists, but you have a request for password change that has not been verified. Please look for the verification code sent to ${formData.email}, and verify your request for password change.`,
					{ duration: 6500 },
				);

				setTimeout(() => {
					navigate('/verify-password-change', { state: { email: formData.email } });
				}, 6500);

				setIsButtonDisabled(false);
				break;
			case 'fail':
				// Authentication failed, show toast
				toast.dismiss(loadingToast);
				toast.error('Authentication failed. This could be due to an incorrect email address or password. Please try again.');

				setIsButtonDisabled(false);
				break;
			case 'fms wrong password':
				// FMS user used incorrect temp password
				toast.dismiss(loadingToast);
				toast.error('Incorrect temp password provided. Please go and see Matt or Richard to assist you.');

				setIsButtonDisabled(false);
				break;
			case 'fms not registered':
				// FMS user logging in for the first time using correct temp password
				toast.dismiss(loadingToast);
				toast.success(`FMS user detected. Please look for the verification code sent to ${formData.email}, and verify your account.`);

				setTimeout(() => {
					navigate('/verify-account', { state: { email: formData.email, indicator: 'fms' } });
				}, 5000);

				setIsButtonDisabled(false);
				break;
			case 'fms no email':
				// Unable to send verification email to fms user at this time
				toast.dismiss(loadingToast);
				toast.error(`Unable to send verification code to ${formData.email} at this time. Please go and see Matt or Richard to assist you.`);

				setIsButtonDisabled(false);
				break;
			default:
				break;
		}
	};

	return (
		<div className='login-form-container'>
			<form method='post' className='login-form'>
				<AuthLogo />
				<h1 className='form-header'>Sign In</h1>
				<div className='form-control'>
					<label className='form-control-label'>Email</label>
					<input
						type='email'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'email');
						}}
					/>
					{!formData.isEmailValid && <p className='form-input-error'>Invalid email.</p>}
				</div>
				<div className='form-control'>
					<label className='form-control-label'>Password</label>
					<input
						type='password'
						className='form-input'
						value={formData.password}
						onChange={(event) => {
							handleInputChange(event, 'password');
						}}
					/>
					{!formData.isPasswordValid && <p className='form-input-error'>Invalid password.</p>}
				</div>
				<input type='submit' value='Sign In' className='btn btn-primary-solid' onClick={(event) => validateForm(event)} disabled={isButtonDisabled} />
				<input
					type='button'
					value='Forgot Password'
					className='btn btn-primary-outline mt-sm'
					onClick={() => navigate('/change-password')}
					disabled={isButtonDisabled}
				/>
				<div className='form-actions'>
					<p className='form-actions-text'>Need an account?</p>
					<a href='/sign-up' className='form-nav-link'>
						Sign Up
					</a>
				</div>
			</form>
		</div>
	);
};

export default LoginScreen;
