import { useNavigate } from 'react-router-dom';

const BuyerEnquiryWidget = () => {
	const navigate = useNavigate();

	return (
		<div
			className='widget'
			onClick={() => {
				navigate('/buyer-enquiry');
			}}>
			<svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 720.000000 720.000000' preserveAspectRatio='xMidYMid meet'>
				<g transform='translate(0.000000,720.000000) scale(0.100000,-0.100000)'>
					<path
						d='M230 7191 c-88 -27 -169 -94 -204 -170 l-21 -46 0 -3375 0 -3375 21
-46 c28 -60 86 -118 149 -149 l50 -25 3370 0 3370 0 55 26 c67 32 113 77 148
144 l27 50 3 3350 c2 3042 1 3354 -14 3405 -25 87 -69 139 -159 188 l-50 27
-3360 2 c-1848 1 -3371 -2 -3385 -6z m6713 -145 c45 -17 73 -40 96 -81 15 -28
16 -287 16 -3365 0 -3078 -1 -3337 -16 -3365 -23 -41 -51 -64 -96 -81 -57 -20
-6629 -20 -6686 0 -45 17 -73 40 -96 81 -15 28 -16 288 -19 3344 -1 2303 1
3325 8 3353 14 51 55 95 105 113 59 21 6630 21 6688 1z'
					/>
					<path
						d='M4260 6405 c-60 -19 -124 -75 -158 -139 l-27 -51 -5 -492 -5 -493
-137 -138 -138 -137 0 -113 0 -112 1208 0 c1192 0 1208 1 1252 21 55 25 114
80 143 134 22 40 22 46 25 664 3 724 6 699 -82 786 -90 90 -23 85 -1095 84
-771 0 -944 -3 -981 -14z m1923 -186 c14 -6 30 -22 36 -36 8 -17 11 -204 11
-619 l0 -595 -25 -24 -24 -25 -1076 0 c-591 0 -1075 3 -1075 7 0 4 45 52 99
107 139 142 131 101 131 676 0 490 -1 484 45 508 28 16 1844 16 1878 1z'
					/>
					<path d='M4450 5950 l0 -90 800 0 801 0 -3 88 -3 87 -797 3 -798 2 0 -90z' />
					<path
						d='M4450 5575 l0 -95 798 2 797 3 3 70 c2 38 0 80 -3 92 l-5 23 -795 0
-795 0 0 -95z'
					/>
					<path d='M4450 5195 l0 -95 608 2 607 3 3 93 3 92 -611 0 -610 0 0 -95z' />
					<path
						d='M2326 6120 c-338 -54 -639 -280 -776 -583 -43 -96 -66 -178 -140
-515 -84 -381 -86 -388 -67 -478 39 -185 176 -326 358 -369 44 -10 118 -15
232 -15 l167 0 0 -97 c0 -54 -4 -103 -8 -109 -4 -6 -171 -66 -372 -133 -263
-87 -385 -133 -432 -162 -129 -77 -242 -217 -291 -361 -21 -62 -22 -78 -25
-725 l-3 -663 -94 0 -95 0 0 -565 0 -565 1270 0 1271 0 -3 93 -3 92 -1172 3
-1173 2 0 375 0 375 1268 -2 1267 -3 3 -467 2 -468 1455 0 1455 0 0 600 c0
672 -2 696 -67 827 -46 92 -109 171 -179 227 -61 47 -76 56 -271 145 -73 34
-192 88 -263 121 -72 33 -135 60 -140 60 -19 0 -10 16 41 70 69 76 121 155
159 245 59 144 60 150 60 838 l0 627 -585 0 c-388 0 -602 -4 -637 -11 -194
-40 -338 -196 -369 -397 -17 -116 -7 -849 14 -927 39 -149 106 -271 211 -382
l49 -53 -64 -29 c-35 -16 -138 -63 -229 -105 -90 -42 -165 -76 -166 -76 -1 0
-4 145 -7 323 -4 293 -6 329 -26 395 -28 95 -57 152 -117 229 -56 71 -128 130
-205 171 -30 15 -217 81 -416 147 l-362 120 -1 108 0 107 153 0 c188 0 251 10
345 55 158 75 261 240 262 418 0 64 -133 713 -166 807 -116 332 -373 570 -709
656 -113 28 -307 40 -409 24z m361 -199 c155 -44 291 -127 395 -240 56 -61
115 -148 107 -155 -2 -2 -42 -20 -89 -39 -47 -20 -130 -55 -185 -79 -98 -42
-103 -43 -210 -43 l-110 0 -111 53 c-251 119 -414 108 -687 -46 -69 -40 -129
-72 -133 -72 -14 0 10 81 51 167 146 310 443 491 785 478 74 -2 140 -11 187
-24z m583 -579 c0 -4 16 -81 35 -172 77 -356 105 -507 105 -561 0 -110 -79
-210 -193 -244 -70 -21 -367 -22 -367 -1 0 24 39 64 113 116 70 48 113 97 139
156 9 21 14 122 18 348 l5 318 55 23 c57 24 90 30 90 17z m-963 -61 c32 -10
94 -35 138 -55 158 -71 314 -84 448 -37 l57 20 0 -249 c0 -162 -4 -258 -11
-273 -6 -14 -49 -50 -94 -81 -89 -60 -134 -116 -154 -191 -6 -22 -11 -127 -11
-237 l0 -198 -190 0 -190 0 0 195 c0 170 -2 201 -20 248 -26 70 -75 125 -160
182 -117 78 -110 55 -110 371 0 273 0 273 23 285 76 42 184 49 274 20z m-494
-363 c2 -136 9 -249 16 -270 19 -59 70 -118 156 -178 74 -52 111 -93 99 -112
-3 -4 -74 -8 -158 -8 -199 0 -239 11 -316 88 -90 92 -99 154 -50 383 49 233
38 211 143 273 51 31 95 55 98 56 4 0 9 -105 12 -232z m3755 -1120 l-3 -553
-26 -67 c-74 -190 -206 -314 -404 -379 -90 -30 -240 -33 -329 -5 -117 35 -187
77 -271 161 -86 86 -140 179 -166 285 -21 81 -20 880 0 930 30 75 96 140 168
166 30 10 146 13 536 14 l498 0 -3 -552z m-3189 -80 l0 -73 -137 -103 -136
-103 -113 113 c-62 62 -113 116 -113 119 0 4 73 32 163 62 145 49 171 55 250
56 l87 1 -1 -72z m518 16 c92 -31 169 -57 171 -59 1 -1 -50 -54 -114 -118
l-116 -115 -134 100 -134 100 0 74 0 74 79 0 c70 0 100 -7 248 -56z m-1016
-330 l208 -207 73 54 c40 30 101 75 136 102 35 26 67 47 73 47 5 0 9 -283 9
-745 l0 -745 -282 2 -283 3 -3 563 -2 562 -90 0 -90 0 0 -565 0 -565 -235 0
-235 0 0 634 c0 602 1 637 20 697 35 114 118 217 215 266 38 20 248 98 274
102 3 1 98 -92 212 -205z m1627 121 c89 -41 178 -123 221 -204 57 -108 61
-141 61 -517 l0 -342 -33 -25 c-138 -105 -231 -274 -245 -441 l-7 -81 -92 -3
-93 -3 -2 373 -3 373 -92 3 -93 3 0 -376 0 -375 -280 0 -280 0 0 745 c0 410 3
745 8 745 4 0 67 -45 140 -100 73 -55 136 -100 140 -100 4 0 99 92 211 204
l203 204 82 -25 c44 -14 114 -40 154 -58z m1231 -910 c156 -47 321 -43 470 11
l65 23 65 -29 c255 -110 633 -285 674 -312 53 -34 115 -106 149 -172 46 -90
48 -112 48 -654 l0 -512 -1257 2 -1258 3 -3 450 c-1 252 2 490 8 540 12 116
56 209 137 290 60 60 111 88 408 220 83 37 202 91 265 121 63 29 122 51 130
49 8 -2 53 -16 99 -30z'
					/>
					<path
						d='M2757 3033 c-4 -3 -7 -46 -7 -95 l0 -88 285 0 286 0 -3 93 -3 92
-276 3 c-151 1 -278 -1 -282 -5z'
					/>
					<path d='M2000 1345 l0 -95 470 0 470 0 0 95 0 95 -470 0 -470 0 0 -95z' />
				</g>
			</svg>

			<div className='widget-text'>Buyer Enquiry</div>
		</div>
	);
};

export default BuyerEnquiryWidget;
