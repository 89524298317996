import { useNavigate } from 'react-router-dom';

const BuyerStatementWidget = () => {
	const navigate = useNavigate();

	return (
		<div
			className='widget'
			onClick={() => {
				navigate('/buyer-statement');
			}}>
			<svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 720.000000 720.000000' preserveAspectRatio='xMidYMid meet'>
				<g transform='translate(0.000000,720.000000) scale(0.100000,-0.100000)'>
					<path
						d='M230 7191 c-88 -27 -169 -94 -204 -170 l-21 -46 0 -3375 0 -3375 21
-46 c28 -60 86 -118 149 -149 l50 -25 3370 0 3370 0 55 26 c67 32 113 77 148
144 l27 50 3 3350 c2 3042 1 3354 -14 3405 -25 87 -69 139 -159 188 l-50 27
-3360 2 c-1848 1 -3371 -2 -3385 -6z m6713 -145 c45 -17 73 -40 96 -81 15 -28
16 -287 16 -3365 0 -3078 -1 -3337 -16 -3365 -23 -41 -51 -64 -96 -81 -57 -20
-6629 -20 -6686 0 -45 17 -73 40 -96 81 -15 28 -16 288 -19 3344 -1 2303 1
3325 8 3353 14 51 55 95 105 113 59 21 6630 21 6688 1z'
					/>
					<path
						d='M958 6726 c-97 -35 -165 -95 -210 -186 l-23 -45 -3 -2307 -2 -2306
26 -31 c34 -40 88 -43 128 -6 l26 24 0 2278 0 2279 23 44 c16 33 34 51 67 68
l44 22 2056 0 c1982 0 2056 -1 2096 -19 23 -10 52 -34 65 -52 24 -33 24 -39
29 -297 l5 -264 28 -24 c39 -33 81 -31 118 6 l30 30 -3 277 c-3 272 -4 279
-28 328 -31 63 -100 133 -165 165 l-50 25 -2110 2 c-1719 2 -2117 0 -2147 -11z'
					/>
					<path
						d='M1664 6341 c-58 -14 -101 -49 -128 -103 -25 -50 -26 -59 -26 -233 0
-172 1 -183 25 -231 30 -61 87 -100 159 -109 28 -3 677 -5 1441 -3 l1390 3 47
27 c34 21 53 41 72 80 25 50 26 58 26 232 0 170 -2 184 -24 233 -19 39 -35 58
-73 80 l-48 28 -1415 2 c-778 0 -1429 -2 -1446 -6z m2816 -336 l0 -155 -1390
0 -1390 0 0 155 0 155 1390 0 1390 0 0 -155z'
					/>
					<path
						d='M5334 5626 c-18 -8 -36 -20 -40 -28 -5 -7 -11 -557 -14 -1222 l-5
-1208 -135 1 c-166 2 -291 -17 -433 -63 -349 -115 -634 -359 -794 -681 -54
-109 -77 -172 -110 -295 -26 -99 -28 -118 -28 -310 0 -189 2 -213 27 -312 46
-182 125 -351 231 -498 60 -83 233 -248 321 -308 l78 -52 -1706 0 c-1896 0
-1746 -6 -1796 68 l-25 36 -3 380 -3 381 -26 27 c-37 39 -84 38 -124 -2 l-30
-29 3 -398 3 -398 29 -58 c34 -71 93 -129 166 -165 l55 -27 2160 0 2160 0 115
27 c121 29 232 69 322 114 78 40 215 132 233 157 40 57 -2 137 -71 137 -29 0
-57 -13 -118 -54 -103 -68 -159 -96 -270 -135 -230 -81 -522 -82 -758 -2 -608
206 -939 866 -738 1473 132 397 446 681 863 780 134 32 373 32 507 -1 542
-133 910 -592 911 -1137 0 -226 -60 -437 -181 -636 -43 -70 -10 -148 64 -148
52 0 64 8 99 64 288 454 268 1053 -50 1504 -70 100 -235 259 -341 330 -110 73
-248 141 -350 171 l-72 22 0 1220 c0 855 -3 1226 -11 1241 -6 11 -26 26 -44
34 -40 16 -32 16 -71 0z'
					/>
					<path
						d='M4016 5319 l-26 -31 0 -759 0 -759 -105 0 -105 0 -2 565 -3 565 -28
27 -27 28 -235 0 c-231 0 -235 0 -257 -23 l-23 -23 -5 -567 -5 -567 -105 0
-105 0 -5 331 c-4 249 -8 335 -18 346 -28 35 -65 39 -285 36 l-217 -3 -27 -28
-28 -27 -3 -330 -3 -330 -105 0 -104 0 0 169 0 168 -26 27 -27 26 -237 0 -238
0 -26 -31 c-26 -30 -26 -33 -26 -194 l0 -164 -219 -3 c-217 -3 -220 -3 -245
-27 -36 -34 -36 -98 0 -132 l26 -24 1919 0 1919 0 26 26 c37 37 37 90 1 127
l-27 27 -220 3 -220 3 0 760 0 761 -29 29 -29 29 -235 0 -235 0 -26 -31z m369
-171 c3 -13 4 -327 3 -698 l-3 -675 -105 0 -105 0 -3 685 c-1 377 0 691 3 698
3 8 34 12 104 12 96 0 101 -1 106 -22z m-795 -878 l0 -500 -105 0 -105 0 0
500 0 500 105 0 105 0 0 -500z m-790 -235 l0 -265 -105 0 -105 0 0 265 0 265
105 0 105 0 0 -265z m-795 -160 l0 -100 -105 0 -105 0 -3 80 c-5 131 -9 126
111 123 l102 -3 0 -100z'
					/>
					<path
						d='M1407 3159 c-11 -6 -25 -26 -33 -45 -12 -30 -12 -39 0 -69 9 -21 26
-39 41 -45 37 -14 301 -13 328 2 34 18 46 39 47 81 0 27 -7 45 -25 62 -24 24
-27 25 -182 25 -94 0 -166 -5 -176 -11z'
					/>
					<path
						d='M2013 3146 c-40 -34 -39 -98 1 -132 l28 -24 610 2 610 3 19 24 c28
34 25 96 -6 126 l-24 25 -605 0 -605 0 -28 -24z'
					/>
					<path
						d='M1180 2773 c-63 -24 -82 -109 -34 -154 l26 -24 656 -3 c462 -2 668 0
695 8 68 20 84 101 30 152 l-25 23 -666 2 c-367 1 -674 -1 -682 -4z'
					/>
					<path
						d='M2848 2765 c-79 -44 -57 -153 34 -170 18 -4 109 -5 201 -3 165 3 167
3 193 29 36 36 36 92 0 128 l-26 26 -188 2 c-151 2 -192 0 -214 -12z'
					/>
					<path
						d='M5480 2451 c-65 -21 -110 -58 -310 -257 l-216 -214 -39 41 c-117 121
-293 140 -431 47 -93 -63 -154 -193 -140 -297 16 -119 25 -132 239 -348 228
-231 253 -247 372 -247 126 0 128 1 537 412 343 345 367 371 388 427 18 48 21
75 18 135 -6 93 -32 154 -90 212 -66 66 -127 92 -223 95 -44 1 -91 -1 -105 -6z
m164 -194 c67 -38 92 -111 62 -180 -8 -18 -166 -184 -358 -374 l-344 -343 -49
0 -49 0 -188 188 -188 188 0 52 c0 95 52 152 138 152 58 0 73 -9 168 -101 127
-123 105 -132 404 165 135 135 259 252 275 260 42 22 83 20 129 -7z'
					/>
					<path
						d='M1165 2368 c-52 -28 -63 -101 -20 -143 24 -25 25 -25 222 -25 207 1
218 3 241 47 20 37 14 73 -17 104 l-29 29 -189 0 c-117 0 -196 -5 -208 -12z'
					/>
					<path
						d='M1879 2365 c-54 -29 -60 -107 -12 -145 25 -20 43 -20 702 -20 739 0
708 -2 728 57 14 42 -2 90 -37 108 -42 22 -1341 22 -1381 0z'
					/>
					<path
						d='M1145 1955 c-18 -17 -25 -35 -25 -62 1 -42 13 -63 47 -81 28 -15
1328 -17 1368 -2 31 12 58 69 49 105 -3 14 -18 34 -31 45 -25 20 -43 20 -705
20 l-679 0 -24 -25z'
					/>
					<path
						d='M2826 1949 c-35 -42 -34 -82 4 -120 l30 -30 201 3 c197 3 201 3 220
27 28 34 25 96 -6 126 -24 25 -25 25 -224 25 l-199 0 -26 -31z'
					/>
				</g>
			</svg>
			<div className='widget-text'>Buyer Statement</div>
		</div>
	);
};

export default BuyerStatementWidget;
