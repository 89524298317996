import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

import CustomLoader from '../../components/CustomLoader';
import NavigationMenu from '../../components/NavigationMenu';
import ScreenHeading from '../../components/ScreenHeading';

import useJwt from '../../hooks/useJwt';

const UnconfirmedDeliveriesScreen = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [deliveryCount, setDeliveryCount] = useState(0);
	const [unconfirmedDeliveries, setUnconfirmedDeliveries] = useState([]);
	const [noDeliveries, setNoDeliveries] = useState(false);
	const [messageText, setMessageText] = useState('No unconfirmed deliveries found for market.');
	const jwt = useJwt();

	useEffect(
		() => {
			getUnconfirmedDeliveries();
		},
		// eslint-disable-next-line
		[],
	);

	const getUnconfirmedDeliveries = async () => {
		setIsLoading(true);

		let postFormData = new FormData();

		const primaryMarket = localStorage.getItem('pm');

		postFormData.append('query', 'unconfirmed-del');
		postFormData.append('device', jwt.data.id);
		postFormData.append('market', primaryMarket);
		postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
		postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

		// Send request to WS
		const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/market-query', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		// get unconfirmed deliveries from WS
		if (responseData.success) {
			setIsLoading(false);

			if (responseData.result.message === 'Device does not have access to the information (nam)') {
				setMessageText('Device does not have access to this information.');
				setNoDeliveries(true);
			} else if (responseData.result.message === 'Device does not have access to the information (dnr)') {
				setMessageText('Device does not have access to this information.');
				setNoDeliveries(true);
			} else if (responseData.result.message === 'Market provided does not have 2 phase deliveries!') {
				setMessageText('Market does not have 2 phase deliveries.');
				setNoDeliveries(true);
			} else if (responseData.result.message.substring(0, 6) === 'Failed') {
				setMessageText('Unable to get unconfirmed deliveries for market.');
				setNoDeliveries(true);
			} else {
				setDeliveryCount(responseData.result.delList.length);
				setUnconfirmedDeliveries(responseData.result.delList);
			}
		} else {
			setIsLoading(false);
			setNoDeliveries(true);
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching unconfirmed deliveries...' />}
			<NavigationMenu />
			<ScreenHeading heading='Unconfirmed Deliveries' />
			<div className='unconfirmed-deliveries-screen'>
				<div className='unconfirmed-deliveries-container'>
					{noDeliveries && <div className='no-unconfirmed-deliveries'>{messageText}</div>}
					{deliveryCount > 0 && (
						<div className='unconfirmed-delivery-count'>
							<span>{deliveryCount}</span> unconfirmed deliveries found!
						</div>
					)}
					{unconfirmedDeliveries.map((ud, index) => {
						return (
							<div className='unconfirmed-delivery' key={index}>
								<div className='unconfirmed-delivery-row'>
									<p className='unconfirmed-delivery-label'>Delivery ID</p>
									<p className='unconfirmed-delivery-info'>{ud.delID}</p>
								</div>
								<div className='unconfirmed-delivery-row'>
									<p className='unconfirmed-delivery-label'>Delivery Date</p>
									<p className='unconfirmed-delivery-info'>{ud.ddate}</p>
								</div>
								<div className='unconfirmed-delivery-row'>
									<p className='unconfirmed-delivery-label'>Delivery Time</p>
									<p className='unconfirmed-delivery-info'>{ud.dtime}</p>
								</div>
								<div className='unconfirmed-delivery-row'>
									<p className='unconfirmed-delivery-label'>Supplier Number</p>
									<p className='unconfirmed-delivery-info'>{ud.supplier}</p>
								</div>
								<div className='unconfirmed-delivery-row'>
									<p className='unconfirmed-delivery-label'>Supplier</p>
									<p className='unconfirmed-delivery-info'>{ud.supplName}</p>
								</div>
								<div className='unconfirmed-delivery-row'>
									<p className='unconfirmed-delivery-label'>Agent</p>
									<p className='unconfirmed-delivery-info'>{ud.agentName}</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default UnconfirmedDeliveriesScreen;
