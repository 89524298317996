import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NavigationBack = () => {
	const navigate = useNavigate();

	return (
		<nav className='navbar'>
			<div className='navigation-home' onClick={() => navigate(-1, { replace: true })}>
				<FaChevronLeft />
				<span className='navigation-home-text'>Back</span>
			</div>
		</nav>
	);
};

export default NavigationBack;
