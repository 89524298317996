import { useState, useRef } from 'react';

import './Support.css';

import toast from 'react-hot-toast';
import FormData from 'form-data';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';
import useJwt from '../../hooks/useJwt';

const NewSupportTicketScreen = () => {
	const [formData, setFormData] = useState({
		subject: '',
		body: '',
		isFormValid: false,
		isSubjectValid: true,
		isBodyValid: true,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [files, setFiles] = useState([]);
	const hiddenFileInput = useRef(null);
	const jwt = useJwt();

	// Function that handles the change of each input in the form
	// Idea here is that has we change the values of the inputs, they get updated in state
	const handleInputChange = (event, indicator) => {
		if (indicator === 'subject') {
			// use the spread operator to copy current state, and only add state that changes
			setFormData({ ...formData, subject: event.target.value });
		} else if (indicator === 'body') {
			setFormData({ ...formData, body: event.target.value });
		}
	};

	const validateForm = () => {
		setIsButtonDisabled(true);

		let validForm = true;
		let validSubject = true;
		let validBody = true;

		// Validate subject
		if (formData.subject === '') {
			validForm = false;
			validSubject = false;
		}

		// Validate body
		if (formData.body === '') {
			validForm = false;
			validBody = false;
		}

		setFormData({ ...formData, isFormValid: validForm, isSubjectValid: validSubject, isBodyValid: validBody });

		if (!validForm) {
			toast.error('One or more form inputs are invalid. Please check your inputs carefully.');
			setIsButtonDisabled(false);
		} else {
			submitSupportRequest();
		}
	};

	// Submit support request
	const submitSupportRequest = async () => {
		let postFormData = new FormData();

		postFormData.append('subject', formData.subject);
		postFormData.append('body', formData.body);

		for (let i = 0; i < files.length; i++) {
			postFormData.append('attachments[]', files[i]);
		}

		postFormData.append('uuid', jwt.data.id);

		const loadingToast = toast.loading('Creating ticket...');

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/support/send-support-ticket', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'sent') {
			toast.dismiss(loadingToast);
			toast.success('Ticket Created!', { duration: 2000 });
			setIsButtonDisabled(false);

			// clear all inputs
			setFormData({
				subject: '',
				body: '',
				isFormValid: false,
				isSubjectValid: true,
				isBodyValid: true,
			});

			setFiles([]);
		} else if (responseData.message === 'none') {
			toast.dismiss(loadingToast);
			toast.error('Unable to send request to Market Systems Admins. Please contact market for support.', { duration: 2000 });
			setIsButtonDisabled(false);
		} else {
			toast.dismiss(loadingToast);
			toast.error('Unable to create support ticket. Please try again.', { duration: 2000 });
			setIsButtonDisabled(false);
		}
	};

	// Programmatically click the file input when the button is clicked
	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	const handleChange = (event) => {
		if (event.target.files.length > 0) {
			// Check validity of files
			// console.log(event.target.files[0].size);
			// console.log(event.target.files[0].type);

			validateFiles(event.target.files[0].size, event.target.files[0].type, event.target.files[0]);
		}
	};

	const removeAttachedFile = (event) => {
		const newFiles = files.filter((_, index) => {
			return index !== parseInt(event.target.id);
		});

		setFiles(newFiles);
	};

	const validateFiles = (size, type, file) => {
		const maxSize = 10 * 1024 * 1024;
		let canAddFile = true;

		if (size > maxSize) {
			canAddFile = false;
		}

		const fileTypeArray = [
			'image/jpeg',
			'image/png',
			'image/heic',
			'image/heif',
			'application/pdf',
			'application/vnd.oasis.opendocument.text',
			'application/vnd.oasis.opendocument.spreadsheet',
			'text/csv',
			'text/plain',
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		];

		if (!fileTypeArray.includes(type)) {
			canAddFile = false;
		}

		if (canAddFile) {
			// Add files to file array
			setFiles([...files, file]);
		} else {
			toast.error('Unable to upload file. Either the file is too large, or the file type is unsupported.', { duration: 5000 });
		}
	};

	return (
		<>
			<NavigationBack />
			<ScreenHeading heading='Support Request' />
			<div className='new-support-ticket-screen'>
				<div className='new-support-ticket-container'>
					<div className='form-control'>
						<label className='form-control-label'>Subject</label>
						<input
							value={formData.subject}
							className='form-input'
							onChange={(event) => {
								handleInputChange(event, 'subject');
							}}
						/>
						{!formData.isSubjectValid && <p className='form-input-error'>Invalid subject.</p>}
					</div>
					<div className='form-control'>
						<label className='form-control-label'>Body</label>
						<textarea
							value={formData.body}
							className='form-input form-textarea'
							rows={10}
							onChange={(event) => {
								handleInputChange(event, 'body');
							}}></textarea>
						{!formData.isSubjectValid && <p className='form-input-error'>Invalid body.</p>}
					</div>
					{files.length > 0 && (
						<div className='attached-files'>
							<label className='form-control-label'>Attachments</label>
							{files.map((file, index) => {
								return (
									<div key={index} className='attached-file'>
										<div className='attached-filename'>{file.name}</div>
										<svg className='remove-attached-file' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
											<path
												id={index}
												onClick={removeAttachedFile}
												d='M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z'
											/>
										</svg>
									</div>
								);
							})}
						</div>
					)}
					<input type='file' ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
					<input type='button' value='Add Attachments' className='btn btn-primary-outline mt-md' onClick={handleClick} />
					<input
						type='submit'
						value='Create Ticket'
						className='btn btn-primary-solid mt-md'
						onClick={(event) => validateForm(event)}
						disabled={isButtonDisabled}
					/>
				</div>
			</div>
		</>
	);
};

export default NewSupportTicketScreen;
