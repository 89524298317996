import { useState, useEffect } from 'react';

import './SystemStatus.css';

import CustomLoader from '../../components/CustomLoader';
// import NavigationMenu from '../../components/NavigationMenu';
import UserMarketsDropdown from '../../components/UserMarketsDropdown';
import NavigationBack from '../../components/NavigationBack';

import useJwt from '../../hooks/useJwt';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import ScreenHeading from '../../components/ScreenHeading';

const MarketSystemStatusScreen = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [selectedMarket, setSelectedMarket] = useState('');
	const [isMarketDropdownOpen, setIsMarketDropdownOpen] = useState(false);
	const [userMarkets, setUserMarkets] = useState([]);
	const [marketData, setMarketData] = useState({
		marketName: '',
		status: '',
		dailyStatus: '',
		adminMessage: '',
	});
	const [agentData, setAgentData] = useState({
		agents: [],
	});
	const [notMarket, setNotMarket] = useState(true);
	const [showData, setShowData] = useState(false);
	const jwt = useJwt();

	const navigate = useNavigate();

	useEffect(
		() => {
			if (jwt.data.role.substring(0, 6) === 'Market') {
				setNotMarket(false);
				setShowData(true);
				// send the request to the app backend to make the request to WS
				getMarketSystemStatusAppBackend();
			} else {
				setNotMarket(true);
				getUserMarketsFromAppBackend();
			}
		},
		// eslint-disable-next-line
		[],
	);

	const getMarketSystemStatusAppBackend = async () => {
		setIsLoading(true);
		let postFormData = new FormData();

		postFormData.append('device_number', jwt.data.id);
		postFormData.append('market', '');

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/market/fetch-market-status', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		// ! ERROR HANDLING NB!

		setMarketData({
			...marketData,
			marketName: responseData.marketStatusData.market.name + ' Market',
			status: responseData.marketStatusData.market.status.toUpperCase(),
			dailyStatus: responseData.marketStatusData.market.daily_status,
			adminMessage: responseData.marketStatusData.market.admin_message,
		});

		setAgentData({
			...agentData,
			agents: responseData.marketStatusData.agent,
		});

		setIsLoading(false);
	};

	const getUserMarketsFromAppBackend = async () => {
		setIsLoading(true);
		let postFormData = new FormData();

		postFormData.append('device_number', jwt.data.id);

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/market/get-user-markets', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			// Check that userMarkets is not 'none'
			if (responseData.userMarkets !== 'none') {
				// This means we have data
				setIsLoading(false);
				setUserMarkets(responseData.userMarkets);
			} else {
				// This means the user does not have any registered markets, this could mean they are pending
				// Show a toast and handle accordingly
				setIsLoading(false);
			}
		} else {
			// Handle this error
			// forbidden means they need to be logged out
			setIsLoading(false);
			localStorage.removeItem('token');
			navigate('/sign-in');
		}
	};

	const getMarketSystemStatusAppBackendWithMarketCode = async () => {
		// ! START HERE, THIS WILL GET THE SYSTEM STATUS FROM WS VIA APP BACKEND USING UUID AND MARKET CODE
		// ! SO THIS IS FOR THE NON MARKET REGISTERED PEOPLE
		const loadingToast = toast.loading('Fetching market status info...');
		setIsButtonDisabled(true);

		// Check if selectedMarket is blank, show error toast if it is
		if (selectedMarket === '') {
			toast.dismiss(loadingToast);
			toast.error('Please select a valid market.');
			setIsButtonDisabled(false);
			return false;
		}

		let postFormData = new FormData();

		postFormData.append('device_number', jwt.data.id);
		postFormData.append('market', selectedMarket);

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/market/fetch-market-status', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			// Check that userMarkets is not 'none'
			if (responseData.userMarkets !== 'none') {
				// This means we have data
				setIsLoading(false);
				setUserMarkets(userMarkets);
				setMarketData({
					...marketData,
					marketName: responseData.marketStatusData.market.name + ' Market',
					status: responseData.marketStatusData.market.status.toUpperCase(),
					dailyStatus: responseData.marketStatusData.market.daily_status,
					adminMessage: responseData.marketStatusData.market.admin_message,
				});
				setShowData(true);
				toast.dismiss(loadingToast);
				setIsButtonDisabled(false);
			} else {
				// This means the user does not have any registered markets, this could mean they are pending
				// Show a toast and handle accordingly
				toast.error('Unable to fetch market status info. Please try again.');
				setUserMarkets(userMarkets);
				setIsLoading(false);
				setIsButtonDisabled(false);
			}
		} else {
			// Handle this error
			// forbidden means they need to be logged out
			setIsLoading(false);
			localStorage.removeItem('token');
			navigate('/sign-in');
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching market information...' />}
			<NavigationBack />
			<ScreenHeading heading='Market System Status' />
			<div className='system-status'>
				<div className='system-status-container'>
					{/* CHECK IF ROLE IS NOT MARKET, SHOW DROPDOWN WITH USER REGISTERED MARKETS */}

					{notMarket && (
						<>
							{/* <div className='container'> */}
							<UserMarketsDropdown
								markets={userMarkets}
								setSelectedMarket={setSelectedMarket}
								isMarketDropdownOpen={isMarketDropdownOpen}
								setIsMarketDropdownOpen={setIsMarketDropdownOpen}
							/>
							<input
								type='button'
								value='Fetch System Status'
								className='btn btn-primary-solid mt-sm mb-lg'
								onClick={getMarketSystemStatusAppBackendWithMarketCode}
								disabled={isButtonDisabled}
							/>
							{/* </div> */}
						</>
					)}
					{showData && (
						<>
							<h1 className='system-status-market-header'>{marketData.marketName}</h1>
							<div className='system-status-info-container'>
								<p className='system-status-label-text'>Market Status</p>
								<p className={'system-status-status ' + marketData.status.toLowerCase()}>{marketData.status}</p>
							</div>
							<div className='system-status-info-container'>
								<p className='system-status-label-text'>Daily Status</p>
								<p className='system-status-info-text'>{marketData.dailyStatus}</p>
							</div>
							{/* {marketData.adminMessage !== '' && <div className='system-status-admin-message'>{marketData.adminMessage}</div>} */}
							{/* AGENTS */}
							{agentData.agents.length === 0 ? (
								<div className='no-payment-runs-message'>No agent payment runs currently taking place.</div>
							) : (
								<h1 className='system-status-market-header mt-lg'>Market Agents</h1>
							)}
							{agentData.agents.length > 0 &&
								agentData.agents.map((agent, index) => {
									return (
										<div key={index} className='system-status-agent-info'>
											<div className='system-status-agent-header'>{agent.name}</div>
											<div className={`system-status-agent-reset-status ${agent.reset === '1' ? 'can-reset' : 'cannot-reset'}`}>
												{agent.reset === '1' ? 'CAN RESET' : 'CANNOT RESET'}
											</div>
											<div className='system-status-info-container'>
												<span className='system-status-label-text'>Payment Status</span>
												<span className='system-status-info-text'>{agent.status}</span>
											</div>
										</div>
									);
								})}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default MarketSystemStatusScreen;
