import './Settings.css';
import { useState, useEffect } from 'react';
import { requestToken, registerTokenOnBackend } from '../../pushNotifications';
import toast from 'react-hot-toast';

import NavigationSettings from '../../components/NavigationSettings';
import ScreenHeading from '../../components/ScreenHeading';

import useJwt from '../../hooks/useJwt';

const NotificationsSettingsScreen = () => {
	const [notificationsEnabled, setNotificationsEnabled] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const jwt = useJwt();

	useEffect(() => {
		if (Notification.permission === 'granted') {
			setNotificationsEnabled(true);
		}
	}, []);

	const enableNotifications = async () => {
		const loadingToast = toast.loading('Generating token...');
		const fcmToken = await requestToken();

		setIsButtonDisabled(true);

		if (fcmToken) {
			toast.dismiss(loadingToast);
			await registerTokenOnBackend(fcmToken, jwt, setNotificationsEnabled, setIsButtonDisabled);
		}
	};

	return (
		<>
			<NavigationSettings />
			<ScreenHeading heading='Notification Settings' />
			<div className='notification-settings-screen'>
				<div className='notification-settings-container'>
					{notificationsEnabled && (
						<div className='notifications-settings'>
							<div className='notifications-enabled'>
								<div className='enabled-text'>Notifications Enabled</div>
							</div>
							{/* <div className='mt-md'>Custom Notification Settings Will Go Here</div> */}
						</div>
					)}
					{!notificationsEnabled && (
						<div className='notifications-disabled'>
							<input
								type='button'
								className='btn btn-primary-solid disabled-button'
								onClick={enableNotifications}
								value='Enable Notifications'
								disabled={isButtonDisabled}
							/>
							<div className='info-text-container mt-md'>
								<p className='verification-form-text'>Please enable notifications in order to see notification settings.</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default NotificationsSettingsScreen;
