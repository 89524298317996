import { useState, useRef, useEffect } from 'react';

const GroupDropdown = ({
	groups,
	roles,
	filteredRoles,
	formData,
	setFormData,
	isGroupDropdownOpen,
	setIsGroupDropdownOpen,
	setIsRoleDropdownOpen,
	setIsMarketDropdownOpen,
	setIsRoleSelected,
	registrationData,
	setRegistrationData,
}) => {
	// DROPDOWN STUFF
	const [selectedItem, setSelectedItem] = useState('');
	const ref = useRef();

	// Handle outside click to close dropdown
	useEffect(
		() => {
			const checkIfClickedOutside = (event) => {
				if (isGroupDropdownOpen && ref.current && !ref.current.contains(event.target)) {
					setIsGroupDropdownOpen(false);
				}
			};

			document.addEventListener('mousedown', checkIfClickedOutside);

			return () => {
				document.removeEventListener('mousedown', checkIfClickedOutside);
			};
		},
		// eslint-disable-next-line
		[isGroupDropdownOpen],
	);

	const toggleDropdown = () => {
		setIsGroupDropdownOpen(!isGroupDropdownOpen);
		setIsMarketDropdownOpen(false);
		setIsRoleDropdownOpen(false);
	};

	const handleItemClick = (event) => {
		const idSplit = event.target.id.split('_');

		setIsRoleSelected(true);

		// ! YOU NEED TO RESET THE ROLES BEFORE CHANGING THE DROPDOWN SO THE FILTER WILL WORK

		setRegistrationData({ ...registrationData, roles: roles });

		// Ok now it's time to filter the roles
		const filteredRoles = roles.filter((role) => {
			return role.group_id.toString() === idSplit[1];
		});

		setRegistrationData({ ...registrationData, filteredRoles: filteredRoles });

		setSelectedItem(idSplit[0]);
		setIsGroupDropdownOpen(!isGroupDropdownOpen);
		setFormData({ ...formData, group: idSplit[0] });
	};

	return (
		<div className='dropdown' ref={ref}>
			<div className={`dropdown-title ${isGroupDropdownOpen && `open`}`} onClick={toggleDropdown}>
				{selectedItem === '' ? 'Select Role' : selectedItem}
				<i className={`fa fa-chevron-down dropdown-icon ${isGroupDropdownOpen && 'open'}`}></i>
			</div>
			{isGroupDropdownOpen && (
				<div className='dropdown-body'>
					<ul className='dropdown-items'>
						{groups.map((group, index) => {
							return (
								<li className='dropdown-item' key={index} onClick={(event) => handleItemClick(event)} id={group.group_name + '_' + group.id}>
									{group.group_name}
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default GroupDropdown;
