import { useState, useEffect } from 'react';

import { FaBars, FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { requestToken, registerTokenOnBackend } from '../pushNotifications';
import useJwt from '../hooks/useJwt';
import toast from 'react-hot-toast';

const NavigationMenu = () => {
	const [drawerState, setDrawerState] = useState('drawer-animate-out');
	const [closeDrawerOverlayState, setCloseDrawerOverlayState] = useState('cdo-invisible');
	const [currentRoute, setCurrentRoute] = useState();
	const [pendingRegistrations, setPendingRegistrations] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [notificationsEnabled, setNotificationsEnabled] = useState(false);
	const [marketRolePending, setMarketRolePending] = useState(false);
	const [canCreateFmsNotice, setCanCreateFmsNotice] = useState(false);
	const jwt = useJwt();

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(
		() => {
			setCurrentRoute(location.pathname);

			// check for pending registrations in jwt and in local storage
			const localStoragePending = localStorage.getItem('pendingRegistrations');

			if (jwt.data.pending === '1') {
				// clear local storage
				localStorage.removeItem('pendingRegistrations');
				setPendingRegistrations(true);
			}

			if (localStoragePending === '1') {
				setPendingRegistrations(true);
			}

			const localStorageMarketRole = localStorage.getItem('marketRole');

			// Check if user has a market role pending
			// if (jwt.data.role.substring(0, 6) === 'Market') {
			// 	localStorage.removeItem('marketRole');
			// 	setMarketRolePending(true);
			// }

			if (jwt.data.group === 'Market') {
				localStorage.removeItem('marketRole');
				setMarketRolePending(true);
			}

			if (localStorageMarketRole === '1') {
				setMarketRolePending(true);
			}

			if (jwt.data.role === 'FMS Exco' || jwt.data.role === 'FMS Developer') {
				setCanCreateFmsNotice(true);
			}

			if (jwt.data.role.substring(0, 3) === 'FMS') {
				setMarketRolePending(true);
			}
		},
		// eslint-disable-next-line
		[],
	);

	const openDrawer = (event) => {
		event.preventDefault();

		// Check if not iPhone
		// if (navigator.appVersion.indexOf('iPhone') === -1) {
		// Check if role is registered
		if (jwt.data.role !== '') {
			setShowSettings(true);
			setNotificationsEnabled(false);
		} else if (jwt.data.role === '' && Notification.permission !== 'granted') {
			setShowSettings(false);
			setNotificationsEnabled(true);
		} else if (jwt.data.role === '' && Notification.permission === 'granted') {
			setShowSettings(false);
			setNotificationsEnabled(false);
		}
		// }

		setDrawerState('drawer-animate-in');
		setCloseDrawerOverlayState('cdo-visible');
	};

	const closeDrawer = (event) => {
		event.preventDefault();

		setDrawerState('drawer-animate-out');
		setCloseDrawerOverlayState('cdo-invisible');
	};

	const enableNotifications = async () => {
		toast.loading('Working...');
		setIsButtonDisabled(true);
		setDrawerState('drawer-animate-out');
		setCloseDrawerOverlayState('cdo-invisible');

		const fcmToken = await requestToken();

		if (fcmToken !== undefined) {
			registerTokenOnBackend(fcmToken, jwt, setNotificationsEnabled, setIsButtonDisabled);
		}
	};

	const signOut = () => {
		// Sign the user out
		localStorage.removeItem('token');
		localStorage.removeItem('bdmc');

		navigate('/sign-in');
	};

	return (
		<>
			<nav className='navbar'>
				<Link to='#' className='open-drawer' onClick={openDrawer}>
					<FaBars />
				</Link>
			</nav>
			<div className={'drawer ' + drawerState}>
				<Link to='#' className='close-drawer' onClick={closeDrawer}>
					<FaChevronLeft />
				</Link>
				<div className='drawer-top'>
					<img src='/img/fms_icon.png' className='drawer-icon' alt='Drawer-Icon' />
					<h3 className='drawer-name'>{jwt.data.name}</h3>
					{jwt.data.role !== '' && <p className='drawer-email'>{jwt.data.role}</p>}
					<div className='nav-links-container'>
						<div className={'nav-link-box ' + (currentRoute === '/home' ? 'active-route' : '')}>
							<Link to='/home' className='nav-link'>
								<p className='nav-link-text'>Dashboard</p>
							</Link>
						</div>
						{/* {jwt.data.role !== '' && (
							<div className={'nav-link-box ' + (currentRoute === '/system-status' ? 'active-route' : '')}>
								<Link to='/system-status' className='nav-link'>
									<p className='nav-link-text'>Market System Status</p>
								</Link>
							</div>
						)} */}

						{!marketRolePending && (
							<div className={'nav-link-box ' + (currentRoute === '/add-market' ? 'active-route' : '')}>
								<Link to='/add-market' className='nav-link'>
									<p className='nav-link-text'>Add Market</p>
								</Link>
							</div>
						)}

						{pendingRegistrations && (
							<div className={'nav-link-box ' + (currentRoute === '/check-registrations' ? 'active-route' : '')}>
								<Link to='/check-registrations' className='nav-link'>
									<p className='nav-link-text'>Pending Registrations</p>
								</Link>
							</div>
						)}

						{canCreateFmsNotice && (
							<div className={'nav-link-box ' + (currentRoute === '/add-fms-notice' ? 'active-route' : '')}>
								<Link to='/add-fms-notice' className='nav-link'>
									<p className='nav-link-text'>Add Notice</p>
								</Link>
							</div>
						)}
						{jwt.data.role === 'Buyer' && (
							<div className={'nav-link-box ' + (currentRoute === '/get-buyer-balance' ? 'active-route' : '')}>
								<Link to='/get-buyer-balance' className='nav-link'>
									<p className='nav-link-text'>Balance Enquiry</p>
								</Link>
							</div>
						)}

						{/* NOTIFICATIONS MENU OPTION */}
						{/* MARKET STAFF */}
						{jwt.data.role === 'Market Manager' && (
							<>
								<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
									<Link to='/notifications' className='nav-link'>
										<p className='nav-link-text'>Notifications</p>
									</Link>
								</div>
								<div className={'nav-link-box ' + (currentRoute === '/unconfirmed-deliveries' ? 'active-route' : '')}>
									<Link to='/unconfirmed-deliveries' className='nav-link'>
										<p className='nav-link-text'>Unconfirmed Deliveries</p>
									</Link>
								</div>
								{/* <div className={'nav-link-box ' + (currentRoute === '/app-users' ? 'active-route' : '')}>
                                    <Link to='/app-users' className='nav-link'>
                                        <p className='nav-link-text'>App Users</p>
                                    </Link>
                                </div> */}
							</>
						)}
						{jwt.data.role === 'Market Systems Admin' && (
							<>
								<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
									<Link to='/notifications' className='nav-link'>
										<p className='nav-link-text'>Notifications</p>
									</Link>
								</div>
								<div className={'nav-link-box ' + (currentRoute === '/unconfirmed-deliveries' ? 'active-route' : '')}>
									<Link to='/unconfirmed-deliveries' className='nav-link'>
										<p className='nav-link-text'>Unconfirmed Deliveries</p>
									</Link>
								</div>
							</>
						)}
						{jwt.data.role === 'Market Admin' && (
							<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
								<Link to='/notifications' className='nav-link'>
									<p className='nav-link-text'>Notifications</p>
								</Link>
							</div>
						)}

						{/* AGENT STAFF */}
						{jwt.data.role === 'Agent Manager' && (
							<>
								<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
									<Link to='/notifications' className='nav-link'>
										<p className='nav-link-text'>Notifications</p>
									</Link>
								</div>
								<div className={'nav-link-box ' + (currentRoute === '/unconfirmed-deliveries' ? 'active-route' : '')}>
									<Link to='/unconfirmed-deliveries' className='nav-link'>
										<p className='nav-link-text'>Unconfirmed Deliveries</p>
									</Link>
								</div>
							</>
						)}
						{jwt.data.role === 'Agent Admin' && (
							<>
								<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
									<Link to='/notifications' className='nav-link'>
										<p className='nav-link-text'>Notifications</p>
									</Link>
								</div>
								<div className={'nav-link-box ' + (currentRoute === '/unconfirmed-deliveries' ? 'active-route' : '')}>
									<Link to='/unconfirmed-deliveries' className='nav-link'>
										<p className='nav-link-text'>Unconfirmed Deliveries</p>
									</Link>
								</div>
							</>
						)}
						{jwt.data.role === 'Agent Salesman' && (
							<>
								<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
									<Link to='/notifications' className='nav-link'>
										<p className='nav-link-text'>Notifications</p>
									</Link>
								</div>
								<div className={'nav-link-box ' + (currentRoute === '/unconfirmed-deliveries' ? 'active-route' : '')}>
									<Link to='/unconfirmed-deliveries' className='nav-link'>
										<p className='nav-link-text'>Unconfirmed Deliveries</p>
									</Link>
								</div>
							</>
						)}

						{/* BUYER */}
						{jwt.data.role === 'Buyer' && (
							<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
								<Link to='/notifications' className='nav-link'>
									<p className='nav-link-text'>Notifications</p>
								</Link>
							</div>
						)}

						{/* SUPPLIER */}
						{jwt.data.role === 'Supplier' && (
							<div className={'nav-link-box ' + (currentRoute === '/notifications' ? 'active-route' : '')}>
								<Link to='/notifications' className='nav-link'>
									<p className='nav-link-text'>Notifications</p>
								</Link>
							</div>
						)}
						{jwt.data.role === 'FMS Manco' && (
							<div className={'nav-link-box ' + (currentRoute === '/pending-broadcast-notifications' ? 'active-route' : '')}>
								<Link to='/pending-broadcast-notifications' className='nav-link'>
									<p className='nav-link-text'>Pending Notifications</p>
								</Link>
							</div>
						)}
						{jwt.data.role === 'FMS Developer' && (
							<div className={'nav-link-box ' + (currentRoute === '/pending-broadcast-notifications' ? 'active-route' : '')}>
								<Link to='/pending-broadcast-notifications' className='nav-link'>
									<p className='nav-link-text'>Pending Notifications</p>
								</Link>
							</div>
						)}
						{jwt.data.role === 'FMS Exco' && (
							<div className={'nav-link-box ' + (currentRoute === '/pending-broadcast-notifications' ? 'active-route' : '')}>
								<Link to='/pending-broadcast-notifications' className='nav-link'>
									<p className='nav-link-text'>Pending Notifications</p>
								</Link>
							</div>
						)}
					</div>
				</div>
				<div className='drawer-bottom'>
					{showSettings && (
						<input
							type='button'
							className='btn-sm btn-primary-solid'
							value='Settings'
							onClick={() => {
								navigate('/app-settings', { replace: true });
							}}
						/>
					)}
					{notificationsEnabled && (
						<input
							type='button'
							className='btn-sm btn-primary-solid'
							value='Enable Notifications'
							onClick={enableNotifications}
							disabled={isButtonDisabled}
						/>
					)}
					<input type='button' className='btn-sm btn-danger-solid' value='Sign Out' onClick={signOut} />
				</div>
			</div>
			<div className={'close-drawer-overlay ' + closeDrawerOverlayState} onClick={closeDrawer}></div>
		</>
	);
};

export default NavigationMenu;
