import { useState, useEffect } from 'react';
import './Settings.css';

import useJwt from '../../hooks/useJwt';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

import NavigationHome from '../../components/NavigationHome';
import ScreenHeading from '../../components/ScreenHeading';

const SettingsScreen = () => {
	const [isIos, setIsIos] = useState(true);
	const jwt = useJwt();
	const navigate = useNavigate();

	useEffect(() => {
		if (navigator.appVersion.indexOf('iPhone') === -1) {
			setIsIos(false);
		}
	}, []);

	return (
		<>
			<NavigationHome />
			<ScreenHeading heading='App Settings' />
			<div className='app-settings-screen'>
				<div className='app-settings-container'>
					<div className='settings-header-container'>
						<img src='/img/fms_icon.png' className='settings-icon' alt='Settings-Icon' />
						<div className='settings-user-info-container'>
							<div className='settings-user-info'>
								<h1 className='settings-name-header'>{jwt.data.name}</h1>
								<h5 className='settings-role-header'>{jwt.data.role}</h5>
							</div>
							<div className='settings-edit-icon'>
								<svg
									className='settings-edit-svg'
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 24 24'
									onClick={() => {
										// navigate('/edit-info');
									}}>
									<path d='M 18.414062 2 C 18.158188 2 17.902031 2.0974687 17.707031 2.2929688 L 16 4 L 20 8 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.925594 2.0974687 18.669937 2 18.414062 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z' />
								</svg>
							</div>
						</div>
					</div>
					<div className='settings-options'>
						{/* <div
							className='notification-settings settings-options-container'
							onClick={() => {
								navigate('/faq');
							}}>
							<p className='settings-title'>FAQ</p>
							<FaChevronRight />
						</div> */}
						<div
							className='notification-settings settings-options-container'
							onClick={() => {
								navigate('/set-primary-market');
							}}>
							<p className='settings-title'>Primary Market</p>
							<FaChevronRight />
						</div>
						{!isIos && (
							<div
								className='notification-settings settings-options-container'
								onClick={() => {
									navigate('/notifications-settings');
								}}>
								<p className='settings-title'>Notifications</p>
								<FaChevronRight />
							</div>
						)}
						<div
							className='notification-settings settings-options-container'
							onClick={() => {
								navigate('/my-devices');
							}}>
							<p className='settings-title'>My Devices</p>
							<FaChevronRight />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SettingsScreen;
