import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NavigationSettings = () => {
	const navigate = useNavigate();

	return (
		<nav className='navbar'>
			<div className='navigation-home' onClick={() => navigate('/app-settings')}>
				<FaChevronLeft />
				<span className='navigation-home-text'>Settings</span>
			</div>
		</nav>
	);
};

export default NavigationSettings;
