import { useState } from 'react';

import './FMS.css';

import toast from 'react-hot-toast';
// import FormData from 'form-data';

import ScreenHeading from '../../components/ScreenHeading';
import NavigationMenu from '../../components/NavigationMenu';

// import useJwt from '../../hooks/useJwt';

const AddFmsNoticeScreen = () => {
    const [formData, setFormData] = useState({
        notice: '',
        isNoticeValid: true,
        isFormValid: true,
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    // const jwt = useJwt();

    const handleInputChange = (event) => {
        setFormData({ ...formData, notice: event.target.value });
    };

    const validateForm = () => {
        setIsButtonDisabled(true);

        let validForm = true;
        let validNotice = true;

        // Validate subject
        if (formData.notice === '') {
            validForm = false;
            validNotice = false;
        }

        setFormData({ ...formData, isFormValid: validForm, isNoticeValid: validNotice });

        if (!validForm) {
            toast.error('Notice is invalid. Please provide a valid notice.');
            setIsButtonDisabled(false);
        } else {
            addNotice();
        }
    };

    const addNotice = async () => {
        // let postFormData = new FormData();
        // postFormData.append('notice', formData.title);
        // postFormData.append('uuid', jwt.data.id);
        // // Send request to app backend
        // const response = await fetch('https://staffapi.freshmarksystems.co.za/fms/add-notice', {
        // 	method: 'POST',
        // 	body: postFormData,
        // });
        // const responseData = await response.json();
    };

    return (
        <>
            <NavigationMenu />
            <ScreenHeading heading='FMS Notice' />
            <div className='add-fms-notice-screen'>
                <div className='add-fms-notice-container'>
                    <div className='form-control'>
                        <label className='form-control-label'>Notice</label>
                        <textarea value={formData.notice} className='form-input form-textarea' id='body' rows='10' onChange={handleInputChange}></textarea>
                        {!formData.isNoticeValid && <p className='form-input-error'>Invalid notice.</p>}
                    </div>
                    <input type='submit' value='Add Notice' className='btn btn-primary-solid mt-md' onClick={validateForm} disabled={isButtonDisabled} />
                </div>
            </div>
        </>
    );
};

export default AddFmsNoticeScreen;
