import { useState, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useJwt from '../../hooks/useJwt';

import NavigationBack from '../../components/NavigationBack';
import CustomLoader from '../../components/CustomLoader';
import ScreenHeading from '../../components/ScreenHeading';
import toast from 'react-hot-toast';

const ActionBroadcastNotificationScreen = () => {
	const { state } = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [notification, setNotification] = useState({});
	const [declinedReason, setDeclinedReason] = useState('');
	const [overlayState, setOverlayState] = useState('overlay-animate-out');
	const [declineOverlayState, setDeclineOverlayState] = useState('overlay-animate-out');
	const [escalateOverlayState, setEscalateOverlayState] = useState('overlay-animate-out');
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [isReasonValid, setIsReasonValid] = useState(true);
	const [isClaimant, setIsClaimant] = useState(false);
	const jwt = useJwt();
	const navigate = useNavigate();

	useEffect(
		() => {
			getNotification();
		},
		// eslint-disable-next-line
		[],
	);

	const getNotification = async () => {
		setIsLoading(true);

		// check for pending notifications, show a widget if there are
		let postFormData = new FormData();

		postFormData.append('id', state.id);
		postFormData.append('uuid', jwt.data.id);

		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/get-broadcast-notification', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setNotification(responseData.notification);

			if (responseData.notification.claimant_name === jwt.data.name) {
				// If the person looking at the notification is the person that claimed it
				setIsClaimant(true);
			}

			setIsLoading(false);
		} else {
			setIsLoading(false);
			toast.error('Unable to fetch notification at this time. Returning to previous screen.');
			setTimeout(() => {
				navigate('/pending-broadcast-notifications', { replace: true });
			}, 2000);
		}
	};

	// ! TEST TEST TEST TEST TEST TEST TEST!!!!!
	const releaseNotificationForSending = async () => {
		// claim the notification for yourself
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('id', notification.id);

		const loadingToast = toast.loading('Releasing broadcast notification for sending...');
		setIsButtonDisabled(true);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/release-broadcast-notification', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			toast.dismiss(loadingToast);
			toast.success('Broadcast notification has been released. Navigating to pending notifications screen...', { duration: 3000 });

			setTimeout(() => {
				navigate('/pending-broadcast-notifications', { replace: true });
			}, 3000);
		} else {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to perform this action at this time. Please try again.');
		}
	};

	const declineRequestForNotification = async () => {
		setIsButtonDisabled(true);

		const loadingToast = toast.loading('Working...');

		if (declinedReason === '') {
			setIsButtonDisabled(false);
			setIsReasonValid(false);
		} else {
			// Decline the request
			let postFormData = new FormData();

			postFormData.append('id', notification.id);
			postFormData.append('uuid', jwt.data.id);
			postFormData.append('reason', declinedReason);

			const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/decline-broadcast-notification', {
				method: 'POST',
				body: postFormData,
			});

			const responseData = await response.json();

			if (responseData.message === 'success') {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.success('Request for broadcast notification has been declined. Navigating to pending notifications screen...', { duration: 3000 });

				setTimeout(() => {
					navigate('/pending-broadcast-notifications', { replace: true });
				}, 3000);
			} else {
				setIsButtonDisabled(false);
				toast.dismiss(loadingToast);
				toast.error('Unable to perform this action at this time. Please try again.');
			}
		}
	};

	const claimBroadcastNotification = async () => {
		// claim the notification for yourself
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('id', notification.id);

		const loadingToast = toast.loading('Claiming broadcast notification...');
		setIsButtonDisabled(true);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/claim-broadcast-notification', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			// you have claimed it
			// navigate to screen to show notification and actions
			toast.dismiss(loadingToast);
			toast.success('You have successfully claimed this broadcast notification. Please action it timeously.');

			setIsButtonDisabled(false);

			getNotification();
		} else if (responseData.message === 'claimed') {
			// someone claimed while you were trying to claim
			// explain this
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('It appears that somebody managed to claim this broadcast notification before you. Please refresh the page to see who it was.');
		} else {
			toast.dismiss(loadingToast);
			toast.error('Unable to claim this broadcast notification at this time. Please try again.');
			setIsButtonDisabled(false);
		}
	};

	const escalateToExco = async () => {
		// escalate to exco
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('id', notification.id);

		const loadingToast = toast.loading('Escalating notification to EXCO...');
		setIsButtonDisabled(true);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/escalate-notification-to-exco', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'sent') {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.success('Request for broadcast notification has been escalated to EXCO. Navigating to pending notifications screen...', { duration: 3000 });

			setTimeout(() => {
				navigate('/pending-broadcast-notifications', { replace: true });
			}, 3000);
		} else {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to perform this action at this time. Please try again.');
		}
	};

	const handleInputChange = (event) => {
		setDeclinedReason(event.target.value);
	};

	const closeOverlay = () => {
		setConfirm(false);
		setOverlayState('overlay-animate-out');
	};

	const closeDeclineOverlay = () => {
		setDeclineOverlayState('overlay-animate-out');
	};

	const closeEscalateOverlay = () => {
		setEscalateOverlayState('overlay-animate-out');
	};

	const getNotificationActions = () => {
		if (notification.claimed === 'N') {
			return (
				<div className='action-notification-actions'>
					<div className='action-notification-manco-actions'>
						<input type='button' className='btn-md btn-primary-solid' value='Claim Notification' onClick={claimBroadcastNotification} />
						<input
							type='button'
							className='btn-md btn-primary-solid'
							value='Release Notification'
							onClick={() => setOverlayState('overlay-animate-in')}
						/>
					</div>
					<div className='action-notification-manco-actions'>
						<input
							type='button'
							className='btn-md btn-primary-solid'
							value='Decline Request'
							onClick={() => setDeclineOverlayState('overlay-animate-in')}
						/>
						<input
							type='button'
							className='btn-md btn-primary-solid'
							value='Escalate to EXCO'
							onClick={() => setEscalateOverlayState('overlay-animate-in')}
						/>
					</div>
				</div>
			);
		}

		return (
			<div className='action-notification-actions'>
				<div className='action-notification-manco-actions'>
					<input
						type='button'
						className='btn-md btn-primary-solid'
						value='Release Notification'
						onClick={() => setOverlayState('overlay-animate-in')}
					/>
					<input
						type='button'
						className='btn-md btn-primary-solid'
						value='Decline Request'
						onClick={() => setDeclineOverlayState('overlay-animate-in')}
					/>
				</div>
				<input
					type='button'
					className='btn-md btn-primary-solid escalate-button'
					value='Escalate to EXCO'
					onClick={() => setEscalateOverlayState('overlay-animate-in')}
				/>
			</div>
		);
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching pending notifications...' />}
			<NavigationBack />
			<ScreenHeading heading='Notification Actions' />
			{/* Confirm release */}
			<div className={`confirm-overlay ${overlayState}`}>
				<div className='confirm-dialog-container'>
					<span className='close-overlay' onClick={closeOverlay}>
						&#10006;
					</span>
					<p className='confirm-dialog-text'>Are you sure you want to release this notification for sending? This action cannot be undone.</p>
					<div className='release-notification-confirm'>
						<p className='release-notification-confirm-text'>I understand and wish to proceed.</p>
						<input type='checkbox' checked={confirm} onChange={() => setConfirm(!confirm)} />
					</div>
					{confirm && (
						<div className='release-notification-actions'>
							<input
								type='button'
								className='btn-md btn-primary-solid'
								value='Release Notification'
								disabled={isButtonDisabled}
								onClick={releaseNotificationForSending}
							/>
						</div>
					)}
				</div>
			</div>
			{/* Give reason if declined */}
			<div className={`confirm-overlay ${declineOverlayState}`}>
				<div className='confirm-dialog-container'>
					<p className='confirm-dialog-text'>Please provide a brief reason as to why this notification request is being declined.</p>
					<div className='form-control'>
						<label className='form-control-label'>Reason</label>
						<textarea value={declinedReason} className='form-input form-textarea' id='body' rows='4' onChange={handleInputChange}></textarea>
						{!isReasonValid && <p className='form-input-error'>Invalid reason.</p>}
					</div>
					<div className='decline-notification-actions'>
						<input
							type='button'
							className='btn-md btn-primary-solid'
							value='Decline Request'
							disabled={isButtonDisabled}
							onClick={declineRequestForNotification}
						/>
						<input type='button' className='btn-md btn-danger-solid' value='Cancel' disabled={isButtonDisabled} onClick={closeDeclineOverlay} />
					</div>
				</div>
			</div>
			{/* Escalate overlay */}
			<div className={`confirm-overlay ${escalateOverlayState}`}>
				<div className='confirm-dialog-container'>
					<p className='confirm-dialog-text'>Are you sure you want to escalate this notification to EXCO?</p>
					<div className='decline-notification-actions'>
						<input type='button' className='btn-md btn-primary-solid' value='Escalate' disabled={isButtonDisabled} onClick={escalateToExco} />
						<input type='button' className='btn-md btn-danger-solid' value='Cancel' disabled={isButtonDisabled} onClick={closeEscalateOverlay} />
					</div>
				</div>
			</div>

			<div className='action-notification-screen'>
				<div className='action-notification-container'>
					<label className='action-notification-label'>Notification Title</label>
					<p className='action-notification-title'>{notification.notification_title}</p>
					<label className='action-notification-label'>Notification Body</label>
					<p className='action-notification-body'>{notification.notification_body}</p>
					<label className='action-notification-label'>Requested By</label>
					<p className='action-notification-info'>
						{notification.author_name} from {notification.market_description} on <em>{notification.notification_date}</em>.
					</p>
					<label className='action-notification-label'>Recipients</label>
					<ul className='action-notification-recipient-list'>
						{notification.market_staff === 'Y' && <li className='action-notification-recipient-list-item'>Market Staff</li>}
						{notification.agents === 'Y' && <li className='action-notification-recipient-list-item'>Agents</li>}
						{notification.buyers === 'Y' && <li className='action-notification-recipient-list-item'>Buyers</li>}
						{notification.suppliers === 'Y' && <li className='action-notification-recipient-list-item'>Suppliers</li>}
					</ul>
					{notification.claimed === 'Y' && (
						<div className='action-notification-claimed'>
							This notification was claimed by {isClaimant ? 'you' : notification.claimant_name} on <em>{notification.date_claimed}</em>. If you
							are seeing this, it means that this notification has not been actioned yet.
						</div>
					)}
					{notification.claimed === 'N' && getNotificationActions()}
					{isClaimant && getNotificationActions()}
				</div>
			</div>
		</>
	);
};

export default ActionBroadcastNotificationScreen;
