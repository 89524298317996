import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({ children }) => {
	// Custom useAuth hook
	const auth = useAuth();

	return auth ? children : <Navigate to='/sign-in' replace />;
};

export default RequireAuth;
