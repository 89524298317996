import { useState, useEffect } from 'react';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';
import CustomLoader from '../../components/CustomLoader';

import { useLocation } from 'react-router-dom';
import useJwt from '../../hooks/useJwt';
import toast from 'react-hot-toast';

const BuyerBalanceScreen = () => {
    const { state } = useLocation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [widgetExists, setWidgetExists] = useState(false);
    const jwt = useJwt();

    useEffect(
        () => {
            checkIfWidgetExists();
        },
        // eslint-disable-next-line
        []
    );

    const formatNumber = (number) => {
        var numberParts = number.toString().split('.');
        numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return numberParts.join('.');
    };

    // Check if this widget has already been added, if so, set in state and show remove button
    const checkIfWidgetExists = async () => {
        setIsLoading(true);

        const postFormData = new FormData();

        postFormData.append('uuid', jwt.data.id);
        postFormData.append('buyer_number', state.buyerNumber);
        postFormData.append('market', state.marketCode);

        const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/check-widget', {
            method: 'POST',
            body: postFormData,
        });

        const responseData = await response.json();

        if (responseData.message === 'exists') {
            setWidgetExists(true);
        } else {
            setWidgetExists(false);
        }

        setIsLoading(false);
    };

    const getDate = (date, time) => {
        // new Date(date);
        const formattedDate = new Date(date).toLocaleString('en-us', { month: 'short', year: 'numeric', day: 'numeric' });

        const hour = time.split(':')[0];
        let descriptor = '';

        if (parseInt(hour) < 12) {
            descriptor = 'AM';
        } else {
            descriptor = 'PM';
        }

        const formattedTime = time + ' ' + descriptor;

        return formattedDate + ' ' + formattedTime;
    };

    const addToHomeScreen = async () => {
        const postFormData = new FormData();

        setIsButtonDisabled(true);
        const loadingToast = toast.loading('Adding widget...');

        postFormData.append('uuid', jwt.data.id);
        postFormData.append('buyer_number', state.buyerNumber);
        postFormData.append('market', state.marketCode);

        const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/add-buyer-widget', {
            method: 'POST',
            body: postFormData,
        });

        const responseData = await response.json();

        if (responseData.message === 'success') {
            setIsButtonDisabled(false);
            toast.dismiss(loadingToast);
            toast.success('Widget successfully added.', { duration: 2000 });
            setWidgetExists(true);
        } else {
            setIsButtonDisabled(false);
            toast.error('Unable to add widget at this time. Please try again.');
            setWidgetExists(false);
        }
    };

    const removeFromHomeScreen = async () => {
        const postFormData = new FormData();

        setIsButtonDisabled(true);
        const loadingToast = toast.loading('Removing widget...');

        postFormData.append('uuid', jwt.data.id);
        postFormData.append('buyer_number', state.buyerNumber);
        postFormData.append('market', state.marketCode);

        const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/remove-buyer-widget', {
            method: 'POST',
            body: postFormData,
        });

        const responseData = await response.json();

        if (responseData.message === 'success') {
            setIsButtonDisabled(false);
            toast.dismiss(loadingToast);
            toast.success('Widget succsessfully removed.', { duration: 2000 });
            setWidgetExists(false);
        } else {
            setIsButtonDisabled(false);
            toast.error('Unable to remove widget at this time. Please try again.');
            setWidgetExists(true);
        }
    };

    return (
        <>
            {isLoading && <CustomLoader text='Fetching buyer information...' />}
            <NavigationBack />
            <ScreenHeading heading='Buyer Balance' />
            <div className='buyer-balance-screen'>
                <div className='buyer-balance-container'>
                    <div className='buyer-balance-header-container'>
                        <h3 className='buyer-balance-header'>{`${state.market} (${state.buyerNumber})`}</h3>
                    </div>
                    <div className='balance-container'>
                        <p className='balance-info-text'>Available Balance:</p>
                        <p className='balance-amount-text'>{`R ${formatNumber(state.result.balance.toFixed(2))}`}</p>
                    </div>
                    {state.result.trans.length === 0 && (
                        <div className='no-transactions-container'>
                            <p className='no-transactions-text'>No recent trasactions to display.</p>
                        </div>
                    )}
                    {state.result.trans.length > 0 && (
                        <div className='transactions-container'>
                            <h4 className='transactions-header'>Transactions</h4>
                            {state.result.trans.map((transaction, index) => {
                                return (
                                    <div className='transaction' key={index}>
                                        <div className='transaction-details'>
                                            <p className='transaction-info'>Date:</p>
                                            <p className='transaction-description'>{`${getDate(transaction.tran_date, transaction.tran_time)}`}</p>
                                        </div>
                                        <div className='transaction-details'>
                                            <p className='transaction-info'>Value:</p>
                                            <p className='transaction-description'>{`R ${transaction.value}`}</p>
                                        </div>
                                        <div className='transaction-details'>
                                            <p className='transaction-info'>Type:</p>
                                            <p className='transaction-description'>{`${transaction.tran_type}`}</p>
                                        </div>
                                        <div className='transaction-details'>
                                            <p className='transaction-info'>Pay Method:</p>
                                            <p className='transaction-description'>{`${transaction.pay_method}`}</p>
                                        </div>
                                        <div className='transaction-details'>
                                            <p className='transaction-info'>Cashier:</p>
                                            <p className='transaction-description'>{`${transaction.cashier}`}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!widgetExists && (
                        <input type='button' className='btn btn-primary-solid' value='Add Widget' disabled={isButtonDisabled} onClick={addToHomeScreen} />
                    )}
                    {widgetExists && (
                        <input
                            type='button'
                            className='btn btn-danger-solid'
                            value='Remove Widget'
                            disabled={isButtonDisabled}
                            onClick={removeFromHomeScreen}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default BuyerBalanceScreen;
