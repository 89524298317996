import { useState, useEffect } from 'react';

import './Buyer.css';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';
import CustomLoader from '../../components/CustomLoader';
import UserMarketsDropdown from '../../components/UserMarketsDropdown';

import toast from 'react-hot-toast';

import useJwt from '../../hooks/useJwt';

const BlockBuyerCardScreen = () => {
	const [markets, setMarkets] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [selectedMarket, setSelectedMarket] = useState('');
	const [isMarketDropdownOpen, setIsMarketDropdownOpen] = useState(false);
	const [buyer, setBuyer] = useState([]);
	const [overlayState, setOverlayState] = useState('overlay-animate-out');
	const [buyerNumberToBeBlocked, setBuyerNumberToBeBlocked] = useState();
	const [fetching, setFetching] = useState(false);
	const jwt = useJwt();

	useEffect(
		() => {
			getBuyerMarkets();
		},
		// eslint-disable-next-line
		[],
	);

	const getBuyerMarkets = async () => {
		setIsLoading(true);
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('buyer', buyerNumberToBeBlocked);

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/get-buyer-markets', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setMarkets(responseData.markets);

			setIsLoading(false);
		} else {
			setIsLoading(false);
			toast.error('Unable to fetch buyer markets at this time. Please try again.');
		}
	};

	const blockBuyerCardAtMarket = async () => {
		setOverlayState('overlay-animate-out');
		setConfirm(false);

		const loadingToast = toast.loading('Blocking buyer card...');

		let postFormData = new FormData();

		postFormData.append('query', 'block-buyer');
		postFormData.append('device', jwt.data.id);
		postFormData.append('buyer_number', buyerNumberToBeBlocked);
		postFormData.append('market', selectedMarket);
		postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
		postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

		// Send request to app backend
		const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/market-query', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.success) {
			setFetching(true);
			toast.dismiss(loadingToast);
			toast.success('Buyer card has been blocked. Please visit the head cashier to undo this.');
			await fetchBuyerNumbers();
		} else {
			toast.dismiss(loadingToast);
			toast.error('Unable to block buyer card at this time. Please try again.');
		}
	};

	const fetchBuyerNumbers = async () => {
		if (selectedMarket === '') {
			toast.error('Please select a market.');
			return false;
		}

		let loadingToast;

		if (!fetching) {
			loadingToast = toast.loading('Fetching buyer numbers...');
		}

		// Reset buyer array
		setBuyer([]);

		let postFormData = new FormData();

		postFormData.append('device', jwt.data.id);
		postFormData.append('query', 'registered-buyers');
		postFormData.append('market', selectedMarket);
		postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
		postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

		// Send request to app backend
		const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/market-query', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.success) {
			responseData.result.buyers.forEach((b) => {
				setBuyer([
					{
						buyerNumber: b.buyer_number,
						buyerName: b.buyer_name,
						main: b.main,
						blocked: b.blocked,
						salesBlocked: b.sales_blocked,
						refundsBlocked: b.refunds_blocked,
						subBuyers: b.sub_buyers,
					},
				]);
				setIsButtonDisabled(false);
				setFetching(false);
			});

			toast.dismiss(loadingToast);
		}
	};

	const getAction = (b) => {
		// Pass in the current iteration of buyer - b
		if (b.refundsBlocked === 'Y' && b.salesBlocked === 'Y') {
			return <h6 className='blocked-message'>Blocked</h6>;
		}

		if (b.refundsBlocked === 'Y' && b.salesBlocked === 'N') {
			return <h6 className='blocked-message'>Refunds Blocked</h6>;
		}

		if (b.salesBlocked === 'Y' && b.refundsBlocked === 'N') {
			return <h6 className='blocked-message'>Sales Blocked</h6>;
		}

		return (
			<input
				type='button'
				value='Block'
				className='btn-xs btn-danger-solid block-card-button'
				onClick={() => {
					setBuyerNumberToBeBlocked(b.buyerNumber);
					setOverlayState('overlay-animate-in');
				}}
			/>
		);
	};

	const closeOverlay = () => {
		setConfirm(false);
		setOverlayState('overlay-animate-out');
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching buyer information...' />}
			<NavigationBack />
			<ScreenHeading heading='Block Buyer Card' />
			<div className={`confirm-overlay ${overlayState}`}>
				<div className='confirm-dialog-container'>
					<span className='close-overlay' onClick={closeOverlay}>
						&#10006;
					</span>
					<p className='confirm-dialog-text'>
						Are you sure you want to block this buyer card? This action cannot be undone using the app. You will need to go to the head cashier to
						unblock your card once it has been blocked.
					</p>
					<div className='block-card-confirm'>
						<p className='block-card-confirm-text'>I understand and wish to proceed.</p>
						<input type='checkbox' checked={confirm} onChange={() => setConfirm(!confirm)} />
					</div>
					{confirm && (
						<div className='block-card-actions'>
							<input
								type='button'
								className='btn btn-primary-solid'
								value='Block Card'
								disabled={isButtonDisabled}
								onClick={blockBuyerCardAtMarket}
							/>
						</div>
					)}
				</div>
			</div>

			<div className='block-card-screen'>
				<div className='block-card-container'>
					<UserMarketsDropdown
						markets={markets}
						setSelectedMarket={setSelectedMarket}
						isMarketDropdownOpen={isMarketDropdownOpen}
						setIsMarketDropdownOpen={setIsMarketDropdownOpen}
					/>
					<input type='button' className='btn btn-primary-solid' value='Get Buyer Cards' disabled={isButtonDisabled} onClick={fetchBuyerNumbers} />
					<div className='cards-container'>
						{buyer.length > 0 && <h3 className='cards-header'>Buyer Cards</h3>}
						{buyer.map((b, index) => (
							<div key={index}>
								<div className='block-action'>
									<h6 className='buyer-account-number'>{b.buyerName + ' (' + b.buyerNumber + ')'}</h6>
									{getAction(b)}
								</div>
								{b.subBuyers.length > 0 && (
									<div className='sub-buyer-accounts'>
										{b.subBuyers.map((sb, index) => (
											<div className='block-action' key={index}>
												{/* TODO - THIS NEEDS TO BE FINISHED WITH RICHARD NEXT WEEK */}
												{/* <h6 className='buyer-account-number'>{sb.buyerName + ' (' + sb.buyerNumber + ')'}</h6> */}
											</div>
										))}
									</div>
								)}
							</div>
						))}
					</div>
					{/* <div className='block-card-dialog'>
                        {markets.length > 0 && (
                            <p className='block-card-dialog-text'>
                                Are you sure you want to block your buyer card for <strong>{markets[0]['market_name']}</strong>? This action cannot be undone
                                using the app. You will need to go to the head cashier to unblock your card once it has been blocked. Do you wish to proceed?
                            </p>
                        )}
                    </div>
                    <div className='block-card-confirm'>
                        <p className='block-card-confirm-text'>I understand and wish to proceed.</p>
                        <input type='checkbox' checked={confirm} onChange={() => setConfirm(!confirm)} />
                    </div>
                    {confirm && (
                        <div className='block-card-actions'>
                            <input type='button' className='btn btn-primary-solid' value='Confirm' disabled={isButtonDisabled} onClick={() => {}} />
                        </div>
                    )} */}
				</div>
			</div>
		</>
	);
};

export default BlockBuyerCardScreen;
