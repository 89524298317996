import { useState, useRef, useEffect } from 'react';

const RoleDropdown = ({ filteredRoles, formData, setFormData, isRoleDropdownOpen, setIsRoleDropdownOpen, setIsMarketDropdownOpen, setIsGroupDropdownOpen }) => {
    // DROPDOWN STUFF
    const [selectedItem, setSelectedItem] = useState('');
    const ref = useRef();

    // Handle outside click to close dropdown
    useEffect(
        () => {
            const checkIfClickedOutside = (event) => {
                if (isRoleDropdownOpen && ref.current && !ref.current.contains(event.target)) {
                    setIsRoleDropdownOpen(false);
                }
            };

            document.addEventListener('mousedown', checkIfClickedOutside);

            return () => {
                document.removeEventListener('mousedown', checkIfClickedOutside);
            };
        },
        // eslint-disable-next-line
        [isRoleDropdownOpen]
    );

    const toggleDropdown = () => {
        setIsRoleDropdownOpen(!isRoleDropdownOpen);
        setIsMarketDropdownOpen(false);
        setIsGroupDropdownOpen(false);
    };

    const handleItemClick = (event) => {
        const idSplit = event.target.id.split('_');

        setSelectedItem(idSplit[0]);
        setIsRoleDropdownOpen(!isRoleDropdownOpen);
        setFormData({ ...formData, role: idSplit[1], roleDescription: idSplit[0] });
    };

    return (
        <div className='dropdown mt-md' ref={ref}>
            <div className={`dropdown-title ${isRoleDropdownOpen && `open`}`} onClick={toggleDropdown}>
                {selectedItem === '' ? 'Select Designation' : selectedItem}
                <i className={`fa fa-chevron-down dropdown-icon ${isRoleDropdownOpen && 'open'}`}></i>
            </div>
            {isRoleDropdownOpen && (
                <div className='dropdown-body'>
                    <ul className='dropdown-items'>
                        {filteredRoles.map((role, index) => {
                            return (
                                <li
                                    className='dropdown-item'
                                    key={index}
                                    onClick={(event) => handleItemClick(event)}
                                    id={role.role_description + '_' + role.id}
                                >
                                    {role.role_description}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default RoleDropdown;
