import { useRef } from 'react';
import jwt_decode from 'jwt-decode';

const useAuth = () => {
	const auth = useRef(true);

	// get token from local storage
	const token = localStorage.getItem('token');

	if (token) {
		const decoded = jwt_decode(token);

		const currentTime = new Date().getTime() / 1000;

		if (decoded.exp < currentTime) {
			// clear token from local storage
			localStorage.setItem('token', '');
			auth.current = false;
		}
	} else {
		auth.current = false;
	}

	return auth.current;
};

export default useAuth;
