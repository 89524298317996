import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

import useJwt from '../../hooks/useJwt';

import NavigationMenu from '../../components/NavigationMenu';
import ScreenHeading from '../../components/ScreenHeading';
import CustomLoader from '../../components/CustomLoader';

const PendingBroadcastNotificationsScreen = () => {
	const [pendingNotifications, setPendingNotifications] = useState([]);
	const [nonePending, setNonePending] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const jwt = useJwt();
	const navigate = useNavigate();

	useEffect(
		() => {
			getPendingNotifications();
		},
		// eslint-disable-next-line
		[],
	);

	const getPendingNotifications = async () => {
		setIsLoading(true);
		// check for pending notifications, show a widget if there are
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/get-broadcast-notifications', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			if (responseData.notifications === 'none') {
				// no pending notifications
				setNonePending(true);
				setIsLoading(false);
			} else {
				setPendingNotifications(responseData.notifications);
				setIsLoading(false);
			}
		} else {
			// show error
			setIsLoading(false);
		}
	};

	const claimBroadcastNotification = async (notificationId) => {
		// claim the notification for yourself
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('id', notificationId);

		const loadingToast = toast.loading('Claiming broadcast notification...');
		setIsButtonDisabled(true);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/claim-broadcast-notification', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			// you have claimed it
			// navigate to screen to show notification and actions
			toast.dismiss(loadingToast);
			toast.success('You have successfully claimed this broadcast notification. Please action it timeously.');

			setIsButtonDisabled(false);

			navigate('/action-broadcast-notification', {
				state: { id: notificationId },
			});
		} else if (responseData.message === 'claimed') {
			// someone claimed while you were trying to claim
			// explain this
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('It appears that somebody managed to claim this broadcast notification before you. Please refresh the page to see who it was.');
		} else {
			toast.dismiss(loadingToast);
			toast.error('Unable to claim this broadcast notification at this time. Please try again.');
			setIsButtonDisabled(false);
		}
	};

	const viewBroadcastNotification = (notificationId) => {
		navigate('/action-broadcast-notification', {
			state: { id: notificationId },
		});
	};

	const checkStatusOfNotification = (notification) => {
		// if claimed and not escalated
		if (notification.escalated === 'Y') {
			if (jwt.data.role === 'FMS Exco') {
				return (
					<>
						<div className='bnot-actions'>
							<input
								type='button'
								className='btn-sm btn-primary-solid'
								value='Claim'
								disabled={isButtonDisabled}
								onClick={() => claimBroadcastNotification(notification.id)}
							/>
							<input
								type='button'
								className='btn-sm btn-primary-solid'
								value='View'
								disabled={isButtonDisabled}
								onClick={() => viewBroadcastNotification(notification.id)}
							/>
						</div>
					</>
				);
			}

			return (
				<div className='bnot-claimed-container'>
					<p className='bnot-escalated'>This notification has been escalated to EXCO.</p>
				</div>
			);
		}

		if (notification.claimed === 'Y') {
			return (
				<>
					<div className='bnot-claimed-container'>
						<p className='bnot-claimed-by'>
							Claimed by {notification.claimant_name === jwt.data.name ? <strong>you</strong> : <strong>{notification.claimant_name}</strong>} on{' '}
							<em className='bnot-date-claimed'>{notification.date_claimed}</em>.
						</p>
					</div>
					<div className='bnot-actions'>
						<input
							type='button'
							className='btn-sm btn-primary-solid'
							value='View'
							disabled={isButtonDisabled}
							onClick={() => viewBroadcastNotification(notification.id)}
						/>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className='bnot-actions'>
						<input
							type='button'
							className='btn-sm btn-primary-solid'
							value='Claim'
							disabled={isButtonDisabled}
							onClick={() => claimBroadcastNotification(notification.id)}
						/>
						<input
							type='button'
							className='btn-sm btn-primary-solid'
							value='View'
							disabled={isButtonDisabled}
							onClick={() => viewBroadcastNotification(notification.id)}
						/>
					</div>
				</>
			);
		}

		// {notification.claimed === 'Y' ? (
		//     <>
		//         <div className='bnot-claimed-container'>
		//             <p className='bnot-claimed-by'>
		//                 Claimed by{' '}
		//                 {notification.claimant_name === jwt.data.name ? (
		//                     <strong>you</strong>
		//                 ) : (
		//                     <strong>{notification.claimant_name}</strong>
		//                 )}{' '}
		//                 on <em className='bnot-date-claimed'>{notification.date_claimed}</em>.
		//             </p>
		//         </div>
		//         <div className='bnot-actions'>
		//             <input
		//                 type='button'
		//                 className='btn-sm btn-primary-solid'
		//                 value='View'
		//                 disabled={isButtonDisabled}
		//                 onClick={() => viewBroadcastNotification(notification.id)}
		//             />
		//         </div>
		//     </>
		// ) : (
		//     <div className='bnot-actions'>
		//         <input
		//             type='button'
		//             className='btn-sm btn-primary-solid'
		//             value='Claim'
		//             disabled={isButtonDisabled}
		//             onClick={() => claimBroadcastNotification(notification.id)}
		//         />
		//         <input
		//             type='button'
		//             className='btn-sm btn-primary-solid'
		//             value='View'
		//             disabled={isButtonDisabled}
		//             onClick={() => viewBroadcastNotification(notification.id)}
		//         />
		//     </div>
		// )}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching pending notifications...' />}
			<NavigationMenu />
			<ScreenHeading heading='Pending Notifications' />
			<div className='pending-notifications-screen'>
				<div className='pending-notifications-container'>
					{nonePending && <div className='no-pending-notifications-container'>There are currently no pending broadcast notifications.</div>}
					{pendingNotifications.map((notification) => {
						return (
							<div className='bnot-container' key={notification.id}>
								<h3 className='bnot-author-name'>{notification.author_name}</h3>
								<h4 className='bnot-market'>{notification.market_description}</h4>
								<div className='bnot-title-container'>
									<div className='bnot-label-and-date'>
										<label className='bnot-label'>Notification Title</label>
										<p className='bnot-request-date'>{notification.notification_date}</p>
									</div>
									<p className='bnot-title'>{notification.notification_title}</p>
								</div>
								{checkStatusOfNotification(notification)}
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default PendingBroadcastNotificationsScreen;
