import { useNavigate } from 'react-router-dom';

const PendingNotificationsWidget = ({ pending, name }) => {
	const navigate = useNavigate();

	const getText = () => {
		if (pending === 1) {
			return `Hi, ${name}. There is 1 unclaimed and unactioned broadcast notification. Please action this.`;
		}

		return `Hi, ${name}. There are ${pending} unclaimed and unactioned broadcast notifications. Please action these.`;
	};

	return (
		<div className='pending-broadcast-notifications-widget'>
			<h3 className='pending-broadcast-notifications-header'>Broadcast Notifications Pending</h3>
			<div className='pending-broadcast-notifications-container'>
				<p className='pending-broadcast-notifications-text'>{getText()}</p>
			</div>
			<div className='pending-broadcast-notifications-actions'>
				<input
					type='button'
					className='btn-sm btn-primary-solid pn-btn'
					value='View Pending Notifications'
					disabled={false}
					onClick={() => navigate('/pending-broadcast-notifications')}
				/>
			</div>
		</div>
	);
};

export default PendingNotificationsWidget;
