import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import useJwt from '../../hooks/useJwt';
import { useNavigate } from 'react-router-dom';
import { Document, Page, Text, PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';

import CustomLoader from '../../components/CustomLoader';
import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';
import BuyerMarketDropdown from '../../components/BuyerMarketDropdown';
import BuyerNumberDropdown from '../../components/BuyerNumberDropdown';

const styles = StyleSheet.create({
	page: {
		paddingTop: 30,
		paddingBottom: 50,
		paddingHorizontal: 35,
	},
	text: {
		whiteSpace: 'pre',
		fontSize: 8,
	},
});

const BuyerStatementScreen = () => {
	const [markets, setMarkets] = useState([]);
	const [buyerNumbers, setBuyerNumbers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [selectedMarket, setSelectedMarket] = useState('');
	const [selectedBuyer, setSelectedBuyer] = useState('');
	const [statementText, setStatementText] = useState('');
	const [isMarketDropdownOpen, setIsMarketDropdownOpen] = useState(false);
	const [isBuyerNumberDropdownOpen, setIsBuyerNumberDropdownOpen] = useState(false);

	// TODO - get the buyer statement into a pdf
	// TODO - add custom market branding for single market registered buyers and single market users like managers and admins

	const jwt = useJwt();
	const navigate = useNavigate();

	useEffect(
		() => {
			getBuyerMarkets();
		},
		// eslint-disable-next-line
		[],
	);

	const getBuyerMarkets = async () => {
		setIsLoading(true);
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/get-buyer-markets', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setMarkets(responseData.markets);

			// if (responseData.markets.length === 1) {
			//     setOnlyOneMarket(true);
			// }

			setIsLoading(false);
		} else {
			setIsLoading(false);
			toast.error('Unable to fetch buyer markets at this time. Please try again.');
		}
	};

	const getBuyerNumbersForMarket = async (marketCode) => {
		const loadingToast = toast.loading('Fetching buyer numbers...');
		setIsButtonDisabled(true);

		// Reset buyer array
		// setBuyer([]);

		let postFormData = new FormData();

		postFormData.append('device', jwt.data.id);
		postFormData.append('query', 'registered-buyers');
		postFormData.append('market', marketCode);
		postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
		postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

		// Send request to app backend
		const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/market-query', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		const buyers = [];

		if (responseData.success) {
			// iterate through responseData.result.buyers and add buyer_number
			responseData.result.buyers.forEach((buyer) => {
				const buyerObject = {
					buyerNumber: buyer.buyer_number,
					buyerName: buyer.buyer_name,
				};

				buyers.push(buyerObject);
			});

			setBuyerNumbers(buyers);

			setIsButtonDisabled(false);

			toast.dismiss(loadingToast);
		} else {
			setIsButtonDisabled(false);
			toast.error('Unable to fetch buyer numbers for selected market.');
		}
	};

	const validateForm = () => {
		let validForm = true;

		if (selectedBuyer === '') {
			toast.error('Buyer number not selected.');
			validForm = false;
		}

		if (selectedMarket === '') {
			toast.error('Market not selected.');
			validForm = false;
		}

		if (validForm) {
			getUnpaidSales();
		}
	};

	const getUnpaidSales = async () => {
		const loadingToast = toast.loading('Checking for buyer statement...');
		setIsButtonDisabled(true);

		let postFormData = new FormData();

		postFormData.append('query', 'buyer-statement');
		postFormData.append('device', jwt.data.id);
		postFormData.append('market', selectedMarket);
		postFormData.append('buyer_number', selectedBuyer);
		postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
		postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

		// Send request to WS
		const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/market-query', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.success) {
			if (responseData.result.statement_text.length > 0) {
				setIsButtonDisabled(false);
				setStatementText(responseData.result.statement_text);

				toast.dismiss(loadingToast);
				toast.success('Statement found! Generating statement...');

				// setTimeout(() => {
				// 	navigate('/your-buyer-statement', { state: { statementText: responseData.result.statement_text } });
				// }, 3000);

				// if (statementText.length > 0) {
				// }
			} else if (responseData.result.statement_text === false) {
				// show unpaid sales
				toast.dismiss(loadingToast);
				setIsButtonDisabled(false);
				setMarkets([]);
				setSelectedMarket('');
				setBuyerNumbers([]);
				toast.success('No buyer statement available for today!');
				setTimeout(() => {
					navigate(0);
				}, 2000);
			}
		} else {
			// ! FIND OUT ABOUT THIS
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to generate buyer statement at this time. Connection to market may be poor.');
			setTimeout(() => {
				navigate(0);
			}, 2000);
		}
	};

	const getDate = () => {
		const d = new Date();
		let year = '' + d.getFullYear();
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();

		if (month.length === 1) {
			month = '0' + month;
		}

		const concatDate = year + '-' + month + '-' + day;

		const time = d.toLocaleTimeString();

		const formattedDate = new Date(concatDate).toLocaleString('en-us', { month: 'short', year: 'numeric', day: 'numeric' });

		const hour = time.split(':')[0];
		let descriptor = '';

		if (parseInt(hour) < 12) {
			descriptor = 'AM';
		} else {
			descriptor = 'PM';
		}

		const formattedTime = time + ' ' + descriptor;

		return formattedDate + ' ' + formattedTime;
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching buyer information...' />}
			<NavigationBack />
			<ScreenHeading heading='Buyer Statement' />
			<div className='buyer-balance-screen'>
				<div className='buyer-balance-container'>
					<div className='buyer-info-container'>
						You will only be able to view a buyer statement for today's date. If there is no statement for today, you will be notified.
					</div>
					<BuyerMarketDropdown
						markets={markets}
						setSelectedMarket={setSelectedMarket}
						isMarketDropdownOpen={isMarketDropdownOpen}
						setIsMarketDropdownOpen={setIsMarketDropdownOpen}
						clickFunction={getBuyerNumbersForMarket}
					/>
					{buyerNumbers.length > 0 && (
						<BuyerNumberDropdown
							buyers={buyerNumbers}
							setSelectedBuyer={setSelectedBuyer}
							isBuyerNumberDropdownOpen={isBuyerNumberDropdownOpen}
							setIsBuyerNumberDropdownOpen={setIsBuyerNumberDropdownOpen}
						/>
					)}
					<input type='button' className='btn btn-primary-solid' value='Get Buyer Statement' disabled={isButtonDisabled} onClick={validateForm} />
					{statementText.length > 0 && (
						<PDFDownloadLink
							document={
								<Document>
									<Page style={styles.page} wrap={false}>
										<Text style={styles.text}>{statementText}</Text>
									</Page>
								</Document>
							}
							fileName={`buyer_statement_` + getDate() + `.pdf`}>
							<input type='button' className='btn btn-danger-solid' value='Download Statement' disabled={isButtonDisabled} />
						</PDFDownloadLink>
					)}
				</div>
			</div>
		</>
	);
};

export default BuyerStatementScreen;
