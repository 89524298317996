import '../app/App.css';

const ConfirmAlert = ({ text, overlayState, acceptClick, cancelClick, buttonValue }) => {
	return (
		<div className={'overlay ' + overlayState}>
			<div className='overlay-content'>
				<p className='overlay-content-text'>{text}</p>
				<div className='overlay-actions mt-sm'>
					<input type='button' value={buttonValue} className='btn-sm btn-primary-solid' onClick={acceptClick} />
					<input type='button' value='Cancel' className='btn-sm btn-danger-solid ml-sm' onClick={cancelClick} />
				</div>
			</div>
		</div>
	);
};

export default ConfirmAlert;
