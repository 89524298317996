import { useState, useRef, useEffect } from 'react';

const BuyerNumberDropdown = ({ buyers, setSelectedBuyer, isBuyerNumberDropdownOpen, setIsBuyerNumberDropdownOpen }) => {
    // DROPDOWN STUFF
    const [selectedItem, setSelectedItem] = useState('');
    const ref = useRef();

    // Handle outside click to close dropdown
    useEffect(
        () => {
            const checkIfClickedOutside = (event) => {
                if (isBuyerNumberDropdownOpen && ref.current && !ref.current.contains(event.target)) {
                    setIsBuyerNumberDropdownOpen(false);
                }
            };

            document.addEventListener('mousedown', checkIfClickedOutside);

            return () => {
                document.removeEventListener('mousedown', checkIfClickedOutside);
            };
        },
        // eslint-disable-next-line
        [isBuyerNumberDropdownOpen]
    );

    const toggleDropdown = () => {
        setIsBuyerNumberDropdownOpen(!isBuyerNumberDropdownOpen);
    };

    const handleItemClick = (event) => {
        setSelectedItem(event.target.id);
        setIsBuyerNumberDropdownOpen(!isBuyerNumberDropdownOpen);
        setSelectedBuyer(event.target.id);
    };

    return (
        <div className='dropdown mt-md' ref={ref}>
            <div className={`dropdown-title ${isBuyerNumberDropdownOpen && `open`}`} onClick={toggleDropdown}>
                {selectedItem === '' ? 'Select Buyer Number' : selectedItem}
                <i className={`fa fa-chevron-down dropdown-icon ${isBuyerNumberDropdownOpen && 'open'}`}></i>
            </div>
            {isBuyerNumberDropdownOpen && (
                <div className='dropdown-body'>
                    <ul className='dropdown-items'>
                        {buyers.map((buyer, index) => {
                            return (
                                <li className='dropdown-item' key={index} onClick={(event) => handleItemClick(event)} id={buyer.buyerNumber}>
                                    {`${buyer.buyerName}(${buyer.buyerNumber})`}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default BuyerNumberDropdown;
