import { Routes, Route, Navigate } from 'react-router-dom';

// Require auth
import RequireAuth from '../config/RequireAuth';

// Screens
// Auth
import LoginScreen from '../screens/Auth/LoginScreen';
import RegistrationScreen from '../screens/Auth/RegistrationScreen';
import VerificationScreen from '../screens/Auth/VerificationScreen';
import VerifyPasswordChangeScreen from '../screens/Auth/VerifyPasswordChangeScreen';
import ForgotPasswordScreen from '../screens/Auth/ForgotPasswordScreen';

// Protected routes
import NotificationsPromptScreen from '../screens/Notifications/NotificationsPromptScreen';
import HomeScreen from '../screens/Home/HomeScreen';
import AddMarketScreen from '../screens/Registration/AddMarketScreen';
import MarketSystemStatusScreen from '../screens/SystemStatus/MarketSystemStatusScreen';
import ProvinceDirectoryInfoScreen from '../screens/Info/ProvinceDirectoryInfoScreen';
import CheckRegistrationScreen from '../screens/Registration/CheckRegistrationScreen';
import SettingsScreen from '../screens/Settings/SettingsScreen';
import EditPersonalInfoScreen from '../screens/Settings/EditPersonalInfoScreen';
import NotificationsSettingsScreen from '../screens/Settings/NotificationsSettingsScreen';
import FaqScreen from '../screens/Settings/FaqScreen';
import SetPrimaryMarketScreen from '../screens/Settings/SetPrimaryMarketScreen';
import MyDevicesScreen from '../screens/Settings/MyDevicesScreen';
import MarketDirectoryScreen from '../screens/Info/MarketDirectoryScreen';
import NewSupportTicketScreen from '../screens/Support/NewSupportTicketScreen';
import NewCommodityScreen from '../screens/Support/NewCommodityScreen';
import NewsFeedScreen from '../screens/News/NewsFeedScreen';
import BroadcastNotificationScreen from '../screens/Notifications/BroadcastNotificationScreen';
import TrackDeliveriesScreen from '../screens/Deliveries/TrackDeliveriesScreen';
import UserNotificationsScreen from '../screens/Notifications/UserNotificationsScreen';
// import AppUsersScreen from '../screens/Analytics/AppUsersScreen';
import SupportRequestsScreen from '../screens/Support/SupportRequestsScreen';
import NewCommodityRequestsScreen from '../screens/Support/NewCommodityRequestsScreen';

// Buyer routes
import GetBuyerBalanceScreen from '../screens/Buyer/GetBuyerBalanceScreen';
import BuyerBalanceScreen from '../screens/Buyer/BuyerBalanceScreen';
import BlockBuyerCardScreen from '../screens/Buyer/BlockBuyerCardScreen';
import BuyerAccountWidgetsScreen from '../screens/Buyer/BuyerAccountWidgetsScreen';
import BuyerEnquiryScreen from '../screens/Buyer/BuyerEnquiryScreen';
import BuyerUnpaidSalesScreen from '../screens/Buyer/BuyerUnpaidSalesScreen';
import MarketStatsScreen from '../screens/Buyer/MarketStatsScreen';
import ViewBuyerEnquiryScreen from '../screens/Buyer/ViewBuyerEnquiryScreen';
import ViewBuyerUnpaidSalesScreen from '../screens/Buyer/ViewBuyerUnpaidSalesScreen';
import BuyerStatementScreen from '../screens/Buyer/BuyerStatementScreen';
import ViewBuyerStatementScreen from '../screens/Buyer/ViewBuyerStatementScreen';

// FMS routes
import AddFmsNoticeScreen from '../screens/FMS/AddFmsNoticeScreen';
import FmsPasswordChange from '../screens/FMS/FmsPasswordChange';
import PendingBroadcastNotificationsScreen from '../screens/FMS/PendingBroadcastNotificationsScreen';
import ActionBroadcastNotificationScreen from '../screens/FMS/ActionBroadcastNotificationScreen';

// Deliveries
import UnconfirmedDeliveriesScreen from '../screens/Deliveries/UnconfirmedDeliveriesScreen';
import ViewUnconfirmedDeliveriesScreen from '../screens/Deliveries/ViewUnconfirmedDeliveriesScreen';

const ApplicationRoutes = () => {
	return (
		<Routes>
			<Route path='/' element={<Navigate to={'/sign-in'} />} />
			<Route path='/sign-in' element={<LoginScreen />} />
			<Route path='/sign-up' element={<RegistrationScreen />} />
			<Route path='/verify-account' element={<VerificationScreen />} />
			<Route path='/change-password' element={<ForgotPasswordScreen />} />
			<Route path='/verify-password-change' element={<VerifyPasswordChangeScreen />} />
			<Route path='/fms-password-change' element={<FmsPasswordChange />} />
			<Route
				path='/notifications-prompt'
				element={
					<RequireAuth>
						<NotificationsPromptScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/home'
				element={
					<RequireAuth>
						<HomeScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/add-market'
				element={
					<RequireAuth>
						<AddMarketScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/system-status'
				element={
					<RequireAuth>
						<MarketSystemStatusScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/province-directory-info'
				element={
					<RequireAuth>
						<ProvinceDirectoryInfoScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/check-registrations'
				element={
					<RequireAuth>
						<CheckRegistrationScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/app-settings'
				element={
					<RequireAuth>
						<SettingsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/edit-info'
				element={
					<RequireAuth>
						<EditPersonalInfoScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/set-primary-market'
				element={
					<RequireAuth>
						<SetPrimaryMarketScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/notifications-settings'
				element={
					<RequireAuth>
						<NotificationsSettingsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/faq'
				element={
					<RequireAuth>
						<FaqScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/my-devices'
				element={
					<RequireAuth>
						<MyDevicesScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/market-directory'
				element={
					<RequireAuth>
						<MarketDirectoryScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/new-support-ticket'
				element={
					<RequireAuth>
						<NewSupportTicketScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/new-commodity'
				element={
					<RequireAuth>
						<NewCommodityScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/news-feed'
				element={
					<RequireAuth>
						<NewsFeedScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/broadcast-notification'
				element={
					<RequireAuth>
						<BroadcastNotificationScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/track-delivery'
				element={
					<RequireAuth>
						<TrackDeliveriesScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/get-buyer-balance'
				element={
					<RequireAuth>
						<GetBuyerBalanceScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/buyer-balance'
				element={
					<RequireAuth>
						<BuyerBalanceScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/block-buyer-card'
				element={
					<RequireAuth>
						<BlockBuyerCardScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/buyer-account-widgets'
				element={
					<RequireAuth>
						<BuyerAccountWidgetsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/add-fms-notice'
				element={
					<RequireAuth>
						<AddFmsNoticeScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/pending-broadcast-notifications'
				element={
					<RequireAuth>
						<PendingBroadcastNotificationsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/action-broadcast-notification'
				element={
					<RequireAuth>
						<ActionBroadcastNotificationScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/notifications'
				element={
					<RequireAuth>
						<UserNotificationsScreen />
					</RequireAuth>
				}
			/>
			{/* <Route
                path='/app-users'
                element={
                    <RequireAuth>
                        <AppUsersScreen />
                    </RequireAuth>
                }
            /> */}
			<Route
				path='/support-requests'
				element={
					<RequireAuth>
						<SupportRequestsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/new-commodity-requests'
				element={
					<RequireAuth>
						<NewCommodityRequestsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/buyer-enquiry'
				element={
					<RequireAuth>
						<BuyerEnquiryScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/view-buyer-enquiry'
				element={
					<RequireAuth>
						<ViewBuyerEnquiryScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/unpaid-sales'
				element={
					<RequireAuth>
						<BuyerUnpaidSalesScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/view-buyer-unpaid-sales'
				element={
					<RequireAuth>
						<ViewBuyerUnpaidSalesScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/market-stats'
				element={
					<RequireAuth>
						<MarketStatsScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/buyer-statement'
				element={
					<RequireAuth>
						<BuyerStatementScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/your-buyer-statement'
				element={
					<RequireAuth>
						<ViewBuyerStatementScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/unconfirmed-deliveries'
				element={
					<RequireAuth>
						<UnconfirmedDeliveriesScreen />
					</RequireAuth>
				}
			/>
			<Route
				path='/view-unconfirmed-deliveries'
				element={
					<RequireAuth>
						<ViewUnconfirmedDeliveriesScreen />
					</RequireAuth>
				}
			/>
		</Routes>
	);
};

export default ApplicationRoutes;
