import { useState, useEffect } from 'react';

const AuthLogo = () => {
	const [logo, setLogo] = useState('/img/fms_logo.png');

	useEffect(
		() => {
			showValidLogo();
		},
		// eslint-disable-next-line
		[],
	);

	const showValidLogo = () => {
		if (localStorage.getItem('pm') !== '') {
			switch (localStorage.getItem('pm')) {
				case '18':
					setLogo('/img/logos/durban_market_logo.png');
					break;
				case '17':
					setLogo('/img/logos/bcm_market_logo.png');
					break;
				default:
					setLogo('/img/fms_logo.png');
					break;
			}
		}
	};

	return <img className='auth-logo' src={logo} alt='Auth-Logo' />;
};

export default AuthLogo;
