import { useState, useEffect } from 'react';
import './Auth.css';

import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import FormData from 'form-data';

import useAuth from '../../hooks/useAuth';
import AuthLogo from '../../components/AuthLogo';

const VerificationScreen = () => {
	const { state } = useLocation();

	const [verificationCode, setVerificationCode] = useState('');
	const [isCodeValid, setIsCodeValid] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const auth = useAuth();

	const navigate = useNavigate();

	// If user is authed, go straight to home screen
	useEffect(
		() => {
			if (auth) {
				navigate('/home');
			}
		},
		// eslint-disable-next-line
		[],
	);

	const handleInputChange = (event) => {
		setVerificationCode(event.target.value);
	};

	const validateForm = (event) => {
		event.preventDefault();

		setIsButtonDisabled(true);

		if (verificationCode.length < 6) {
			toast.error('Verification code provided is invalid. Please provide correct code, or resend a verification email.');
			setIsCodeValid(false);
			setIsButtonDisabled(false);
		} else {
			verifyAccount();
		}
	};

	const verifyAccount = async () => {
		let postFormData = new FormData();

		postFormData.append('email', state.email);
		postFormData.append('verificationCode', verificationCode);
		postFormData.append('indicator', state.indicator);

		const loadingToast = toast.loading('Verifying account...');

		// ! POSSIBLE RESPONSES: verified, fail
		const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/verify-account', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		switch (responseData.message) {
			case 'verified':
				toast.dismiss(loadingToast);
				toast.success('Verification successful. Redirecting to sign in screen.');
				setTimeout(() => {
					navigate('/sign-in');
				}, 5000);
				break;
			case 'verified fms':
				toast.dismiss(loadingToast);
				toast.success('FMS account verification successful. Redirecting to set password screen. Please set a new password.', { duration: 5000 });
				setTimeout(() => {
					navigate('/fms-password-change', { state: { email: state.email } });
				}, 5000);
				break;
			case 'fail':
				toast.dismiss(loadingToast);
				toast.error('Verification failed. Please try again soon.');
				setIsButtonDisabled(false);
				break;
			default:
				break;
		}
	};

	const resendVerificationCode = async () => {
		let postFormData = new FormData();

		postFormData.append('email', state.email);
		postFormData.append('indicator', state.indicator);

		setIsButtonDisabled(true);
		const loadingToast = toast.loading(`Resending verification code to ${state.email}...`);

		// ! POSSIBLE RESPONSES: success, fail
		const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/resend-code', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		switch (responseData.message) {
			case 'success':
				toast.dismiss(loadingToast);
				toast.success(`Verification code successfully resent to ${state.email}. Please enter the code below.`);
				setIsButtonDisabled(false);
				break;
			case 'fail':
				toast.dismiss(loadingToast);
				toast.error('Resending of verification code cannot be completed at this time. Please try again soon, or contact support for help.');
				setIsButtonDisabled(false);
				break;
			default:
				break;
		}
	};

	return (
		<div className='verification-form-container'>
			<form method='post' className='verification-form'>
				<AuthLogo />
				<div className='info-text-container'>
					<p className='verification-form-text'>
						Thank you for registering for the Market App. A 6 digit verification code has been sent to <strong>{state.email}</strong>. Please
						provide that code below to verify your account.
					</p>
				</div>
				<div className='form-control'>
					<label className='form-control-label'>Verification Code</label>
					<input type='text' className='form-input' onChange={handleInputChange} />
					{!isCodeValid && <p className='form-input-error'>Invalid code.</p>}
				</div>
				<input
					type='submit'
					value='Verify Account'
					className='btn btn-primary-solid'
					onClick={(event) => validateForm(event)}
					disabled={isButtonDisabled}
				/>
				<input type='button' value='Resend Code' className='btn btn-primary-solid mt-sm' onClick={resendVerificationCode} disabled={isButtonDisabled} />
			</form>
		</div>
	);
};

export default VerificationScreen;
