import { useState, useEffect, useRef } from 'react';
import './Auth.css';

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import useAuth from '../../hooks/useAuth';
import AuthLogo from '../../components/AuthLogo';

const RegistrationScreen = () => {
	const uuid = uuidv4();

	const [formData, setFormData] = useState({
		fullName: '',
		email: '',
		cellNumber: '',
		idSelection: '',
		rsaIdNumber: '',
		passportNumber: '',
		dobYear: '',
		dobMonth: '',
		dobDay: '',
		password: '',
		confirmPassword: '',
		uuid: uuid,
		isNameValid: true,
		isEmailValid: true,
		isCellNumberValid: true,
		isIdSelectionValid: true,
		isIdNumberValid: true,
		isPassportNumberValid: true,
		isDateOfBirthValid: true,
		isPasswordValid: true,
		isConfirmPasswordValid: true,
		isFormValid: true,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [selectedItem, setSelectedItem] = useState('');
	const [isIdDropdownOpen, setIsIdDropdownOpen] = useState(false);

	const auth = useAuth();

	// navigation with params
	const navigate = useNavigate();

	const ref = useRef();

	// If user is authed, go straight to home screen
	useEffect(
		() => {
			if (auth) {
				navigate('/home');
			} else {
				const checkIfClickedOutside = (event) => {
					if (isIdDropdownOpen && ref.current && !ref.current.contains(event.target)) {
						setIsIdDropdownOpen(false);
					}
				};

				document.addEventListener('mousedown', checkIfClickedOutside);

				return () => {
					document.removeEventListener('mousedown', checkIfClickedOutside);
				};
			}
		},
		// eslint-disable-next-line
		[isIdDropdownOpen],
	);

	const handleInputChange = (event, indicator) => {
		if (indicator === 'name') {
			setFormData({ ...formData, fullName: event.target.value });
		} else if (indicator === 'email') {
			setFormData({ ...formData, email: event.target.value });
		} else if (indicator === 'password') {
			setFormData({ ...formData, password: event.target.value });
		} else if (indicator === 'cell') {
			setFormData({ ...formData, cellNumber: event.target.value });
		} else if (indicator === 'id') {
			setFormData({ ...formData, rsaIdNumber: event.target.value });
		} else if (indicator === 'passport') {
			setFormData({ ...formData, passportNumber: event.target.value });
		} else if (indicator === 'year') {
			setFormData({ ...formData, dobYear: event.target.value });
		} else if (indicator === 'month') {
			setFormData({ ...formData, dobMonth: event.target.value });
		} else if (indicator === 'day') {
			setFormData({ ...formData, dobDay: event.target.value });
		} else {
			setFormData({ ...formData, confirmPassword: event.target.value });
		}
	};

	const validateForm = (event) => {
		event.preventDefault();

		setIsButtonDisabled(true);

		let validForm = true;
		let validName = true;
		let validEmail = true;
		let validCellNumber = true;
		let validIdSelection = true;
		let validRsaIdNumber = true;
		let validPassportNumber = true;
		let validDob = true;
		let validPassword = true;
		let validConfirmPassword = true;

		// Validate user name
		if (formData.fullName === '') {
			validName = false;
			validForm = false;
		}

		// Validate email
		if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
			validEmail = false;
			validForm = false;
		}

		// Validate cell number
		if (formData.cellNumber.length < 10) {
			validCellNumber = false;
			validForm = false;
		}

		if (!/^\d+$/.test(formData.cellNumber)) {
			validCellNumber = false;
			validForm = false;
		}

		// Validate id selection
		if (formData.idSelection === '') {
			validIdSelection = false;
			validForm = false;
		}

		// Validate ID number
		if (formData.idSelection === 'RSAID') {
			// ID NUMBER
			if (formData.rsaIdNumber.length < 13 || !/^\d+$/.test(formData.rsaIdNumber)) {
				validRsaIdNumber = false;
				validForm = false;
			} else {
				const validId = validateRsaIdNumber();

				if (!validId) {
					validRsaIdNumber = false;
					validForm = false;
				}
			}
		} else if (formData.idSelection === 'PASSPORT') {
			// PASSPORT NUMBER
			if (formData.passportNumber.length === 0) {
				validPassportNumber = false;
				validForm = false;
			}

			// DOB
			if (formData.dobYear.length < 4 || !/^\d+$/.test(formData.dobYear)) {
				validDob = false;
				validForm = false;
			}

			if (!/^\d+$/.test(formData.dobMonth)) {
				validDob = false;
				validForm = false;
			}

			if (!/^\d+$/.test(formData.dobDay)) {
				validDob = false;
				validForm = false;
			}
		}

		// Validate password
		if (formData.password.length < 8) {
			validPassword = false;
			validForm = false;
		}

		// Validate confirm password
		if (formData.password.length > 0 && formData.password !== formData.confirmPassword) {
			validConfirmPassword = false;
			validForm = false;
		}

		setFormData({
			...formData,
			isFormValid: validForm,
			isNameValid: validName,
			isEmailValid: validEmail,
			isCellNumberValid: validCellNumber,
			isIdSelectionValid: validIdSelection,
			isIdNumberValid: validRsaIdNumber,
			isPassportNumberValid: validPassportNumber,
			isDateOfBirthValid: validDob,
			isPasswordValid: validPassword,
			isConfirmPasswordValid: validConfirmPassword,
		});

		if (!validForm) {
			// form is invalid
			// show toast
			toast.error('One or more form inputs are invalid. Please check your inputs carefully.');
			setIsButtonDisabled(false);
		} else {
			// input is valid, send registration request
			sendRegistrationRequest();
		}
	};

	const validateRsaIdNumber = () => {
		// ! valid id number algorithm
		let tempTotal = 0;
		let checkSum = 0;
		let multiplier = 1;

		for (let i = 0; i < 13; ++i) {
			tempTotal = parseInt(formData.rsaIdNumber.charAt(i)) * multiplier;

			if (tempTotal > 9) {
				tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
			}

			checkSum = checkSum + tempTotal;

			multiplier = multiplier % 2 === 0 ? 1 : 2;
		}

		if (checkSum % 10 !== 0) {
			return false;
		}

		return true;
	};

	const sendRegistrationRequest = async () => {
		let postFormData = new FormData();

		postFormData.append('fullName', formData.fullName);
		postFormData.append('email', formData.email);
		postFormData.append('cellNumber', formData.cellNumber);

		// Check id type
		if (formData.idSelection === 'RSAID') {
			postFormData.append('idNumber', formData.rsaIdNumber);
			postFormData.append('passportNumber', '');
		} else if (formData.idSelection === 'PASSPORT') {
			postFormData.append('passportNumber', formData.passportNumber);
			postFormData.append('idNumber', '');

			let month = formData.dobMonth;
			let day = formData.dobDay;

			// Check month and day
			if (month.length === 1) {
				month = '0' + month;
			}

			if (day.length === 1) {
				day = '0' + day;
			}

			// DOB
			const dateOfBirth = formData.dobYear + '-' + month + '-' + day;

			postFormData.append('dateOfBirth', dateOfBirth);
		}

		postFormData.append('password', formData.password);
		postFormData.append('uuid', formData.uuid);

		const loadingToast = toast.loading('Sending registration request...');

		// ! POSSIBLE RESPONSES: success, exists, mail fail, registration fail
		const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/register-user', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		switch (responseData.message) {
			case 'success':
				// Registration successful, show toast, and navigate to verification screen with name and email as parameters
				toast.dismiss(loadingToast);
				toast.success('Registration successful. Redirecting to verification screen.');

				setTimeout(() => {
					navigate('/verify-account', { state: { email: formData.email } });
				}, 5000);
				break;
			case 'exists':
				// Account exists and has been verified, show toast, navigate to sign in screen
				toast.dismiss(loadingToast);
				toast.success('Account already exists. Redirecting to sign in screen.');
				setTimeout(() => {
					navigate('/sign-in');
				}, 5000);
				break;
			case 'mail fail':
				// Verification email failed to send, show toast
				toast.dismiss(loadingToast);
				toast.error('Verification email cannot be sent at this time. Please try again soon.');
				setIsButtonDisabled(false);
				break;
			case 'registration fail':
				// Registration failed, show toast
				toast.dismiss(loadingToast);
				toast.error('Registration cannot be completed at this time. Please try again soon, or contact support for help.');
				setIsButtonDisabled(false);
				break;
			case 'fms':
				// FMS user trying to register with there fms email address
				toast.dismiss(loadingToast);
				toast.error(
					'FMS users do not need to register for the app. Please talk to Matt or Richard about how to sign in to the app using your Freshmark email address.',
				);
				setTimeout(() => {
					navigate('/sign-in', { state: { email: formData.email } });
				}, 10000);
				break;
			default:
				break;
		}
	};

	const toggleDropdown = () => {
		setIsIdDropdownOpen(!isIdDropdownOpen);
	};

	const handleDropdownChange = (event) => {
		setFormData({ ...formData, idSelection: event.target.id });

		if (event.target.id === 'RSAID') {
			setSelectedItem('RSA ID Number');
		} else if (event.target.id === 'PASSPORT') {
			setSelectedItem('Passport Number');
		}

		setIsIdDropdownOpen(!isIdDropdownOpen);
	};

	return (
		<div className='registration-form-container'>
			<form method='post' className='registration-form'>
				<AuthLogo />
				<h1 className='form-header'>Sign Up</h1>
				<div className='form-control'>
					<label className='form-control-label'>Full Name</label>
					<input
						type='text'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'name');
						}}
					/>
					{!formData.isNameValid && <p className='form-input-error'>Invalid name.</p>}
				</div>
				<div className='form-control'>
					<label className='form-control-label'>Email</label>
					<input
						type='email'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'email');
						}}
					/>
					{!formData.isEmailValid && <p className='form-input-error'>Invalid email.</p>}
				</div>
				<div className='form-control'>
					<label className='form-control-label'>Cell Number</label>
					<input
						type='text'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'cell');
						}}
					/>
					{!formData.isCellNumberValid && <p className='form-input-error'>Invalid cell number.</p>}
				</div>
				<div className='auth-dropdown mt-lg' ref={ref}>
					<div className={`auth-dropdown-title ${isIdDropdownOpen && `open`}`} onClick={toggleDropdown}>
						{selectedItem === '' ? 'Select Identification Type' : selectedItem}
						<i className={`fa fa-chevron-down auth-dropdown-icon ${isIdDropdownOpen && 'open'}`}></i>
					</div>
					{isIdDropdownOpen && (
						<div className='auth-dropdown-body'>
							<ul className='auth-dropdown-items'>
								<li className='auth-dropdown-item' onClick={(event) => handleDropdownChange(event)} id='RSAID'>
									RSA ID Number
								</li>
								<li className='auth-dropdown-item' onClick={(event) => handleDropdownChange(event)} id='PASSPORT'>
									Passport Number
								</li>
							</ul>
						</div>
					)}
					{!formData.isIdSelectionValid && <p className='form-input-error selection-error'>Invalid selection.</p>}
				</div>
				{selectedItem === 'RSA ID Number' && (
					<div className='form-control'>
						<label className='form-control-label'>RSA ID Number</label>
						<input
							type='text'
							className='form-input'
							onChange={(event) => {
								handleInputChange(event, 'id');
							}}
						/>
						{!formData.isIdNumberValid && <p className='form-input-error'>Invalid ID number.</p>}
					</div>
				)}
				{selectedItem === 'Passport Number' && (
					<>
						<div className='form-control'>
							<label className='form-control-label'>Passport Number</label>
							<input
								type='text'
								className='form-input'
								onChange={(event) => {
									handleInputChange(event, 'passport');
								}}
							/>
							{!formData.isPassportNumberValid && <p className='form-input-error'>Invalid passport number.</p>}
						</div>
						<div className='form-control'>
							<label className='form-control-label'>Date of Birth</label>
							<div className='dob-inputs'>
								<input
									type='text'
									className='form-input dob-year'
									placeholder='YYYY'
									onChange={(event) => {
										handleInputChange(event, 'year');
									}}
								/>
								<input
									type='text'
									className='form-input dob-month'
									placeholder='MM'
									onChange={(event) => {
										handleInputChange(event, 'month');
									}}
								/>
								<input
									type='text'
									className='form-input dob-day'
									placeholder='DD'
									onChange={(event) => {
										handleInputChange(event, 'day');
									}}
								/>
							</div>
							{!formData.isDateOfBirthValid && <p className='form-input-error'>Invalid date of birth.</p>}
						</div>
					</>
				)}
				<div className='form-control'>
					<label className='form-control-label'>
						Password <span>(minimum 8 characters)</span>
					</label>
					<input
						type='password'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'password');
						}}
					/>
					{!formData.isPasswordValid && <p className='form-input-error'>Invalid password.</p>}
				</div>
				<div className='form-control'>
					<label className='form-control-label'>Confirm Password</label>
					<input
						type='password'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'confirm-password');
						}}
					/>
					{!formData.isConfirmPasswordValid && <p className='form-input-error'>Passwords don't match.</p>}
				</div>
				<input type='submit' value='Sign Up' className='btn btn-primary-solid' onClick={(event) => validateForm(event)} disabled={isButtonDisabled} />
				<div className='form-actions'>
					<p className='form-actions-text'>Have an account?</p>
					<a href='/sign-in' className='form-nav-link'>
						Sign In
					</a>
				</div>
			</form>
		</div>
	);
};

export default RegistrationScreen;
