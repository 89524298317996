import { useLocation } from 'react-router-dom';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';

const ViewBuyerStatementScreen = () => {
	const { state } = useLocation();

	return (
		<>
			<NavigationBack />
			<ScreenHeading heading='Your Buyer Statement' />
			<div className='buyer-statement-container'>{state.statementText}</div>
		</>
	);
};

export default ViewBuyerStatementScreen;
