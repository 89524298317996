import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';

const ViewBuyerEnquiryScreen = () => {
	const { state } = useLocation();

	const [balance, setBalance] = useState('');
	const [isMainBuyer, setIsMainBuyer] = useState(false);

	useEffect(
		() => {
			if (state.enquiry.mainBuyer === null) {
				setIsMainBuyer(true);
				const balanceCalc = (state.enquiry.DM_CRLIMIT * state.enquiry.DM_CRLIM_PERC) / 100 - state.enquiry.DM_OSBAL;
				setBalance(balanceCalc);
			} else {
				setBalance(state.enquiry.mainBalance);
			}
		},
		// eslint-disable-next-line
		[],
	);

	// DM_MAIN_DEBTOR

	return (
		<>
			<NavigationBack />
			<ScreenHeading heading='Buyer Enquiry Data' />
			<div className='buyer-enquiry-data-screen'>
				<div className='buyer-enquiry-data-container'>
					<div className='buyer-enquiry-info-container'>
						{!isMainBuyer && (
							<div className='buyer-enquiry-data-row'>
								<div className='buyer-enquiry-label'>Main Buyer</div>
								<div className='buyer-enquiry-info'>{state.enquiry.DM_MAIN_DEBTOR}</div>
							</div>
						)}
						<div className='buyer-enquiry-data-row'>
							<div className='buyer-enquiry-label'>Buyer Name</div>
							<div className='buyer-enquiry-info'>{state.enquiry.DM_NAME}</div>
						</div>
						<div className='buyer-enquiry-data-row'>
							<div className='buyer-enquiry-label'>Buyer Number</div>
							<div className='buyer-enquiry-info'>{state.enquiry.DM_DEBTOR}</div>
						</div>
						<div className='buyer-enquiry-data-row'>
							<div className='buyer-enquiry-label'>Buyer Contact</div>
							<div className='buyer-enquiry-info'>{state.enquiry.DM_CONTACT}</div>
						</div>
						<div className='buyer-enquiry-data-row'>
							<div className='buyer-enquiry-label'>Buyer Vat Number</div>
							<div className='buyer-enquiry-info'>{state.enquiry.DM_VATNO}</div>
						</div>
					</div>
					<div className='buyer-enquiry-info-container buyer-enquiry-balance-container'>
						<div className='buyer-enquiry-label'>Buyer Balance</div>
						<div className='buyer-enquiry-info'>{'R ' + balance}</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ViewBuyerEnquiryScreen;
