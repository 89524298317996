import { useState, useEffect } from 'react';
import './Auth.css';

import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import FormData from 'form-data';

import useAuth from '../../hooks/useAuth';
import AuthLogo from '../../components/AuthLogo';

// ! REMOVE USER DATA FROM LOCAL STORAGE
// ! PASS USER'S EMAIL ADDRESS VIA ROUTER RATHER
// ! ADD EXTRA CHECK TO SIGN IN - IF VCODE IS PRESENT IN BOTH USERS AND TEMP TABLE, HANDLE ACCORDINGLY

const VerifyPasswordChangeScreen = () => {
	const { state } = useLocation();

	const [verificationCode, setVerificationCode] = useState('');
	const [isCodeValid, setIsCodeValid] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const auth = useAuth();

	const navigate = useNavigate();

	// If user is authed, go straight to home screen
	useEffect(
		() => {
			if (auth) {
				navigate('/home');
			}
		},
		// eslint-disable-next-line
		[],
	);

	const handleInputChange = (event) => {
		setVerificationCode(event.target.value);
	};

	const validateForm = (event) => {
		event.preventDefault();

		setIsButtonDisabled(true);

		if (verificationCode.length < 6) {
			toast.error('Verification code provided is invalid. Please provide correct code, or resend a verification email.');
			setIsCodeValid(false);
			setIsButtonDisabled(false);
		} else {
			verifyAccount();
		}
	};

	const verifyAccount = async () => {
		let postFormData = new FormData();

		postFormData.append('email', state.email);
		postFormData.append('verificationCode', verificationCode);

		const loadingToast = toast.loading('Updating password...');

		// ! POSSIBLE RESPONSES: success, fail
		const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/change-password', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		switch (responseData.message) {
			case 'success':
				toast.dismiss(loadingToast);
				toast.success('Password successfully changed. Redirecting to sign in screen.');
				setTimeout(() => {
					navigate('/sign-in');
				}, 5000);
				break;
			case 'fail':
				toast.dismiss(loadingToast);
				toast.error('No record of request for password change found. Please resend your request. Redirecting to forgot password screen.');
				setTimeout(() => {
					navigate('/change-password');
				}, 5000);
				break;
			default:
				break;
		}
	};

	const resendPasswordVerificationCode = async () => {
		// Resend the code to the provided email
		const postArray = [{ email: state.email }];

		setIsButtonDisabled(true);
		const loadingToast = toast.loading(`Resending verification code to ${state.email}...`);

		// ! POSSIBLE RESPONSES: success, fail
		const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/resend-password-code', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(postArray),
		});

		const responseData = await response.json();

		switch (responseData.message) {
			case 'success':
				toast.dismiss(loadingToast);
				toast.success(`Verification code successfully resent to ${state.email}. Please enter the code below.`);
				setIsButtonDisabled(false);
				break;
			case 'fail':
				toast.dismiss(loadingToast);
				toast.error('Resending of verification code cannot be completed at this time. Please try again soon, or contact support for help.');
				setIsButtonDisabled(false);
				break;
			default:
				break;
		}
	};

	return (
		<div className='verification-form-container'>
			<form method='post' className='verification-form'>
				<AuthLogo />
				<div className='info-text-container'>
					<p className='verification-form-text'>Please verify your request for password change by providing the 6 digit verification code below.</p>
				</div>
				<div className='form-control'>
					<label className='form-control-label'>Verification Code</label>
					<input type='text' className='form-input' onChange={handleInputChange} />
					{!isCodeValid && <p className='form-input-error'>Invalid code.</p>}
				</div>
				<input
					type='submit'
					value='Verify Password Change'
					className='btn btn-primary-solid'
					onClick={(event) => validateForm(event)}
					disabled={isButtonDisabled}
				/>
				<input
					type='button'
					value='Resend Code'
					className='btn btn-primary-solid mt-sm'
					onClick={resendPasswordVerificationCode}
					disabled={isButtonDisabled}
				/>
			</form>
		</div>
	);
};

export default VerifyPasswordChangeScreen;
