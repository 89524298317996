import { useState, useEffect } from 'react';

import NavigationMenu from '../../components/NavigationMenu';
import CustomLoader from '../../components/CustomLoader';
import ScreenHeading from '../../components/ScreenHeading';

import useJwt from '../../hooks/useJwt';

const CheckRegistrationScreen = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [pendingData, setPendingData] = useState([]);
	const [pending, setPending] = useState(true);
	const jwt = useJwt();

	useEffect(
		() => {
			const fetchPendingRegistrations = async () => {
				let postFormData = new FormData();

				postFormData.append('device', jwt.data.id);
				postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
				postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

				const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/check-registration', {
					method: 'POST',
					body: postFormData,
				});

				const responseData = await response.json();

				if (responseData.data.length > 0) {
					// this means we have data
					setIsLoading(false);

					setPendingData(responseData.data);
					// console.log(responseData.data);
					responseData.data.forEach((rd) => {
						setPending(true);
						if (rd.pending.length === 0) {
							setPending(false);
						}
					});
				}
			};

			fetchPendingRegistrations();
		},
		// eslint-disable-next-line
		[],
	);

	return (
		<>
			{isLoading && <CustomLoader text='Fetching pending registrations...' />}
			<NavigationMenu />
			<ScreenHeading heading='Pending Registrations' />
			<div className='pending-container'>
				{!pending && <div className='no-pending-data'>No Pending Registrations</div>}
				{pendingData.map((pr, index) => {
					if (pr.pending.length > 0) {
						if (pr.pending[index] === '') {
							return (
								<div className='pending-record' key={index}>
									<div className='pending-market-description'>{pr.marketDescription}</div>
									<div className='pending-status'>PENDING</div>
								</div>
							);
						} else {
							return (
								<div className='pending-record-ref' key={index}>
									<div className='pending-market-description-ref'>{pr.marketDescription}</div>
									{pr.pending.map((pendingRecord, index) => {
										return (
											<div className='pending-reference-numbers mb-md' key={index}>
												<div className='pending-reference-number'>{pendingRecord}</div>
												<div className='pending-status'>PENDING</div>
											</div>
										);
									})}
								</div>
							);
						}
					}

					return <div key={index}></div>;
				})}
			</div>
		</>
	);
};

export default CheckRegistrationScreen;
