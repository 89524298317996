const CustomLoader = ({ text }) => {
	return (
		<div className='loader'>
			<div className='loader-container'>
				<img className='loader-gif' src='/img/loading.gif' alt='Loader-Gif' />
				<p className='loader-text'>{text}</p>
			</div>
		</div>
	);
};

export default CustomLoader;
