import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';
import BuyerMarketDropdown from '../../components/BuyerMarketDropdown';
import BuyerNumberDropdown from '../../components/BuyerNumberDropdown';
import CustomLoader from '../../components/CustomLoader';

import useJwt from '../../hooks/useJwt';
import { useNavigate } from 'react-router-dom';

const GetBuyerBalanceScreen = () => {
	const [markets, setMarkets] = useState([]);
	const [buyerNumbers, setBuyerNumbers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [selectedMarket, setSelectedMarket] = useState('');
	const [selectedBuyer, setSelectedBuyer] = useState('');
	const [isMarketDropdownOpen, setIsMarketDropdownOpen] = useState(false);
	const [isBuyerNumberDropdownOpen, setIsBuyerNumberDropdownOpen] = useState(false);

	const jwt = useJwt();
	const navigate = useNavigate();

	useEffect(
		() => {
			getBuyerMarkets();
		},
		// eslint-disable-next-line
		[],
	);

	const getBuyerMarkets = async () => {
		setIsLoading(true);
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/get-buyer-markets', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setMarkets(responseData.markets);

			// if (responseData.markets.length === 1) {
			//     setOnlyOneMarket(true);
			// }

			setIsLoading(false);
		} else {
			setIsLoading(false);
			toast.error('Unable to fetch buyer markets at this time. Please try again.');
		}
	};

	const getBuyerNumbersForMarket = async (marketCode) => {
		const loadingToast = toast.loading('Fetching buyer numbers...');
		setIsButtonDisabled(true);

		// Reset buyer array
		// setBuyer([]);

		let postFormData = new FormData();

		postFormData.append('device', jwt.data.id);
		postFormData.append('query', 'registered-buyers');
		postFormData.append('market', marketCode);
		postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
		postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

		// Send request to app backend
		const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/market-query', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		const buyers = [];

		if (responseData.success) {
			// iterate through responseData.result.buyers and add buyer_number
			responseData.result.buyers.forEach((buyer) => {
				const buyerObject = {
					buyerNumber: buyer.buyer_number,
					buyerName: buyer.buyer_name,
				};

				buyers.push(buyerObject);
			});

			setBuyerNumbers(buyers);

			setIsButtonDisabled(false);

			toast.dismiss(loadingToast);
		} else {
			setIsButtonDisabled(false);
			toast.error('Unable to fetch buyer numbers for selected market.');
		}
	};

	const getMarketName = (selectedMarket) => {
		const mappedMarket = markets.filter((market) => {
			return market.market_code === selectedMarket;
		});

		return mappedMarket[0].market_name;
	};

	const validateForm = () => {
		let validForm = true;

		if (selectedBuyer === '') {
			toast.error('Buyer number not selected.');
			validForm = false;
		}

		if (selectedMarket === '') {
			toast.error('Market not selected.');
			validForm = false;
		}

		if (validForm) {
			getBuyerBalance();
		}
	};

	const getBuyerBalance = async () => {
		const loadingToast = toast.loading('Fetching buyer balance details...');
		setIsButtonDisabled(true);

		let postFormData = new FormData();

		postFormData.append('query', 'buyer-account');
		postFormData.append('device', jwt.data.id);
		postFormData.append('market', selectedMarket);
		postFormData.append('buyer_number', selectedBuyer);
		postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
		postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);

		// Send request to WS
		const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/market-query', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.success) {
			if (responseData.result.balance === '?') {
				toast.dismiss(loadingToast);
				toast.error(responseData.result.message);
				setIsButtonDisabled(false);

				return;
			}

			toast.dismiss(loadingToast);
			// navigate to balance screen
			navigate('/buyer-balance', {
				state: { result: responseData.result, buyerNumber: selectedBuyer, market: getMarketName(selectedMarket), marketCode: selectedMarket },
			});
		} else {
			// ! FIND OUT ABOUT THIS
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching buyer information...' />}
			<NavigationBack />
			<ScreenHeading heading='Buyer Balance Enquiry' />
			<div className='buyer-balance-screen'>
				<div className='buyer-balance-container'>
					<BuyerMarketDropdown
						markets={markets}
						setSelectedMarket={setSelectedMarket}
						isMarketDropdownOpen={isMarketDropdownOpen}
						setIsMarketDropdownOpen={setIsMarketDropdownOpen}
						clickFunction={getBuyerNumbersForMarket}
					/>
					{buyerNumbers.length > 0 && (
						<BuyerNumberDropdown
							buyers={buyerNumbers}
							setSelectedBuyer={setSelectedBuyer}
							isBuyerNumberDropdownOpen={isBuyerNumberDropdownOpen}
							setIsBuyerNumberDropdownOpen={setIsBuyerNumberDropdownOpen}
						/>
					)}
					<input type='button' className='btn btn-primary-solid' value='Get Buyer Balance' disabled={isButtonDisabled} onClick={validateForm} />
				</div>
			</div>
		</>
	);
};

export default GetBuyerBalanceScreen;
