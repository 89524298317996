import './Info.css';

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FormData from 'form-data';
import useJwt from '../../hooks/useJwt';
import toast from 'react-hot-toast';
// import { animateScroll as scroll } from 'react-scroll/modules';

import ScreenHeading from '../../components/ScreenHeading';
import NavigationBack from '../../components/NavigationBack';
import CustomLoader from '../../components/CustomLoader';

// Province maps
// import EasternCapeMap from '../../components/maps/EasternCapeMap';
// import KwaZuluNatalMap from '../../components/maps/KwaZuluNatalMap';
// import LimpopoMap from '../../components/maps/LimpopoMap';
// import GautengMap from '../../components/maps/GautengMap';
// import FreestateMap from '../../components/maps/FreestateMap';
// import MpumalangaMap from '../../components/maps/MpumalangaMap';
// import NorthWestMap from '../../components/maps/NorthWestMap';
// import NorthernCapeMap from '../../components/maps/NorthernCapeMap';
// import WesternCapeMap from '../../components/maps/WesternCapeMap';

const ProvinceDirectoryInfoScreen = (props) => {
	const { state } = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [marketDirectoryDetails, setMarketDirectoryDetails] = useState([]);
	// const [marketLocationPin, setMarketLocationPin] = useState(<div></div>);
	const jwt = useJwt();

	useEffect(
		() => {
			const getMarketDirectoryDetails = async () => {
				let postFormData = new FormData();

				postFormData.append('province', state.provinceCode);
				postFormData.append('uuid', jwt.data.id);

				const response = await fetch('https://staffapi.freshmarksystems.co.za/market/get-market-directory-details', {
					method: 'POST',
					body: postFormData,
				});

				const responseData = await response.json();

				if (responseData.message === 'success') {
					setMarketDirectoryDetails(responseData.data);
				} else {
					toast.error('Cannot retrieve market directory details. Please try again.');
				}

				setIsLoading(false);
			};

			getMarketDirectoryDetails();
		},
		// eslint-disable-next-line
		[],
	);

	// const getProvinceMap = () => {
	// 	let relevantProvinceMap;

	// 	switch (state.provinceCode) {
	// 		case 'KZN':
	// 			relevantProvinceMap = <KwaZuluNatalMap />;
	// 			break;
	// 		case 'EC':
	// 			relevantProvinceMap = <EasternCapeMap />;
	// 			break;
	// 		case 'LP':
	// 			relevantProvinceMap = <LimpopoMap />;
	// 			break;
	// 		case 'GP':
	// 			relevantProvinceMap = <GautengMap />;
	// 			break;
	// 		case 'FS':
	// 			relevantProvinceMap = <FreestateMap />;
	// 			break;
	// 		case 'MP':
	// 			relevantProvinceMap = <MpumalangaMap />;
	// 			break;
	// 		case 'NW':
	// 			relevantProvinceMap = <NorthWestMap />;
	// 			break;
	// 		case 'NC':
	// 			relevantProvinceMap = <NorthernCapeMap />;
	// 			break;
	// 		case 'WC':
	// 			relevantProvinceMap = <WesternCapeMap />;
	// 			break;
	// 		default:
	// 			break;
	// 	}

	// 	return relevantProvinceMap;
	// };

	// get the location pins of the markets
	// const getMarketLocations = (event) => {
	// 	scroll.scrollToTop();

	// 	// switch market codes
	// 	switch (event.target.id) {
	// 		case '18':
	// 			setMarketLocationPin(<img className='durban-market-location' src='/img/map_pin_icon.png' alt='Map-Pin-Icon' />);
	// 			break;
	// 		case '19':
	// 			setMarketLocationPin(<img className='pietermaritzburg-market-location' src='/img/map_pin_icon.png' alt='Map-Pin-Icon' />);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// };

	return (
		<>
			{isLoading && <CustomLoader text='Fetching directory details...' />}
			<NavigationBack />
			<ScreenHeading heading={state.provinceName} />
			<div className='province-container'>
				{/* <div className='province-info-container'>
					{getProvinceMap()}
					{marketLocationPin}
					<div className='dbn-market-location-container'>
						<div className='dbn-market-location-info'>
							<p>Market Name</p>

						</div>
						<img className='durban-market-location' src='/img/map_pin_icon.png' alt='Map-Pin-Icon' />
					</div>
				</div> */}
				<div className='market-directory-details'>
					{marketDirectoryDetails === 'empty' && <div className='no-market-info'>No market info available for province.</div>}
					{marketDirectoryDetails !== 'empty' &&
						marketDirectoryDetails.map((market, index) => {
							return (
								<div key={index} className='market-details'>
									<div className='market-header'>
										<h3 className='market-details-description'>{market.market_description}</h3>
										{market.latitude !== '' && (
											<input
												type='button'
												className='btn btn-xs btn-primary-solid location-button'
												value='Navigate'
												id={market.market_code}
												onClick={() => {
													window.open('https://maps.google.com?q=' + market.latitude + ',' + market.longitude);
												}}
											/>
										)}
									</div>
									<div className='market-address-info'>
										<p className='street-address'>{market.street_address}</p>
										<p className='suburb'>{market.suburb}</p>
										<p className='city'>{market.city}</p>
										<p className='postal-code'>{market.postal_code}</p>
									</div>
									<div className='market-contact-info'>
										<div className='contact-container'>
											<div className='contact-label'>Contact Person</div>
											<div className='contact-person'>{market.contact_person}</div>
										</div>
										<div className='contact-container'>
											<div className='contact-label'>Email Address</div>
											<div className='email-address'>
												<a className='contact-link' href={`mailto:${market.email_address}`}>
													{market.email_address}
												</a>
											</div>
										</div>
										{market.tel_number !== '' && (
											<div className='contact-container'>
												<div className='contact-label'>Tel Number</div>
												<div className='tel-number'>
													<a className='contact-link' href={`tel:${market.tel_number}`}>
														{market.tel_number}
													</a>
												</div>
											</div>
										)}
										{market.cell_number !== '' && (
											<div className='contact-container'>
												<div className='contact-label'>Cell Number</div>
												<div className='cell-number'>
													<a className='contact-link' href={`tel:${market.cell_number}`}>
														{market.cell_number}
													</a>
												</div>
											</div>
										)}
										{market.website !== '' && (
											<div className='contact-container'>
												<div className='contact-label'>Website</div>
												<div className='website'>
													<a className='contact-link' href={market.website} target='_blank' rel='noreferrer'>
														Open Website
													</a>
												</div>
											</div>
										)}
									</div>
								</div>
							);
						})}
				</div>
			</div>
			{/* <BackButtonListener /> */}
		</>
	);
};

export default ProvinceDirectoryInfoScreen;
