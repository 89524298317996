import { useState, useEffect } from 'react';

import NavigationMenu from '../../components/NavigationMenu';
import ScreenHeading from '../../components/ScreenHeading';
import CustomLoader from '../../components/CustomLoader';

import useJwt from '../../hooks/useJwt';

const UserNotificationsScreen = () => {
	const [notifications, setNotifications] = useState([]);
	const [noNotifications, setNoNotifications] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const jwt = useJwt();

	useEffect(
		() => {
			localStorage.removeItem('notRoute');
			// get notifications from backend
			getNotificationsFromBackend();
		},
		// eslint-disable-next-line
		[],
	);

	const getNotificationsFromBackend = async () => {
		setIsLoading(true);
		// check for pending notifications, show a widget if there are
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/get-user-notifications', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			if (responseData.notifications.length === 0) {
				setIsLoading(false);
				setNoNotifications(true);
			} else {
				setIsLoading(false);
				setNotifications(responseData.notifications);
			}
		} else {
			// show error
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching notifications...' />}
			<NavigationMenu />
			<ScreenHeading heading='Notifications' />
			<div className='user-notifications-screen'>
				<div className='user-notifications-container'>
					{noNotifications && <div className='no-pending-notifications-container'>No notifications to display.</div>}
					{notifications.map((notification, index) => {
						return (
							<div className='user-notification' key={index}>
								{index === 0 && <p className='new-notification'>LATEST</p>}
								<div className='user-notification-header'>
									<p className='user-notification-author'>{notification.author_name}</p>
									<p className='user-notification-date'>{notification.date_actioned}</p>
								</div>
								<p className='user-notification-market'>{notification.market_description}</p>
								<div className='user-notification-title-container'>
									<p className='user-notification-label'>Notification Title</p>
									<p className='user-notification-title'>{notification.notification_title}</p>
								</div>
								<div className='user-notification-body-container'>
									<p className='user-notification-label'>Notification Body</p>
									<p className='user-notification-title'>{notification.notification_body}</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default UserNotificationsScreen;
