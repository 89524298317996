import '../app/App.css';

const MessageOverlay = ({ text, acceptClick, cancelClick, overlayState }) => {
	return (
		<div className={'overlay ' + overlayState}>
			<div className='overlay-content'>
				<p className='overlay-content-text'>{text}</p>
				<div className='overlay-actions mt-sm'>
					<input type='button' value='Yes' className='btn-sm btn-primary-solid' onClick={acceptClick} />
					<input type='button' value='Close' className='btn-sm btn-danger-solid ml-sm' onClick={cancelClick} />
				</div>
			</div>
		</div>
	);
};

export default MessageOverlay;
