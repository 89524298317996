import React from 'react';

const EditDeviceModal = ({ value, onChange, overlayState, acceptClick, cancelClick, isNameValid, isButtonDisabled }) => {
	return (
		<div className={'overlay ' + overlayState}>
			<div className='overlay-content'>
				<div className='form-control'>
					<label className='form-control-label'>Device Name</label>
					<input type='text' value={value} className='form-input' onChange={onChange} />
					{!isNameValid && <p className='form-input-error'>Invalid device name.</p>}
				</div>

				<div className='overlay-actions mt-sm'>
					<input type='button' value='Submit' className='btn-sm btn-primary-solid' onClick={acceptClick} disabled={isButtonDisabled} />
					<input type='button' value='Close' className='btn-sm btn-danger-solid ml-sm' onClick={cancelClick} disabled={isButtonDisabled} />
				</div>
			</div>
		</div>
	);
};

export default EditDeviceModal;
