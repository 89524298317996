import './Widget.css';

import { useNavigate } from 'react-router-dom';

const MarketSystemStatusWidget = () => {
	const navigate = useNavigate();

	return (
		<div
			className='widget'
			onClick={() => {
				navigate('/system-status');
			}}>
			<svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 285.000000 285.000000' preserveAspectRatio='xMidYMid meet'>
				<g transform='translate(0.000000,285.000000) scale(0.100000,-0.100000)'>
					<path d='M195 2827 c-87 -34 -151 -101 -174 -182 -9 -30 -11 -355 -9 -1240 3 -1170 3 -1201 23 -1240 25 -50 80 -105 130 -130 39 -20 67 -20 1260 -20 1193 0 1221 0 1260 20 50 25 105 80 130 130 20 39 20 67 20 1260 0 1193 0 1221 -20 1260 -25 50 -80 105 -130 130 -39 20 -69 20 -1250 22 -910 1 -1218 -1 -1240 -10z m2439 -39 c55 -16 138 -99 154 -154 17 -60 17 -2358 0 -2418 -16 -55 -99 -138 -154 -154 -60 -17 -2358 -17 -2418 0 -55 16 -138 99 -154 154 -17 60 -17 2358 0 2418 15 51 99 137 148 153 53 17 2368 17 2424 1z' />
					<path d='M2190 2501 c-48 -15 -85 -39 -121 -78 -61 -64 -74 -97 -74 -193 0 -96 13 -129 74 -193 60 -64 103 -82 201 -82 98 0 141 18 201 82 61 64 74 97 74 193 0 96 -13 129 -74 193 -53 57 -104 80 -186 83 -38 2 -81 0 -95 -5z m144 -63 c79 -24 156 -126 156 -208 0 -51 -26 -106 -70 -150 -92 -92 -208 -92 -300 0 -92 92 -92 208 0 300 64 65 130 83 214 58z' />
					<path d='M2197 2364 l-27 -15 27 -47 c14 -26 30 -52 34 -58 5 -5 -7 -35 -26 -68 -19 -31 -35 -59 -35 -60 0 -2 11 -11 25 -20 24 -16 25 -15 51 30 l27 46 26 -45 c26 -44 27 -45 54 -31 l27 15 -27 47 c-14 26 -31 53 -36 59 -6 9 1 31 22 68 17 30 31 59 31 63 0 5 -11 14 -24 20 -23 10 -27 8 -49 -30 l-24 -41 -24 40 c-24 40 -25 41 -52 27z' />
					<path d='M327 2462 c-15 -16 -17 -47 -17 -229 0 -191 2 -211 18 -226 16 -15 47 -17 229 -17 191 0 211 2 226 18 15 16 17 47 17 229 0 191 -2 211 -18 226 -16 15 -47 17 -229 17 -191 0 -211 -2 -226 -18z m413 -227 l0 -185 -185 0 -185 0 0 185 0 185 185 0 185 0 0 -185z' />
					<path d='M1440 2415 c-7 -14 -11 -27 -9 -29 2 -2 47 -28 99 -57 l95 -54 -313 -3 -312 -2 0 -30 0 -30 312 -2 312 -3 -92 -53 c-50 -29 -94 -56 -96 -60 -2 -4 3 -17 12 -29 l17 -22 150 87 c129 75 150 91 150 112 0 21 -21 37 -150 112 -82 48 -153 87 -156 88 -3 0 -12 -11 -19 -25z' />
					<path d='M2190 1681 c-48 -15 -85 -39 -121 -78 -61 -64 -74 -97 -74 -193 0 -96 13 -129 74 -193 60 -64 103 -82 201 -82 98 0 141 18 201 82 61 64 74 97 74 193 0 96 -13 129 -74 193 -53 57 -104 80 -186 83 -38 2 -81 0 -95 -5z m144 -63 c79 -24 156 -126 156 -208 0 -107 -113 -220 -220 -220 -51 0 -106 26 -150 70 -92 92 -92 208 0 300 64 65 130 83 214 58z' />
					<path d='M2308 1448 l-86 -93 -46 45 -46 44 -22 -22 -23 -22 70 -63 c38 -34 72 -62 76 -62 6 0 144 152 183 202 19 24 18 25 -1 44 -19 20 -20 19 -105 -73z' />
					<path d='M327 1642 c-15 -16 -17 -47 -17 -229 0 -191 2 -211 18 -226 16 -15 47 -17 229 -17 191 0 211 2 226 18 15 16 17 47 17 229 0 191 -2 211 -18 226 -16 15 -47 17 -229 17 -191 0 -211 -2 -226 -18z m413 -227 l0 -185 -185 0 -185 0 0 185 0 185 185 0 185 0 0 -185z' />
					<path d='M1410 1585 c-7 -14 -11 -27 -9 -29 2 -2 45 -27 94 -55 l90 -51 -307 0 -308 0 0 -40 0 -40 307 0 308 0 -80 -46 c-106 -61 -108 -64 -87 -91 l17 -22 150 87 c129 75 150 91 150 112 0 21 -21 37 -150 112 -82 48 -153 87 -156 88 -3 0 -12 -11 -19 -25z' />
					<path d='M2190 901 c-48 -15 -85 -39 -121 -78 -61 -64 -74 -97 -74 -193 0 -96 13 -129 74 -193 60 -64 103 -82 201 -82 98 0 141 18 201 82 61 64 74 97 74 193 0 96 -13 129 -74 193 -53 57 -104 80 -186 83 -38 2 -81 0 -95 -5z m144 -63 c79 -24 156 -126 156 -208 0 -51 -26 -106 -70 -150 -92 -92 -208 -92 -300 0 -92 92 -92 208 0 300 64 65 130 83 214 58z' />
					<path d='M2193 763 l-23 -15 32 -57 32 -58 -32 -56 c-18 -31 -32 -58 -32 -61 0 -2 11 -11 24 -20 24 -15 25 -15 51 31 l27 46 25 -46 c24 -42 27 -45 49 -35 31 14 30 19 -8 88 l-31 58 31 51 c18 28 32 55 32 60 0 4 -11 13 -25 19 -22 10 -26 8 -49 -30 l-24 -41 -21 34 c-12 19 -21 37 -21 42 0 11 -12 8 -37 -10z' />
					<path d='M327 822 c-15 -16 -17 -47 -17 -229 0 -191 2 -211 18 -226 16 -15 47 -17 229 -17 191 0 211 2 226 18 15 16 17 47 17 229 0 191 -2 211 -18 226 -16 15 -47 17 -229 17 -191 0 -211 -2 -226 -18z m413 -227 l0 -185 -185 0 -185 0 0 185 0 185 185 0 185 0 0 -185z' />
					<path d='M1400 765 c-7 -14 -11 -27 -9 -29 2 -2 47 -28 99 -57 l95 -54 -313 -3 -312 -2 0 -30 0 -30 312 -2 312 -3 -92 -53 c-50 -29 -94 -56 -96 -60 -2 -4 3 -17 12 -29 l17 -22 150 87 c129 75 150 91 150 112 0 21 -21 37 -150 112 -82 48 -153 87 -156 88 -3 0 -12 -11 -19 -25z' />
				</g>
			</svg>
			<div className='widget-text'>Market System Status</div>
		</div>
	);
};

export default MarketSystemStatusWidget;
