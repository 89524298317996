import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { onMessageListener } from '../pushNotifications';

const CustomNotification = () => {
	const [notification, setNotification] = useState({ title: '', body: '' });
	const [showNotification, setShowNotification] = useState('notification-animate-out');
	const navigate = useNavigate();

	onMessageListener().then((payload) => {
		setNotification({
			title: payload.notification.title,
			body: payload.notification.body,
		});
		// console.log(payload);

		setShowNotification('notification-animate-in');

		setTimeout(() => {
			setShowNotification('notification-animate-out');
		}, 8000);
	});

	const navigateToNotifications = () => {
		navigate('/notifications');
	};

	return (
		<div className={'custom-notification ' + showNotification} onClick={navigateToNotifications}>
			<img className='custom-notification-icon' src='/img/fms_icon.png' alt='Notification-Icon' />
			<div className='custom-notification-content'>
				<h3 className='custom-notification-title'>{notification.title}</h3>
				<p className='custom-notification-body'>{notification.body}</p>
			</div>
		</div>
	);
};

export default CustomNotification;
