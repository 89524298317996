import './App.css';

// React Hot Toast
import { Toaster } from 'react-hot-toast';

// react router
import { BrowserRouter as Router } from 'react-router-dom';

import ApplicationRoutes from '../routes/ApplicationRoutes';
import CustomNotification from '../components/CustomNotification';
import BackButtonListener from '../components/BackButtonListener';

const App = () => {
	return (
		<Router>
			<CustomNotification />
			<ApplicationRoutes />
			<Toaster
				position='top-center'
				reverseOrder={true}
				gutter={8}
				containerClassName=''
				containerStyle={{ zIndex: 11000 }}
				toastOptions={{
					// Define default options
					className: '',
					duration: 5000,
					style: {
						background: '#04a704',
						color: '#fafafa',
					},
					// Default options for specific types
					success: {
						duration: 5000,
						style: {
							backgroundColor: '#04a704',
							color: '#fafafa',
						},
					},
					error: {
						duration: 5000,
						style: {
							backgroundColor: '#cc0000',
							color: '#fafafa',
						},
					},
				}}
			/>
			<BackButtonListener />
		</Router>
	);
};

export default App;
