import { useState, useEffect } from 'react';

import './Registration.css';

import toast from 'react-hot-toast';
import FormData from 'form-data';
import { useNavigate } from 'react-router';

import NavigationMenu from '../../components/NavigationMenu';
import CustomLoader from '../../components/CustomLoader';
import RoleDropdown from '../../components/RoleDropdown';
import useJwt from '../../hooks/useJwt';
import MarketDropdown from '../../components/MarketDropdown';
import AgentCodeInput from '../../components/AgentCodeInput';
import BuyerNumberInputs from '../../components/BuyerNumberInputs';
import SupplierNumberInputs from '../../components/SupplierNumberInputs';
import GroupDropdown from '../../components/GroupDropdown';
import ScreenHeading from '../../components/ScreenHeading';

// AGENT CODES FOR TESTING
// AAM
// MS

const AddMarketScreen = () => {
    const jwt = useJwt();
    const [registrationData, setRegistrationData] = useState({
        markets: [],
        groups: [],
        roles: [],
        filteredRoles: [],
    });
    const [formData, setFormData] = useState({
        group: jwt.data.group,
        role: jwt.data.role_id,
        roleDescription: jwt.data.role,
        market: '',
        agentCode: '',
        buyerNumbers: [{ buyerNumber: '' }],
        supplierNumbers: [{ supplierNumber: '' }],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isRoleDropdownOpen, setIsRoleDropdownOpen] = useState(false);
    const [isMarketDropdownOpen, setIsMarketDropdownOpen] = useState(false);
    const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
    const [isRoleSelected, setIsRoleSelected] = useState(false);
    const [approvedRole, setApprovedRole] = useState(false);
    const [pendingGroup, setPendingGroup] = useState('');
    const [pendingRole, setPendingRole] = useState('');

    const navigate = useNavigate();

    useEffect(
        () => {
            const fetchRegistrationData = async () => {
                let postFormData = new FormData();

                postFormData.append('uuid', jwt.data.id);

                // ! POSSIBLE RESPONSES: success, pc not verified, r not verified, fail
                const response = await fetch('https://staffapi.freshmarksystems.co.za/core/get-registration-data', {
                    method: 'POST',
                    body: postFormData,
                });

                const responseData = await response.json();

                if (responseData.message === 'success') {
                    // set registration data
                    setRegistrationData({ ...registrationData, groups: responseData.groups, markets: responseData.markets, roles: responseData.roles });
                    setIsLoading(false);

                    if (jwt.data.role !== '') {
                        setApprovedRole(true);
                    } else {
                        // this means we can assume the user has no approved role
                        // now we check if localStorage contains role, role id and group
                        const localStoragePendingGroup = localStorage.getItem('pendingGroup');
                        const localStoragePendingRole = localStorage.getItem('pendingRole');
                        const localStoragePendingRoleDescription = localStorage.getItem('pendingRoleDescription');

                        if (localStoragePendingGroup !== null && localStoragePendingRole !== null && localStoragePendingRoleDescription !== null) {
                            setPendingGroup(localStoragePendingGroup);
                            setPendingRole(localStoragePendingRoleDescription);

                            setFormData({
                                ...formData,
                                group: localStoragePendingGroup,
                                role: localStoragePendingRole,
                                roleDescription: localStoragePendingRoleDescription,
                            });
                        }

                        if (jwt.data.pending_role !== '' && jwt.data.pending_role_id !== '') {
                            setFormData({
                                ...formData,
                                role: jwt.data.pending_role_id,
                                roleDescription: jwt.data.pending_role,
                            });
                        }
                    }
                }
            };

            fetchRegistrationData();
        },
        // eslint-disable-next-line
        []
    );

    const validateForm = () => {
        setIsButtonDisabled(true);

        // Make sure group is selected
        if (formData.group === '') {
            toast.error('Please select a role.');
            setIsButtonDisabled(false);
            return false;
        }

        // Make sure a role is selected
        if (formData.role === '' && isRoleSelected) {
            toast.error('Please select a designation.');
            setIsButtonDisabled(false);
            return false;
        }

        // Make sure market is selected
        if (formData.market === '') {
            toast.error('Please select a market.');
            setIsButtonDisabled(false);
            return false;
        }

        // Now we check the role specific stuff
        switch (formData.group) {
            case 'Agent':
                if (formData.agentCode === '') {
                    toast.error('Agent Code cannot be empty.');
                    setIsButtonDisabled(false);
                    return false;
                }

                break;
            case 'Buyer':
                let emptyBuyerNumber = false;

                for (let i = 0; i < formData.buyerNumbers.length; i++) {
                    if (formData.buyerNumbers[i].buyerNumber === '') {
                        emptyBuyerNumber = true;
                    }
                }

                if (emptyBuyerNumber) {
                    toast.error('Please ensure all of your Buyer Numbers are not empty.');
                    setIsButtonDisabled(false);
                    return false;
                }
                break;
            case 'Supplier':
                let emptySupplierNumber = false;

                for (let i = 0; i < formData.supplierNumbers.length; i++) {
                    if (formData.supplierNumbers[i].supplierNumber === '') {
                        emptySupplierNumber = true;
                    }
                }

                if (emptySupplierNumber) {
                    toast.error('Please ensure all of your Supplier Numbers are not empty.');
                    setIsButtonDisabled(false);
                    return false;
                }

                break;

            default:
                break;
        }

        // This means validation has passed, now submit the request
        submitRegistrationRequest();
    };

    const submitRegistrationRequest = async () => {
        const loadingToast = toast.loading('Sending registration request...');
        let postFormData = new FormData();
        let role = formData.role;

        postFormData.append('email_address', jwt.data.email);
        postFormData.append('user_name', jwt.data.name);
        postFormData.append('device_number', jwt.data.id);
        postFormData.append('market', formData.market);
        postFormData.append('role', role);
        postFormData.append('api_key', process.env.REACT_APP_WEBSERVICES_API_KEY);
        postFormData.append('event_id', process.env.REACT_APP_WEBSERVICES_EVENT);
        // console.log(formData);
        // return false;
        // Initialise empty reference number array
        let referenceNumberArray = [];

        // Check the role and create the reference number array based on the role
        switch (formData.group) {
            case 'Agent':
                referenceNumberArray.push(formData.agentCode);
                break;
            case 'Buyer':
                referenceNumberArray = formData.buyerNumbers.map((bn) => bn.buyerNumber);
                break;
            case 'Supplier':
                referenceNumberArray = formData.supplierNumbers.map((sn) => sn.supplierNumber);
                break;
            default:
                break;
        }

        postFormData.append('registration_numbers', referenceNumberArray.toString());

        // Send request to webservices
        const response = await fetch('https://webservices.freshmarksystems.co.za/mobiapi/register', {
            method: 'POST',
            body: postFormData,
        });

        const responseData = await response.json();

        // Get the market name for the toast message
        const marketElement = registrationData.markets.find((element) => {
            return element.market_code === formData.market;
        });

        switch (responseData.code) {
            // Accepted immediately
            case 91001:
                toast.dismiss(loadingToast);

                // Submit this data to app backend
                const response = await submitRegistrationDataToAppBackend();

                // Possible responses - success, success rl, fail, forbidden
                switch (response) {
                    case 'success rl':
                        toast.success(`Registration at ${marketElement.name} successful. Please sign in again for the registration to take effect.`, {
                            duration: 5000,
                        });

                        setTimeout(() => {
                            localStorage.removeItem('token');

                            navigate('/sign-in');
                        }, 5000);
                        break;

                    case 'success':
                        toast.success(`${marketElement.name} successfully added. Redirecting to home screen...`, {
                            duration: 5000,
                        });

                        setTimeout(() => {
                            navigate('/home');
                        }, 5000);
                        break;

                    case 'fail':
                        toast.error('The server seems to have run into a problem. Please contact support to rectify this.', { duration: 3000 });

                        setTimeout(() => {
                            navigate('/add-market');
                        }, 5000);
                        break;

                    default:
                        break;
                }

                break;

            // Handed over to market for approval
            case 91002:
                toast.dismiss(loadingToast);

                toast.success(
                    `Registration request handed over for approval. Please be aware that this can take up to 24 hours. Redirecting to Home Screen...`,
                    { duration: 7500 }
                );

                // ADD PENDING REGISTRATIONS FLAG TO LOCAL STORAGE
                localStorage.setItem('pendingRegistrations', '1');

                // Add group to local storage temporarily
                if (jwt.data.role === '') {
                    localStorage.setItem('pendingGroup', formData.group);
                    localStorage.setItem('pendingRole', formData.role);
                    localStorage.setItem('pendingRoleDescription', formData.roleDescription);
                }

                if (formData.group === 'Market') {
                    // set market indicator
                    localStorage.setItem('marketRole', '1');
                }

                setTimeout(() => {
                    navigate('/home');
                }, 7500);

                break;

            // Ref numbers not found
            // ! DOES THIS MEAN IT WILL BE SENT FOR APPROVAL?
            case 91008:
                toast.error(`Reference number(s) not found at ${marketElement.name}.`, { duration: 5000 });

                setIsButtonDisabled(false);

                break;

            case 91010:
                toast.error(`Connection to ${marketElement.name} temporarily unavailable. Please try again soon.`, { duration: 5000 });

                setIsButtonDisabled(false);

                break;

            case 91020:
                toast.error(`Registration already pending at ${marketElement.name}.`, { duration: 5000 });

                setIsButtonDisabled(false);

                break;

            case 91050:
                toast.success(`Registration at ${marketElement.name} already exists. Adding reference number for approval.`, { duration: 5000 });

                setTimeout(() => {
                    navigate('/home');
                }, 5000);

                break;

            default:
                toast.error(`Unable to process this registration at this time. Please try again soon, or contact support.`, { duration: 5000 });

                setIsButtonDisabled(false);

                break;
        }
    };

    const submitRegistrationDataToAppBackend = async () => {
        let postFormData = new FormData();

        let response;

        if (jwt.data.role !== '') {
            // Role is already established, just add market
            postFormData.append('device_number', jwt.data.id);
            postFormData.append('market', formData.market);

            // Send request to app backend
            response = await fetch('https://staffapi.freshmarksystems.co.za/market/add-user-market', {
                method: 'POST',
                body: postFormData,
            });
        } else {
            postFormData.append('device_number', jwt.data.id);
            postFormData.append('market', formData.market);
            postFormData.append('role', formData.roleDescription);

            // Send request to app backend
            response = await fetch('https://staffapi.freshmarksystems.co.za/core/add-registration-data', {
                method: 'POST',
                body: postFormData,
            });
        }

        const responseData = await response.json();

        return responseData.message;
    };

    const showGroupDropdown = () => {
        if (pendingRole === '' && jwt.data.role === '' && jwt.data.pending_role === '') {
            return (
                <GroupDropdown
                    groups={registrationData.groups}
                    // ! FILTER ROLES ON CHANGE OF THIS DROPDOWN
                    roles={registrationData.roles}
                    filteredRoles={registrationData.filteredRoles}
                    formData={formData}
                    setFormData={setFormData}
                    isGroupDropdownOpen={isGroupDropdownOpen}
                    setIsGroupDropdownOpen={setIsGroupDropdownOpen}
                    isRoleDropdownOpen={isRoleDropdownOpen}
                    setIsRoleDropdownOpen={setIsRoleDropdownOpen}
                    isMarketDropdownOpen={isMarketDropdownOpen}
                    setIsMarketDropdownOpen={setIsMarketDropdownOpen}
                    setIsRoleSelected={setIsRoleSelected}
                    registrationData={registrationData}
                    setRegistrationData={setRegistrationData}
                />
            );
        }
    };

    const showReferenceNumberInputs = () => {
        if (formData.group === 'Agent' || pendingGroup === 'Agent') {
            return <AgentCodeInput formData={formData} setFormData={setFormData} />;
        }

        if (formData.group === 'Buyer' || pendingGroup === 'Buyer') {
            return <BuyerNumberInputs formData={formData} setFormData={setFormData} />;
        }

        if (formData.group === 'Supplier' || pendingGroup === 'Supplier') {
            return <SupplierNumberInputs formData={formData} setFormData={setFormData} />;
        }

        // ! TEST TO SEE IF THIS IS WORKING!
    };

    const showRoleHeader = () => {
        if (pendingRole !== '') {
            return (
                <div className='approved-role'>
                    <p className='approved-role-text'>{`${pendingRole} (Pending)`}</p>
                </div>
            );
        }

        if (approvedRole) {
            return (
                <div className='approved-role'>
                    <p className='approved-role-text'>{`${jwt.data.role}`}</p>
                </div>
            );
        }

        if (jwt.data.pending_role !== '') {
            return (
                <div className='approved-role'>
                    <p className='approved-role-text'>{`${jwt.data.pending_role} (Pending)`}</p>
                </div>
            );
        }
    };

    return (
        <>
            {isLoading && <CustomLoader text='Setting a few things up...' />}
            <NavigationMenu />
            <ScreenHeading heading='Add Market' />
            <div className='add-market'>
                <div className='container'>
                    {showGroupDropdown()}
                    {showRoleHeader()}
                    {isRoleSelected && (
                        <RoleDropdown
                            roles={registrationData.roles}
                            filteredRoles={registrationData.filteredRoles}
                            formData={formData}
                            setFormData={setFormData}
                            isRoleDropdownOpen={isRoleDropdownOpen}
                            setIsRoleDropdownOpen={setIsRoleDropdownOpen}
                            isMarketDropdownOpen={isMarketDropdownOpen}
                            setIsMarketDropdownOpen={setIsMarketDropdownOpen}
                            isGroupDropdownOpen={isGroupDropdownOpen}
                            setIsGroupDropdownOpen={setIsGroupDropdownOpen}
                        />
                    )}
                    <MarketDropdown
                        markets={registrationData.markets}
                        formData={formData}
                        setFormData={setFormData}
                        isRoleDropdownOpen={isRoleDropdownOpen}
                        setIsRoleDropdownOpen={setIsRoleDropdownOpen}
                        isMarketDropdownOpen={isMarketDropdownOpen}
                        setIsMarketDropdownOpen={setIsMarketDropdownOpen}
                        isGroupDropdownOpen={isGroupDropdownOpen}
                        setIsGroupDropdownOpen={setIsGroupDropdownOpen}
                    />
                    {showReferenceNumberInputs()}

                    {/* {jwt.data.group === 'Agent' && <AgentCodeInput formData={formData} setFormData={setFormData} />}
					{jwt.data.group === 'Buyer' && <BuyerNumberInputs formData={formData} setFormData={setFormData} />}
					{jwt.data.group === 'Supplier' && <SupplierNumberInputs formData={formData} setFormData={setFormData} />}
					{pendingGroup === 'Agent' && <AgentCodeInput formData={formData} setFormData={setFormData} />}
					{pendingGroup === 'Buyer' && <BuyerNumberInputs formData={formData} setFormData={setFormData} />}
					{pendingGroup === 'Supplier' && <SupplierNumberInputs formData={formData} setFormData={setFormData} />} */}
                    <input type='button' value='Submit' className='btn btn-primary-solid mt-md' onClick={validateForm} disabled={isButtonDisabled} />
                </div>
            </div>
        </>
    );
};

export default AddMarketScreen;
