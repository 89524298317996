import { useRef } from 'react';
import jwt_decode from 'jwt-decode';

import { useNavigate } from 'react-router-dom';

const useJwt = () => {
	// const [userData, setUserData] = useState();
	const jwt = useRef({});

	const navigate = useNavigate();

	// get token from local storage
	const token = localStorage.getItem('token');

	if (token) {
		const decoded = jwt_decode(token);

		jwt.current = decoded;
	} else {
		// sign the user out
		navigate('/sign-in');
	}

	return jwt.current;
};

export default useJwt;
