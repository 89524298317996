import NavigationHome from '../../components/NavigationHome';
import ScreenHeading from '../../components/ScreenHeading';
import HomeScreenMap from '../../components/maps/HomeScreenMap';

const MarketDirectoryScreen = () => {
	return (
		<>
			<NavigationHome />
			<ScreenHeading heading='Market Directory' />
			<div className='market-directory'>
				<div className='market-directory-container'>
					<HomeScreenMap />
				</div>
			</div>
		</>
	);
};

export default MarketDirectoryScreen;
