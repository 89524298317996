import './Widget.css';

import { useNavigate } from 'react-router-dom';

const MarketDirectoryWidget = () => {
	const navigate = useNavigate();

	return (
		<div
			className='widget'
			onClick={() => {
				navigate('/market-directory');
			}}>
			<svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 285.000000 285.000000' preserveAspectRatio='xMidYMid meet'>
				<g transform='translate(0.000000,285.000000) scale(0.100000,-0.100000)'>
					<path d='M260 2843 c-105 -17 -192 -81 -233 -171 l-22 -47 0 -1190 c0 -1126 1 -1193 18 -1242 21 -61 82 -129 142 -158 39 -20 68 -20 1245 -23 1353 -3 1251 -8 1338 72 25 23 56 64 68 91 l24 50 0 1200 0 1200 -24 51 c-42 90 -114 145 -217 164 -50 10 -2283 12 -2339 3z m2371 -55 c51 -15 114 -69 142 -122 l22 -41 0 -1200 0 -1200 -22 -40 c-28 -52 -68 -89 -123 -114 -44 -20 -60 -21 -1219 -21 -900 0 -1183 3 -1215 12 -56 17 -141 100 -155 153 -8 28 -11 399 -11 1218 0 1015 2 1183 15 1213 28 67 79 117 144 140 50 18 2363 20 2422 2z' />
					<path d='M766 2599 c-185 -31 -343 -166 -408 -349 l-23 -65 0 -740 0 -740 23 -65 c57 -159 174 -276 332 -331 59 -21 84 -23 263 -23 197 -1 199 -1 218 23 18 23 19 55 19 1141 l0 1119 -26 20 c-25 19 -39 21 -188 20 -88 -1 -183 -5 -210 -10z m284 -1155 l0 -1035 -153 3 c-149 3 -154 4 -218 35 -80 39 -150 111 -187 191 l-27 57 0 755 0 755 28 53 c51 97 137 173 235 208 24 8 89 13 180 14 l142 0 0 -1036z' />
					<path d='M1392 2598 c-39 -19 -42 -86 -4 -105 9 -4 161 -10 337 -13 l320 -5 60 -29 c80 -40 149 -111 188 -194 l32 -67 0 -740 0 -740 -32 -67 c-39 -83 -108 -154 -188 -194 l-60 -29 -323 -5 -324 -5 -19 -24 c-25 -31 -24 -47 6 -77 l26 -26 307 4 c282 4 312 6 373 26 171 57 298 192 344 368 22 87 22 1451 0 1538 -46 176 -173 311 -344 368 -61 20 -90 22 -371 25 -214 2 -312 -1 -328 -9z' />
					<path d='M1678 2016 c-205 -56 -235 -337 -45 -435 135 -69 304 10 336 159 37 171 -122 322 -291 276z m127 -161 c19 -18 25 -35 25 -65 0 -30 -6 -47 -25 -65 -18 -19 -35 -25 -65 -25 -30 0 -47 6 -65 25 -19 18 -25 35 -25 65 0 30 6 47 25 65 18 19 35 25 65 25 30 0 47 -6 65 -25z' />
					<path d='M1668 1365 c-175 -44 -316 -189 -334 -341 -6 -44 -3 -55 15 -73 43 -43 95 -16 105 55 16 117 122 218 256 242 153 28 276 -63 303 -225 7 -39 18 -77 26 -86 19 -23 57 -21 82 4 19 19 21 29 16 79 -21 202 -160 341 -352 355 -34 3 -84 -2 -117 -10z' />
				</g>
			</svg>
			<div className='widget-text'>Market Directory</div>
		</div>
	);
};

export default MarketDirectoryWidget;
