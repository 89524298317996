import './Widget.css';

import { useNavigate } from 'react-router-dom';

const BlockBuyerCardWidget = () => {
	const navigate = useNavigate();

	return (
		<div
			className='widget'
			onClick={() => {
				navigate('/block-buyer-card');
			}}>
			<svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 603.000000 604.000000' preserveAspectRatio='xMidYMid meet'>
				<g transform='translate(0.000000,604.000000) scale(0.100000,-0.100000)'>
					<path d='M462 6020 c-222 -58 -385 -222 -442 -446 -20 -76 -20 -122 -20 -2554 0 -2432 0 -2478 20 -2554 57 -225 221 -389 446 -446 76 -20 122 -20 2549 -20 2427 0 2473 0 2549 20 225 57 389 221 446 446 20 76 20 122 20 2554 0 2432 0 2478 -20 2554 -57 225 -221 389 -446 446 -76 20 -121 20 -2553 19 -2403 0 -2478 -1 -2549 -19z m5077 -86 c195 -49 336 -190 385 -385 15 -59 16 -285 16 -2529 0 -2244 -1 -2470 -16 -2529 -49 -195 -190 -336 -385 -385 -59 -15 -285 -16 -2524 -16 -2239 0 -2465 1 -2524 16 -195 49 -336 190 -385 385 -15 59 -16 285 -16 2529 0 2244 1 2470 16 2529 25 98 69 178 138 247 68 68 139 108 236 135 61 17 202 18 2529 18 2246 1 2471 0 2530 -15z' />
					<path d='M2835 5524 c-358 -37 -632 -113 -920 -256 -141 -69 -165 -89 -165 -134 0 -33 36 -74 65 -74 7 0 81 35 166 78 489 248 1063 307 1615 167 324 -83 631 -236 891 -445 199 -161 426 -423 556 -642 247 -415 363 -928 316 -1404 -54 -564 -283 -1049 -684 -1449 -373 -373 -805 -591 -1335 -672 -159 -24 -491 -24 -650 0 -263 41 -483 108 -705 217 -405 199 -758 526 -995 920 -294 488 -398 1098 -284 1668 94 473 371 947 750 1284 62 55 114 109 117 120 8 30 -19 77 -50 84 -41 11 -85 -20 -231 -161 -427 -412 -676 -898 -763 -1490 -18 -119 -18 -501 0 -620 87 -589 338 -1081 759 -1487 388 -373 854 -603 1392 -685 141 -21 500 -24 635 -5 393 56 759 192 1062 395 613 410 1013 1037 1120 1756 24 162 24 512 -1 676 -79 535 -322 1020 -704 1409 -392 398 -876 647 -1422 731 -101 15 -450 28 -535 19z' />
					<path d='M2845 5133 c-350 -31 -707 -154 -991 -341 -221 -145 -461 -385 -607 -607 -350 -532 -440 -1216 -241 -1827 76 -233 233 -519 366 -669 70 -78 -71 -207 1552 1416 856 856 1458 1465 1462 1479 10 41 -4 62 -84 123 -195 150 -368 242 -612 325 -252 85 -580 125 -845 101z m363 -144 c220 -23 469 -97 677 -199 118 -58 306 -175 312 -195 2 -6 -148 -161 -332 -345 l-335 -335 -357 357 c-253 251 -370 361 -398 373 -54 23 -149 24 -205 3 -37 -15 -143 -116 -607 -580 -557 -559 -562 -563 -578 -621 -20 -68 -14 -129 17 -192 12 -25 155 -175 371 -392 l352 -353 -335 -335 c-184 -184 -338 -335 -343 -335 -15 0 -147 214 -202 325 -98 201 -171 452 -194 667 -67 614 194 1254 684 1675 424 365 954 539 1473 482z m-134 -828 l351 -351 -58 -57 -57 -58 -375 375 c-206 206 -375 380 -375 387 0 7 17 24 38 38 30 21 45 25 81 22 43 -5 54 -15 395 -356z m-244 -196 l375 -375 -93 -92 -92 -93 -380 380 -380 380 87 87 c48 49 92 88 98 88 5 0 179 -169 385 -375z m-290 -290 l375 -375 -45 -45 -44 -45 -261 259 c-280 280 -308 301 -400 301 -163 0 -272 -171 -204 -320 14 -32 94 -119 279 -305 l260 -261 -135 -134 -135 -135 -341 340 c-187 187 -347 352 -355 367 -19 36 -18 78 2 108 24 36 607 620 619 620 6 0 179 -169 385 -375z m-83 -307 c139 -139 253 -257 253 -262 0 -14 -92 -106 -105 -106 -6 0 -124 114 -263 253 -239 239 -252 255 -252 290 0 24 7 44 20 57 13 13 33 20 57 20 35 0 51 -13 290 -252z' />
					<path d='M3106 2945 c-931 -930 -1458 -1464 -1462 -1481 -10 -39 10 -66 105 -137 209 -158 460 -278 725 -348 673 -175 1417 -1 1921 448 241 215 386 403 515 665 275 560 292 1214 47 1778 -79 182 -205 388 -300 493 -70 75 68 201 -1551 -1418z m1546 1174 c166 -246 295 -599 327 -901 87 -794 -376 -1604 -1125 -1969 -323 -158 -693 -225 -1032 -188 -215 23 -466 96 -667 194 -111 55 -325 187 -325 202 0 5 151 159 335 343 l335 335 353 -352 c217 -216 367 -359 392 -371 63 -31 124 -37 191 -17 51 15 70 28 156 114 105 103 112 120 77 165 -35 45 -75 36 -139 -30 -107 -110 -156 -132 -218 -100 -15 8 -180 168 -367 355 l-340 341 343 342 342 343 375 -375 c206 -206 375 -379 375 -384 0 -6 -45 -56 -100 -111 -60 -61 -100 -110 -100 -122 0 -35 38 -73 74 -73 28 0 66 35 373 343 333 334 342 343 358 400 20 68 14 129 -17 192 -12 25 -155 175 -371 392 l-352 353 335 335 c184 184 339 334 345 332 5 -2 36 -41 67 -88z m-505 -1031 c187 -189 346 -353 352 -365 6 -12 11 -34 11 -50 0 -31 -44 -103 -63 -103 -7 0 -181 169 -387 375 l-375 375 55 55 c30 30 57 55 60 55 3 0 159 -154 347 -342z m-190 -250 c205 -205 373 -378 373 -383 0 -6 -39 -50 -88 -98 l-87 -87 -380 380 -380 380 90 90 c49 49 92 90 95 90 3 0 173 -168 377 -372z' />
				</g>
			</svg>
			<div className='widget-text'>Block Buyer Card</div>
		</div>
	);
};

export default BlockBuyerCardWidget;
