import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Home.css';

// import { toast } from 'react-hot-toast';
import useJwt from '../../hooks/useJwt';

import NavigationMenu from '../../components/NavigationMenu';
import MessageOverlay from '../../components/MessageOverlay';
import ScreenHeading from '../../components/ScreenHeading';

// Widgets
import MarketDirectoryWidget from '../../components/widgets/MarketDirectoryWidget';
import MarketSystemStatusWidget from '../../components/widgets/MarketSystemStatusWidget';
// import NewsFeedWidget from '../../components/widgets/NewsFeedWidget';
import SupportRequestWidget from '../../components/widgets/SupportRequestWidget';
import NewCommodityWidget from '../../components/widgets/NewCommodityWidget';
// import DeliveryTrackingWidget from '../../components/widgets/DeliveryTrackingWidget';
import BroadcastNotificationWidget from '../../components/widgets/BroadcastNotificationWidget';
import BlockBuyerCardWidget from '../../components/widgets/BlockBuyerCardWidget';
import BuyerAccountWidget from '../../components/widgets/BuyerAccountWidget';
import PendingNotificationsWidget from '../../components/widgets/PendingNotificationsWidget';
import BuyerUnpaidSalesWidget from '../../components/widgets/BuyerUnpaidSalesWidget';
// import MarketStatsWidget from '../../components/widgets/MarketStatsWidget';
import BuyerEnquiryWidget from '../../components/widgets/BuyerEnquiryWidget';
import BuyerStatementWidget from '../../components/widgets/BuyerStatementWidget';
import CustomLoader from '../../components/CustomLoader';
import toast from 'react-hot-toast';

const HomeScreen = () => {
	const navigate = useNavigate();
	const jwt = useJwt();

	const [isLoading, setIsLoading] = useState(false);
	const [overlayState, setOverlayState] = useState('overlay-animate-out');
	const [isMarket, setIsMarket] = useState(false);
	const [isAgent, setIsAgent] = useState(false);
	const [isBuyer, setIsBuyer] = useState(false);
	const [birthdayMessage, setBirthdayMessage] = useState('');
	const [birthdayMessageSeen, setBirthdayMessageSeen] = useState(true);
	const [buyerAccountData, setBuyerAccountData] = useState([]);
	const [buyerNumbers, setBuyerNumbers] = useState([]);
	const [isProducer, setIsProducer] = useState(false);
	const [isFms, setIsFms] = useState(false);
	const [isFmsManco, setIsFmsManco] = useState(false);
	const [isFmsExco, setIsFmsExco] = useState(false);
	const [isFmsDeveloper, setIsFmsDeveloper] = useState(false);
	const [pendingNotifications, setPendingNotifications] = useState(false);
	const [pendingNotificationCount, setPendingNotificationCount] = useState(0);

	useEffect(
		() => {
			setIsLoading(true);

			const pendingRegistrations = localStorage.getItem('pendingRegistrations');

			console.log(jwt.data);

			// check for birthdays
			if (jwt.data.bd === '1') {
				const birthdayMessageCleared = localStorage.getItem('bdmc');

				if (birthdayMessageCleared === null || birthdayMessageCleared === undefined) {
					localStorage.setItem('bdmc', jwt.data.bdmc);
					setBirthdayMessageSeen(false);
				} else if (birthdayMessageCleared === '0') {
					setBirthdayMessageSeen(false);
				} else {
					setBirthdayMessageSeen(true);
				}
			}

			if (jwt.data.bab !== '') {
				setBuyerAccountData(JSON.parse(jwt.data.bab).buyerData);
				setBuyerNumbers(JSON.parse(jwt.data.bab).buyerNumbers);
			}

			// check for 1 market
			if (jwt.data.pm !== '') {
				localStorage.setItem('pm', jwt.data.pm);
				localStorage.setItem('pmDesc', jwt.data.pm_desc);
				setBirthdayMessage(`Dear ${jwt.data.name}. We at ${jwt.data.pm_desc} would like to wish you a very happy birthday for ${jwt.data.dob}.`);
			} else {
				setBirthdayMessage(`Dear ${jwt.data.name}. We at Freshmark Systems would like to wish you a very happy birthday for ${jwt.data.dob}.`);
			}

			if (jwt.data.role === '' && pendingRegistrations === null && jwt.data.pending === '0') {
				setOverlayState('overlay-animate-in');
			}

			// if role is set, remove the pendingRegistrations flag from local storage
			if (jwt.data.role !== '') {
				localStorage.removeItem('marketRole');
				localStorage.removeItem('pendingRole');
				localStorage.removeItem('pendingRegistrations');
			}

			// if group is set, remove pendingGroup from local storage
			if (jwt.data.group !== '') {
				localStorage.removeItem('pendingGroup');
				localStorage.removeItem('marketRole');
			}

			// if pending role id is set, remove pendingRole from local storage
			if (jwt.data.pending_role_id !== '') {
				localStorage.removeItem('pendingRole');
				localStorage.removeItem('marketRole');
			}

			// if pending role is set, remove pendingRoleDescription from local storage
			if (jwt.data.pending_role !== '') {
				localStorage.removeItem('pendingRoleDescription');
				localStorage.removeItem('marketRole');
			}

			if (jwt.data.role === 'Market Manager' || jwt.data.role === 'Market Systems Admin' || jwt.data.role === 'Market Admin') {
				setIsMarket(true);
			}

			if (jwt.data.role === 'Agent Manager' || jwt.data.role === 'Agent Admin' || jwt.data.role === 'Salesman') {
				setIsAgent(true);
			}

			if (jwt.data.role === 'Buyer') {
				setIsBuyer(true);
			}

			if (jwt.data.role === 'Supplier') {
				setIsProducer(true);
			}

			// FMS Staff
			if (jwt.data.role === 'FMS Staff') {
				setIsFms(true);
				setBirthdayMessage(
					`Dear ${jwt.data.name}. Here's wishing you a very happy birthday for ${jwt.data.dob}. We hope you have an incredibly special day and we wish you many, many more wonderful birthdays to come!`,
				);
			}

			// FMS Manco
			if (jwt.data.role === 'FMS Manco') {
				setIsFmsManco(true);

				// Check for pending notifications
				checkForPendingNotifications();
				setBirthdayMessage(
					`Dear ${jwt.data.name}. Here's wishing you a very happy birthday for ${jwt.data.dob}. We hope you have an incredibly special day and we wish you many, many more wonderful birthdays to come!`,
				);
			}

			// FMS Exco
			if (jwt.data.role === 'FMS Exco') {
				setIsFmsExco(true);
				setBirthdayMessage(
					`Dear ${jwt.data.name}. Here's wishing you a very happy birthday for ${jwt.data.dob}. We hope you have an incredibly special day and we wish you many, many more wonderful birthdays to come!`,
				);
			}

			// FMS Developer
			if (jwt.data.role === 'FMS Developer') {
				setIsFmsDeveloper(true);

				// Check for pending notifications
				checkForPendingNotifications();
				setBirthdayMessage(
					`Dear ${jwt.data.name}. Here's wishing you a very happy birthday for ${jwt.data.dob}. We hope you have an incredibly special day and we wish you many, many more wonderful birthdays to come!`,
				);
			}

			setIsLoading(false);
		},
		// eslint-disable-next-line
		[],
	);

	const acceptClick = () => {
		// Navigate user to add market screen
		navigate('/add-market');
	};

	const cancelClick = () => {
		// Close overlay
		setOverlayState('overlay-animate-out');
	};

	const getName = () => {
		return jwt.data.name.split(' ')[0];
	};

	const checkForPendingNotifications = async () => {
		// check for pending notifications, show a widget if there are
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/notifications/get-pending-notifications', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			if (responseData.pending > 0) {
				setPendingNotifications(true);
				setPendingNotificationCount(responseData.pending);
			}
		}
	};

	const closeBirthdayMessage = async () => {
		const loadingToast = toast.loading('Working...');

		// check for pending notifications, show a widget if there are
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/core/close-birthday-message', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			toast.dismiss(loadingToast);
			localStorage.setItem('bdmc', '1');
			setBirthdayMessageSeen(true);
		} else {
			toast.dismiss(loadingToast);
			toast.error('Something went wrong! Please try again.');
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Setting up your home screen...' />}
			<NavigationMenu />
			<MessageOverlay
				text={`Hi, ${getName()}! It looks like you have not identified your role yet. Would you like to complete your registration in order to unlock personal functionality within the app?`}
				acceptClick={acceptClick}
				cancelClick={cancelClick}
				overlayState={overlayState}
			/>
			<ScreenHeading heading='Dashboard' />
			<div className='dashboard'>
				<div className='dashboard-container'>
					{!birthdayMessageSeen && (
						<div className='birthday-message-container'>
							<img src='/img/hbd_gif.gif' alt='Happy Birthday Gif' className='birthday-gif' />
							<p className='birthday-message-text'>{birthdayMessage}</p>
							<input type='button' className='btn-md btn-danger-solid' value='Close' onClick={closeBirthdayMessage} />
						</div>
					)}
					{buyerAccountData.length > 0 && (
						<div className='buyer-account-container'>
							<h4 className='primary-market-header'>{jwt.data.pm_desc}</h4>
							{buyerAccountData.map((ba, index) => {
								return (
									<div className='buyer-account-row-container' key={index}>
										<div className='buyer-account-row'>
											<p className='buyer-account-label'>Buyer Number</p>
											<p className='buyer-account-info'>{buyerNumbers[index]}</p>
										</div>
										<div className='buyer-account-row'>
											<p className='buyer-account-label'>Balance</p>
											<p className='buyer-account-info buyer-account-balance'>{`R ` + ba.result.balance}</p>
										</div>
									</div>
								);
							})}
						</div>
					)}
					{/* {buyerAccountData.map((ba, index) => {
						return (
							<div className='buyer-account-container' key={index}>
								<h4 className='primary-market-header'>{jwt.data.pm_desc}</h4>
								<div className='buyer-account-row'>
									<p className='buyer-account-label'>Buyer Number</p>
									<p className='buyer-account-info'>{buyerNumbers[index]}</p>
								</div>
								<div className='buyer-account-row'>
									<p className='buyer-account-label'>Balance</p>
									<p className='buyer-account-info buyer-account-balance'>{`R ` + ba.result.balance}</p>
								</div>
							</div>
						);
					})} */}
					<div className='dashboard-widgets-container'>
						{/* FOR MANCO IF PENDING NOTIFICATIONS */}
						{pendingNotifications && <PendingNotificationsWidget pending={pendingNotificationCount} name={getName()} />}

						{/* ALL USERS */}
						<MarketDirectoryWidget />

						{/* MARKETS */}
						{/* {isMarket && <MarketDirectoryWidget />} */}
						{isMarket && <SupportRequestWidget />}
						{isMarket && <NewCommodityWidget />}
						{isMarket && <MarketSystemStatusWidget />}
						{jwt.data.role === 'Market Manager' && <BroadcastNotificationWidget />}

						{/* FMS Staff */}
						{/* {isFms && <MarketDirectoryWidget />} */}
						{isFms && <MarketSystemStatusWidget />}

						{/* FMS Manco */}
						{/* {isFmsManco && <MarketDirectoryWidget />} */}
						{isFmsManco && <MarketSystemStatusWidget />}

						{/* FMS Exco */}
						{/* {isFmsExco && <MarketDirectoryWidget />} */}
						{isFmsExco && <MarketSystemStatusWidget />}

						{/* FMS Developer */}
						{/* {isFmsDeveloper && <MarketDirectoryWidget />} */}
						{isFmsDeveloper && <SupportRequestWidget />}
						{isFmsDeveloper && <NewCommodityWidget />}
						{isFmsDeveloper && <MarketSystemStatusWidget />}
						{/* {isFmsDeveloper && <BroadcastNotificationWidget />} */}
						{/* {isFmsDeveloper && <BlockBuyerCardWidget />} */}
						{/* {isFmsDeveloper && <BuyerAccountWidget />} */}

						{/* AGENTS */}
						{/* {isAgent && <MarketDirectoryWidget />} */}
						{isAgent && <MarketSystemStatusWidget />}
						{isAgent && <SupportRequestWidget />}
						{isAgent && <NewCommodityWidget />}

						{/* BUYERS */}
						{/* {isBuyer && <MarketDirectoryWidget />} */}
						{isBuyer && <MarketSystemStatusWidget />}
						{isBuyer && <BlockBuyerCardWidget />}
						{isBuyer && <BuyerAccountWidget />}
						{isBuyer && <BuyerUnpaidSalesWidget />}
						{/* {isBuyer && <MarketStatsWidget />} */}
						{isBuyer && <BuyerEnquiryWidget />}
						{isBuyer && <BuyerStatementWidget />}

						{/* SUPPLIERS */}
						{isProducer && <MarketSystemStatusWidget />}
						{isProducer && <MarketDirectoryWidget />}
					</div>
				</div>
			</div>
		</>
	);
};

export default HomeScreen;
