import { useLocation } from 'react-router-dom';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';

const ViewBuyerUnpaidSalesScreen = () => {
	const { state } = useLocation();

	const getValue = (value) => {
		const result = value.includes('.');

		if (!result) {
			return 'R ' + value + '.00';
		}

		return 'R ' + value;
	};

	return (
		<>
			<NavigationBack />
			<ScreenHeading heading='Buyer Unpaid Sales Data' />
			<div className='buyer-enquiry-data-screen'>
				<div className='buyer-enquiry-data-container'>
					{state.unpaidSales.map((us, index) => {
						return (
							<div className='buyer-enquiry-info-container' key={index}>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Buyer Number</div>
									<div className='buyer-enquiry-info'>{us.buyer_no}</div>
								</div>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Agent</div>
									<div className='buyer-enquiry-info'>{us.agent}</div>
								</div>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Docket Number</div>
									<div className='buyer-enquiry-info'>{us.docket_no}</div>
								</div>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Product</div>
									<div className='buyer-enquiry-info'>{us.product}</div>
								</div>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Commodity</div>
									<div className='buyer-enquiry-info'>{us.commodity}</div>
								</div>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Quantity</div>
									<div className='buyer-enquiry-info'>{us.quantity}</div>
								</div>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Price</div>
									<div className='buyer-enquiry-info'>{'R ' + us.price}</div>
								</div>
								<div className='buyer-enquiry-data-row'>
									<div className='buyer-enquiry-label'>Value</div>
									<div className='buyer-enquiry-info'>{getValue(us.value.toString())}</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default ViewBuyerUnpaidSalesScreen;
