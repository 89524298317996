import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import FormData from 'form-data';
import { toast } from 'react-hot-toast';
import { isMobile, isBrowser } from 'react-device-detect';

initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: 'fms-ia-pwa.firebaseapp.com',
	projectId: 'fms-ia-pwa',
	storageBucket: 'fms-ia-pwa.appspot.com',
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const messaging = getMessaging();

export const requestToken = () => {
	return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
		.then((currentToken) => {
			if (currentToken) {
				// console.log('current token for client: ', currentToken);
				// Perform any other neccessary action with the token

				return currentToken;
			} else {
				// Show permission request UI
				console.log('No registration token available. Request permission to generate one.');
			}
		})
		.catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
		});
};

export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			// store route in local storage
			localStorage.setItem('notRoute', payload.data.route);

			resolve(payload);
		});
	});

export const registerTokenOnBackend = async (token, jwt, setNotificationsEnabled, setIsButtonDisabled) => {
	// Idea here is we are going to send the fcm token to the backend
	let postFormData = new FormData();
	let deviceDescription = '';

	if (isMobile) {
		deviceDescription = 'Phone or Tablet';
	}

	if (isBrowser) {
		deviceDescription = 'Laptop or Desktop';
	}

	postFormData.append('token', token);
	postFormData.append('uuid', jwt.data.id);
	postFormData.append('device', deviceDescription);

	const loadingToast = toast.loading('Enabling Notifications...');

	const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/add-fcm-token', {
		method: 'POST',
		body: postFormData,
	});

	const responseData = await response.json();

	toast.dismiss(loadingToast);

	if (responseData.message === 'success') {
		toast.success('Notifications Enabled!');
		setNotificationsEnabled(true);
		setIsButtonDisabled(false);
	} else if (responseData.message === 'exists') {
		toast.success('Notifications already enabled for this device.');
		setIsButtonDisabled(false);
	} else {
		toast.error('An error occurred while trying to enable notifications. Please try again.');
		setNotificationsEnabled(false);
		setIsButtonDisabled(false);
	}
};
