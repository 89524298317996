import { useState, useEffect } from 'react';

import FormData from 'form-data';

import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import useAuth from '../../hooks/useAuth';

const FmsChangePassword = () => {
    const { state } = useLocation();

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        isPasswordValid: true,
        isConfirmPasswordValid: true,
        isFormValid: true,
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const auth = useAuth();

    const navigate = useNavigate();

    // If user is authed, go straight to home screen
    useEffect(
        () => {
            if (auth) {
                navigate('/home');
            }
        },
        // eslint-disable-next-line
        []
    );

    const handleInputChange = (event, indicator) => {
        if (indicator === 'password') {
            setFormData({ ...formData, password: event.target.value });
        } else {
            setFormData({ ...formData, confirmPassword: event.target.value });
        }
    };

    const validateForm = (event) => {
        event.preventDefault();

        setIsButtonDisabled(true);

        let validForm = true;
        let validPassword = true;
        let validConfirmPassword = true;

        // Validate password
        if (formData.password.length < 8) {
            validPassword = false;
            validForm = false;
        }

        // Validate confirm password
        if (formData.password.length > 0 && formData.password !== formData.confirmPassword) {
            validConfirmPassword = false;
            validForm = false;
        }

        setFormData({
            ...formData,
            isFormValid: validForm,
            isPasswordValid: validPassword,
            isConfirmPasswordValid: validConfirmPassword,
        });

        if (!validForm) {
            // form is invalid
            // show toast
            toast.error('One or more form inputs are invalid. Please check your inputs carefully.');
            setIsButtonDisabled(false);
        } else {
            // input is valid, send registration request
            changePassword();
        }
    };

    const changePassword = async () => {
        let postFormData = new FormData();

        postFormData.append('email', state.email);
        postFormData.append('password', formData.password);

        const loadingToast = toast.loading('Setting new password...');

        // ! POSSIBLE RESPONSES: success, fail, no user, status fail
        const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/fms-change-password', {
            method: 'POST',
            body: postFormData,
        });

        const responseData = await response.json();

        switch (responseData.message) {
            case 'success':
                // Registration successful, show toast, and navigate to verification screen with name and email as parameters
                toast.dismiss(loadingToast);
                toast.success('Registration complete. You may now sign in to the application.');

                setTimeout(() => {
                    navigate('/sign-in');
                }, 3000);
                break;
            case 'fail':
                // Registration failed, show toast
                toast.dismiss(loadingToast);
                toast.error('Your password could not be set at this time. Please go and see Matt or Richard to assist you.');
                setIsButtonDisabled(false);
                break;
            case 'no user':
                // FMS user not present in staff users table
                toast.dismiss(loadingToast);
                toast.error('Your user account is not present in the staff users table. Please go and see Matt or Richard for investigation.');
                setIsButtonDisabled(false);
                break;
            case 'status fail':
                // FMS user not present in staff users table
                toast.dismiss(loadingToast);
                toast.error(
                    'Something critical went wrong during this process. Please go and see Matt and tell him that your registration status did not update. He will know what to do.'
                );
                setIsButtonDisabled(false);
                break;
            default:
                break;
        }
    };

    return (
        <div className='verification-form-container'>
            <form method='post' className='verification-form'>
                <img className='auth-logo' src='/img/fms_logo.png' alt='Auth-Logo' />
                <div className='info-text-container'>
                    <p className='verification-form-text'>Please set a new password for your account. Your password needs to be at least 8 characters long.</p>
                </div>
                <div className='form-control'>
                    <label className='form-control-label'>New Password</label>
                    <input
                        type='password'
                        className='form-input'
                        onChange={(event) => {
                            handleInputChange(event, 'password');
                        }}
                    />
                    {!formData.isPasswordValid && <p className='form-input-error'>Password must be 8 characters or more.</p>}
                </div>
                <div className='form-control'>
                    <label className='form-control-label'>Confirm New Password</label>
                    <input
                        type='password'
                        className='form-input'
                        onChange={(event) => {
                            handleInputChange(event, 'confirm-password');
                        }}
                    />
                    {!formData.isConfirmPasswordValid && <p className='form-input-error'>Passwords don't match.</p>}
                </div>
                <input
                    type='submit'
                    value='Change Password'
                    className='btn btn-primary-solid'
                    onClick={(event) => validateForm(event)}
                    disabled={isButtonDisabled}
                />
            </form>
        </div>
    );
};

export default FmsChangePassword;
