import './Settings.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import NavigationSettings from '../../components/NavigationSettings';
import ScreenHeading from '../../components/ScreenHeading';
import BuyerMarketDropdown from '../../components/BuyerMarketDropdown';
import CustomLoader from '../../components/CustomLoader';

import useJwt from '../../hooks/useJwt';

const SetPrimaryMarketScreen = () => {
	const [primaryMarketDescription, setPrimaryMarketDescription] = useState('');
	const [markets, setMarkets] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [onlyOneMarket, setOnlyOneMarket] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [selectedMarket, setSelectedMarket] = useState('');
	const [marketName, setMarketName] = useState('');
	const [isMarketDropdownOpen, setIsMarketDropdownOpen] = useState(false);
	const jwt = useJwt();
	const navigate = useNavigate();

	useEffect(
		() => {
			setIsLoading(true);
			getPrimaryMarketDescription();
			getBuyerMarkets();
		},
		// eslint-disable-next-line
		[],
	);

	const getPrimaryMarketDescription = async () => {
		const pmDesc = await localStorage.getItem('pmDesc');

		if (pmDesc !== '' || pmDesc !== null || pmDesc !== undefined) {
			setPrimaryMarketDescription(pmDesc);
		}
	};

	// called getBuyerMarkets, but this will be used to get any user markets
	const getBuyerMarkets = async () => {
		setIsLoading(true);
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/get-buyer-markets', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setMarkets(responseData.markets);

			if (responseData.markets.length === 1) {
				setOnlyOneMarket(true);
			}

			setIsLoading(false);
		} else {
			setIsLoading(false);
			toast.error('Unable to fetch buyer markets at this time. Please try again.');
		}
	};

	const setMarketCode = async (marketCode) => {
		setSelectedMarket(marketCode);

		// get market description
		const marketFilter = markets.find((market) => {
			return market.market_code === marketCode;
		});

		setMarketName(marketFilter.market_name);
	};

	const setPrimaryMarket = async () => {
		setIsButtonDisabled(true);

		let postFormData = new FormData();

		postFormData.append('market', selectedMarket);
		postFormData.append('uuid', jwt.data.id);

		const loadingToast = toast.loading('Setting primary market...');

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/core/set-primary-market', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.success('Primary market changed!');
			localStorage.setItem('pm', selectedMarket);
			localStorage.setItem('pmDesc', marketName);
			setPrimaryMarketDescription(marketName);

			setTimeout(() => {
				navigate('/app-settings');
			}, 2000);
		} else {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to set primary market at this time. Please try again.');
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching primary market information...' />}
			<NavigationSettings />
			<ScreenHeading heading='Primary Market' />
			<div className='primary-market-settings-screen'>
				<div className='primary-market-settings-container'>
					<div className='primary-market-container'>
						<p className='primary-market-text'>Your primary market is currently:</p>
						<h3 className='primary-market'>{primaryMarketDescription}</h3>
					</div>

					{!onlyOneMarket ? (
						<>
							<div className='primary-market-info-container'>
								You may change your primary market by selecting one of your registered markets below.
							</div>
							<BuyerMarketDropdown
								markets={markets}
								setSelectedMarket={setSelectedMarket}
								isMarketDropdownOpen={isMarketDropdownOpen}
								setIsMarketDropdownOpen={setIsMarketDropdownOpen}
								clickFunction={setMarketCode}
							/>
							<input
								type='button'
								className='btn btn-primary-solid primary-market-btn'
								value='Set Primary Market'
								disabled={isButtonDisabled}
								onClick={setPrimaryMarket}
							/>
						</>
					) : (
						<>
							<div className='primary-market-info-container'>
								You are only registered for one market. This market will be your primary market by default. If your role allows, you may
								register for more markets and set one of them as your primary market.
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default SetPrimaryMarketScreen;
