import { useState, useEffect, useRef } from 'react';

import useJwt from '../../hooks/useJwt';
import FormData from 'form-data';
import toast from 'react-hot-toast';

import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';

const NewCommodityScreen = () => {
	const [formData, setFormData] = useState({
		product: '',
		variety: '',
		container: '',
		files: [],
		isFormValid: false,
		isProductValid: true,
		isVarietyValid: true,
	});
	const [products, setProducts] = useState([]);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const [isProductLookupVisible, setIsProductLookupVisible] = useState(false);
	const [isClickable, setIsClickable] = useState(true);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const hiddenFileInput = useRef(null);
	const jwt = useJwt();

	useEffect(
		() => {
			const fetchProductsForLookup = async () => {
				let postFormData = new FormData();

				postFormData.append('uuid', jwt.data.id);

				const response = await fetch('https://staffapi.freshmarksystems.co.za/core/get-product-descriptions', {
					method: 'POST',
					body: postFormData,
				});

				const responseData = await response.json();

				if (responseData.message === 'success') {
					setProducts(responseData.products);
					setFilteredProducts(responseData.products);
				} else {
					toast.error('Unable to fetch products at this time. Please try again.');
				}
			};

			fetchProductsForLookup();
		},
		// eslint-disable-next-line
		[],
	);

	const filterProducts = (event) => {
		setIsProductLookupVisible(true);
		setIsClickable(true);
		setFormData({ ...formData, product: event.target.value });

		const productLookup = products.filter((product) => {
			return product.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
		});

		if (event.target.value === '') {
			setIsProductLookupVisible(false);
		}

		if (productLookup.length === 0) {
			// no products match search
			setIsClickable(false);
			setFilteredProducts(['No products matching search']);
		} else {
			setFilteredProducts(productLookup);
		}
	};

	const handleProductClick = (product) => {
		if (isClickable) {
			setFormData({ ...formData, product: product });
			setIsProductLookupVisible(false);
		} else {
			return false;
		}
	};

	const handleInputChange = (event, indicator) => {
		if (indicator === 'variety') {
			setFormData({ ...formData, variety: event.target.value });
		} else if (indicator === 'container') {
			setFormData({ ...formData, container: event.target.value });
		}
	};

	// Programmatically click the file input when the button is clicked
	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	const handleChange = (event) => {
		if (event.target.files.length > 0) {
			// validate files
			validateFiles(event.target.files[0].size, event.target.files[0].type, event.target.files[0]);
		}
	};

	const removeAttachedFile = (event) => {
		const newFiles = formData.files.filter((_, index) => {
			return index !== parseInt(event.target.id);
		});

		setFormData({ ...formData, files: newFiles });
	};

	const validateFiles = (size, type, file) => {
		const maxSize = 10 * 1024 * 1024;
		let canAddFile = true;

		if (size > maxSize) {
			canAddFile = false;
		}

		const fileTypeArray = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];

		if (!fileTypeArray.includes(type)) {
			canAddFile = false;
		}

		if (canAddFile) {
			// Add files to file array
			setFormData({ ...formData, files: [...formData.files, file] });
		} else {
			toast.error('Unable to upload file. Either the file is too large, or the file type is unsupported.', { duration: 5000 });
		}
	};

	const validateForm = (event) => {
		setIsButtonDisabled(true);

		let validForm = true;
		let validProduct = true;
		let validVariety = true;

		// let validContainers = true; - not mandatory

		// Validate product
		if (formData.product === '') {
			validForm = false;
			validProduct = false;
		}

		// Validate variety
		if (formData.variety === '') {
			validForm = false;
			validVariety = false;
		}

		setFormData({ ...formData, isFormValid: validForm, isProductValid: validProduct, isVarietyValid: validVariety });

		if (!validForm) {
			toast.error('One or more form inputs are invalid. Please check your inputs carefully.');
			setIsButtonDisabled(false);
		} else {
			requestNewCommodity();
		}
	};

	const requestNewCommodity = async () => {
		setIsButtonDisabled(true);

		let postFormData = new FormData();

		postFormData.append('product', formData.product);
		postFormData.append('variety', formData.variety);
		postFormData.append('container', formData.container);

		for (let i = 0; i < formData.files.length; i++) {
			postFormData.append('attachments[]', formData.files[i]);
		}

		postFormData.append('uuid', jwt.data.id);

		const loadingToast = toast.loading('Requesting new commodity...');

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/support/new-commodity-request', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'sent') {
			toast.dismiss(loadingToast);
			toast.success('New commodity request sent!', { duration: 2000 });
			setIsButtonDisabled(false);

			// clear all inputs
			setFormData({
				product: '',
				variety: '',
				container: '',
				files: [],
				isFormValid: false,
				isProductValid: true,
				isVarietyValid: true,
			});
		} else if (responseData.message === 'none') {
			toast.dismiss(loadingToast);
			toast.error('Unable to send request to Market Systems Admins. Please contact market for support.', { duration: 2000 });
			setIsButtonDisabled(false);
		} else {
			toast.dismiss(loadingToast);
			toast.error('Unable to create new commodity request. Please try again.', { duration: 2000 });
			setIsButtonDisabled(false);
		}
	};

	return (
		<>
			<NavigationBack />
			<ScreenHeading heading='New Commodity' />
			<div className='new-commodity-screen'>
				<div className='new-commodity-container'>
					<div className='form-control'>
						<label className='form-control-label'>Search Product</label>
						<input
							value={formData.product}
							className='form-input'
							onChange={(event) => {
								filterProducts(event);
							}}
						/>
						{!formData.isProductValid && <p className='form-input-error'>Please select a product.</p>}
					</div>
					{isProductLookupVisible && (
						<div className='new-commodity-products'>
							{filteredProducts.map((product, index) => (
								<div
									key={index}
									className='new-commodity-product'
									onClick={() => {
										handleProductClick(product);
									}}>
									{product}
								</div>
							))}
						</div>
					)}
					<div className='form-control'>
						<label className='form-control-label'>Variety Description</label>
						<input
							value={formData.variety}
							className='form-input'
							onChange={(event) => {
								handleInputChange(event, 'variety');
							}}
						/>
						{!formData.isVarietyValid && <p className='form-input-error'>Invalid variety description.</p>}
					</div>
					<div className='form-control'>
						<label className='form-control-label'>Container Description</label>
						<textarea
							value={formData.container}
							className='form-input form-textarea'
							rows='10'
							onChange={(event) => {
								handleInputChange(event, 'container');
							}}></textarea>
					</div>
					{formData.files.length > 0 && (
						<div className='attached-files'>
							<label className='form-control-label'>Attached Images</label>
							{formData.files.map((file, index) => {
								return (
									<div key={index} className='attached-file'>
										<div className='attached-filename'>{file.name}</div>
										<svg className='remove-attached-file' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'>
											<path
												id={index}
												onClick={removeAttachedFile}
												d='M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z'
											/>
										</svg>
									</div>
								);
							})}
						</div>
					)}
					<input type='file' ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
					<input type='button' value='Add Image' className='btn btn-primary-outline mt-md' onClick={handleClick} />
					<input
						type='submit'
						value='Request New Commodity'
						className='btn btn-primary-solid mt-md'
						onClick={(event) => validateForm(event)}
						disabled={isButtonDisabled}
					/>
				</div>
			</div>
		</>
	);
};

export default NewCommodityScreen;
