import React from 'react';

const AgentCodeInput = ({ formData, setFormData }) => {
	const handleInputChange = (event) => {
		setFormData({ ...formData, agentCode: event.target.value });
	};

	return (
		<div className='registration-control-container'>
			<div className='registration-control'>
				<label className='registration-control-label'>Agent Code</label>
				<input type='text' className='registration-code-input' value={formData.agentCode} onChange={handleInputChange} />
			</div>
		</div>
	);
};

export default AgentCodeInput;
