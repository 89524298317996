import { useState, useEffect } from 'react';
import './Auth.css';

import FormData from 'form-data';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import useAuth from '../../hooks/useAuth';
import AuthLogo from '../../components/AuthLogo';

const ForgotPasswordScreen = () => {
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		isEmailValid: true,
		isPasswordValid: true,
		isConfirmPasswordValid: true,
		isFormValid: true,
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const auth = useAuth();

	const navigate = useNavigate();

	// If user is authed, go straight to home screen
	useEffect(
		() => {
			if (auth) {
				navigate('/home');
			}
		},
		// eslint-disable-next-line
		[],
	);

	const handleInputChange = (event, indicator) => {
		if (indicator === 'email') {
			setFormData({ ...formData, email: event.target.value });
		} else if (indicator === 'password') {
			setFormData({ ...formData, password: event.target.value });
		} else {
			setFormData({ ...formData, confirmPassword: event.target.value });
		}
	};

	const validateForm = (event) => {
		event.preventDefault();

		setIsButtonDisabled(true);

		let validForm = true;
		let validEmail = true;
		let validPassword = true;
		let validConfirmPassword = true;

		// Validate email
		if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)) {
			validEmail = false;
			validForm = false;
		}

		// Validate password
		if (formData.password.length < 8) {
			validPassword = false;
			validForm = false;
		}

		// Validate confirm password
		if (formData.password.length > 0 && formData.password !== formData.confirmPassword) {
			validConfirmPassword = false;
			validForm = false;
		}

		setFormData({
			...formData,
			isFormValid: validForm,
			isEmailValid: validEmail,
			isPasswordValid: validPassword,
			isConfirmPasswordValid: validConfirmPassword,
		});

		if (!validForm) {
			// form is invalid
			// show toast
			toast.error('One or more form inputs are invalid. Please check your inputs carefully.');
			setIsButtonDisabled(false);
		} else {
			// input is valid, send registration request
			changePassword();
		}
	};

	const changePassword = async () => {
		let postFormData = new FormData();

		postFormData.append('email', formData.email);
		postFormData.append('password', formData.password);

		const loadingToast = toast.loading('Sending request for password change...');

		// ! POSSIBLE RESPONSES: success, fail
		const response = await fetch('https://staffapi.freshmarksystems.co.za/authentication/request-password-change', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		// ! TODO - WORK ON THE VERIFY PASSWORD CHANGE SCREEN, TEST THIS PROCESS FIRST!

		switch (responseData.message) {
			case 'success':
				// Registration successful, show toast, and navigate to verification screen with name and email as parameters
				toast.dismiss(loadingToast);
				toast.success('Request for password change successful. Redirecting to verification screen...');

				setTimeout(() => {
					navigate('/verify-password-change', { state: { email: formData.email } });
				}, 3000);
				break;
			case 'fail':
				// Registration failed, show toast
				toast.dismiss(loadingToast);
				toast.error('Request for password change cannot be processed at this time. Please try again soon, or contact support for help.');
				setIsButtonDisabled(false);
				break;
			default:
				break;
		}
	};

	return (
		<div className='verification-form-container'>
			<form method='post' className='verification-form'>
				<AuthLogo />
				<div className='form-control'>
					<label className='form-control-label'>Email</label>
					<input type='email' className='form-input' onChange={(event) => handleInputChange(event, 'email')} />
					{!formData.isEmailValid && <p className='form-input-error'>Invalid email.</p>}
				</div>
				<div className='form-control'>
					<label className='form-control-label'>New Password</label>
					<input
						type='password'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'password');
						}}
					/>
					{!formData.isPasswordValid && <p className='form-input-error'>Password must be 8 characters or more.</p>}
				</div>
				<div className='form-control'>
					<label className='form-control-label'>Confirm New Password</label>
					<input
						type='password'
						className='form-input'
						onChange={(event) => {
							handleInputChange(event, 'confirm-password');
						}}
					/>
					{!formData.isConfirmPasswordValid && <p className='form-input-error'>Passwords don't match.</p>}
				</div>
				<input
					type='submit'
					value='Change Password'
					className='btn btn-primary-solid'
					onClick={(event) => validateForm(event)}
					disabled={isButtonDisabled}
				/>
				<input type='button' value='Back' className='btn btn-primary-outline mt-sm' onClick={() => navigate('/sign-in')} disabled={isButtonDisabled} />
			</form>
		</div>
	);
};

export default ForgotPasswordScreen;
