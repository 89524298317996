import { useState, useEffect, useRef } from 'react';

const UserMarketsDropdown = ({ markets, setSelectedMarket, isMarketDropdownOpen, setIsMarketDropdownOpen }) => {
    // DROPDOWN STUFF
    const [selectedItem, setSelectedItem] = useState('');
    const ref = useRef();

    // Handle outside click to close dropdown
    useEffect(
        () => {
            const checkIfClickedOutside = (event) => {
                if (isMarketDropdownOpen && ref.current && !ref.current.contains(event.target)) {
                    setIsMarketDropdownOpen(false);
                }
            };

            document.addEventListener('mousedown', checkIfClickedOutside);

            return () => {
                document.removeEventListener('mousedown', checkIfClickedOutside);
            };
        },
        // eslint-disable-next-line
        [isMarketDropdownOpen]
    );

    const toggleDropdown = () => {
        setIsMarketDropdownOpen(!isMarketDropdownOpen);
    };

    const handleItemClick = (event) => {
        const idSplit = event.target.id.split('_');
        const marketName = idSplit[0];
        const marketCode = idSplit[1];
        setSelectedItem(marketName);
        setIsMarketDropdownOpen(!isMarketDropdownOpen);
        setSelectedMarket(marketCode);

        // Trigger function
        // trigger();
    };

    return (
        <div className='dropdown mt-md' ref={ref}>
            <div className={`dropdown-title ${isMarketDropdownOpen && `open`}`} onClick={toggleDropdown}>
                {selectedItem === '' ? 'Select Market' : selectedItem}
                <i className={`fa fa-chevron-down dropdown-icon ${isMarketDropdownOpen && 'open'}`}></i>
            </div>
            {isMarketDropdownOpen && (
                <div className='dropdown-body'>
                    <ul className='dropdown-items'>
                        {markets.map((market, index) => {
                            return (
                                <li
                                    className='dropdown-item'
                                    key={index}
                                    onClick={(event) => handleItemClick(event)}
                                    id={market.market_name + '_' + market.market_code}
                                >
                                    {market.market_name}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UserMarketsDropdown;
