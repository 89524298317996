import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import NavigationSettings from '../../components/NavigationSettings';
import ScreenHeading from '../../components/ScreenHeading';
import CustomLoader from '../../components/CustomLoader';
import EditDeviceModal from '../../components/widgets/EditDeviceModal';

import useJwt from '../../hooks/useJwt';

const MyDevicesScreen = () => {
	const [devices, setDevices] = useState([]);
	const [noDevices, setNoDevices] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [deviceId, setDeviceId] = useState(0);
	const [deviceName, setDeviceName] = useState('');
	const [isNameValid, setIsNameValid] = useState(true);
	const [overlayState, setOverlayState] = useState('overlay-animate-out');
	const jwt = useJwt();
	const navigate = useNavigate();

	useEffect(
		() => {
			getDevices();
		},
		// eslint-disable-next-line
		[],
	);

	const getDevices = async () => {
		setIsLoading(true);
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/core/get-user-devices', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			if (responseData.devices.length === 0) {
				setNoDevices(true);
			} else {
				setDevices(responseData.devices);
				setIsLoading(false);
			}
		} else {
			toast.error('Unable to fetch devices. Navigating back to Settings...');

			setTimeout(() => {
				navigate('/app-settings', { replace: true });
			}, 3000);
		}
	};

	const editDevice = (event) => {
		setDeviceId(event.target.id);
		// console.log(event.target.id);
		const deviceName = devices.find((d) => {
			return d.id.toString() === event.target.id;
		});
		// console.log(deviceName.device_name);
		setDeviceName(deviceName.device_name);

		setOverlayState('overlay-animate-in');
	};

	const handleInputChange = (event) => {
		setDeviceName(event.target.value);
	};

	const submitDeviceChange = async () => {
		if (deviceName === '') {
			setIsNameValid(false);
			return;
		}

		const loadingToast = toast.loading('Updating device name...');

		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('deviceId', deviceId);
		postFormData.append('deviceName', deviceName);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/core/update-device', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.success('Device name changed!');
			getDevices();
			setOverlayState('overlay-animate-out');
		} else {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to change device name.');
		}
	};

	const closeOverlay = () => {
		const deviceName = devices.find((d) => {
			return d.id.toString() === deviceId;
		});

		setDeviceName(deviceName.device_name);
		setOverlayState('overlay-animate-out');
	};

	const deleteDevice = async () => {
		setIsButtonDisabled(true);
		const loadingToast = toast.loading('Deleting device...');

		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('deviceId', deviceId);

		// ! POSSIBLE RESPONSES: sent, error, forbidden
		const response = await fetch('https://staffapi.freshmarksystems.co.za/core/delete-device', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.message === 'success') {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.success('Device deleted!');
			getDevices();
			setOverlayState('overlay-animate-out');
		} else {
			setIsButtonDisabled(false);
			toast.dismiss(loadingToast);
			toast.error('Unable to delete device.');
		}
	};

	return (
		<>
			{isLoading && <CustomLoader text='Fetching user devices...' />}
			<EditDeviceModal
				value={deviceName}
				onChange={handleInputChange}
				acceptClick={submitDeviceChange}
				cancelClick={closeOverlay}
				isNameValid={isNameValid}
				overlayState={overlayState}
				isButtonDisabled={isButtonDisabled}
			/>
			<NavigationSettings />
			<ScreenHeading heading='My Devices' />
			<div className='buyer-enquiry-data-screen'>
				<div className='buyer-enquiry-data-container'>
					{noDevices && <div className='no-pending-notifications-container'>No devices with notifications turned on.</div>}
					{devices.map((d, index) => {
						return (
							<div className='device-row mb-md' key={index}>
								<div className='device-name'>{d.device_name}</div>
								<div className='device-actions'>
									<input type='button' id={d.id} className='btn-xs btn-primary-solid device-btn' value='Edit' onClick={editDevice} />
									<input type='button' id={d.id} className='btn-xs btn-danger-solid device-btn' value='Delete' onClick={deleteDevice} />
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default MyDevicesScreen;
