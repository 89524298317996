import './Widget.css';

import { useNavigate } from 'react-router-dom';

const SupportRequestWidget = () => {
	const navigate = useNavigate();

	return (
		<div
			className='widget'
			onClick={() => {
				navigate('/new-support-ticket');
			}}>
			<svg version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 285.000000 285.000000' preserveAspectRatio='xMidYMid meet'>
				<g transform='translate(0.000000,285.000000) scale(0.100000,-0.100000)'>
					<path d='M223 2836 c-94 -23 -173 -94 -205 -184 -10 -30 -13 -275 -13 -1227 0 -1107 1 -1193 17 -1233 28 -69 73 -118 140 -151 l61 -31 1201 0 c1186 0 1202 1 1246 21 60 27 117 83 146 144 l24 50 0 1200 0 1200 -24 51 c-42 90 -114 145 -217 164 -83 16 -2310 12 -2376 -4z m2408 -48 c51 -15 114 -69 142 -122 l22 -41 0 -1200 0 -1200 -22 -40 c-25 -47 -64 -85 -113 -111 -33 -18 -86 -19 -1215 -22 -909 -2 -1190 0 -1225 10 -59 16 -144 98 -159 153 -8 28 -11 399 -11 1220 0 1121 1 1182 19 1220 23 52 74 103 126 126 38 18 99 19 1219 19 866 0 1189 -3 1217 -12z' />
					<path d='M1833 2581 c-75 -47 -53 -165 34 -187 14 -4 42 -1 63 6 36 12 41 11 92 -20 362 -222 575 -651 529 -1070 -58 -542 -469 -953 -1011 -1011 -85 -10 -134 -10 -217 0 -60 6 -117 13 -128 14 -14 1 -20 -4 -20 -18 0 -17 10 -22 65 -31 96 -17 284 -16 386 1 565 99 974 584 974 1155 0 395 -202 767 -531 982 -76 49 -79 53 -79 90 0 80 -89 131 -157 89z m91 -57 c9 -8 16 -22 16 -29 0 -44 -73 -61 -91 -21 -23 50 36 90 75 50z' />
					<path d='M1226 2574 c-705 -127 -1133 -849 -905 -1528 71 -212 213 -414 385 -546 46 -36 52 -45 57 -86 5 -52 26 -79 72 -94 45 -15 98 7 120 50 47 90 -55 185 -145 136 -23 -13 -28 -11 -83 33 -293 229 -458 622 -418 991 58 535 463 946 994 1010 53 6 128 10 166 8 74 -3 87 2 78 27 -5 12 -27 15 -124 14 -65 0 -154 -7 -197 -15z m-328 -2126 c20 -20 14 -57 -12 -69 -45 -21 -79 16 -60 65 7 19 55 21 72 4z' />
					<path d='M1657 2563 c-13 -12 -7 -41 10 -47 21 -8 48 17 40 38 -6 16 -38 22 -50 9z' />
					<path d='M1257 2358 c-3 -13 -11 -64 -17 -114 l-13 -92 -41 -12 c-22 -7 -63 -23 -89 -36 -26 -14 -53 -21 -60 -18 -7 4 -47 34 -91 67 l-78 60 -119 -119 -118 -119 66 -88 65 -87 -32 -78 c-18 -42 -36 -84 -40 -92 -5 -10 -40 -20 -111 -30 l-104 -15 -3 -167 -2 -166 27 -6 c16 -3 64 -10 109 -17 l80 -11 38 -94 37 -94 -59 -78 c-32 -42 -61 -82 -65 -88 -3 -6 47 -63 112 -128 l117 -117 87 66 87 66 94 -37 94 -38 11 -80 c7 -45 14 -93 17 -109 l6 -27 166 2 167 3 15 104 c10 71 20 106 30 111 8 4 50 22 92 40 l78 32 87 -65 88 -66 119 118 119 119 -60 78 c-33 44 -63 84 -67 91 -3 7 4 34 18 60 13 26 29 67 36 89 l12 41 92 13 c154 20 137 -4 134 189 l-3 166 -110 15 -109 15 -26 70 c-15 39 -32 80 -39 93 -11 20 -6 30 47 100 32 42 62 83 66 91 6 9 -25 47 -105 127 -63 63 -116 114 -120 114 -3 0 -45 -29 -93 -65 -78 -59 -88 -64 -109 -53 -12 6 -53 23 -92 38 l-70 26 -15 109 -15 110 -166 3 c-166 2 -167 2 -172 -20z m303 -143 l13 -104 52 -16 c29 -8 83 -30 121 -49 l69 -35 35 24 c19 14 58 43 85 64 l50 39 82 -80 81 -81 -64 -85 c-35 -47 -63 -88 -61 -91 23 -41 78 -165 86 -197 l11 -41 105 -13 105 -13 0 -117 0 -117 -105 -13 -105 -13 -11 -41 c-8 -32 -63 -156 -86 -197 -2 -3 26 -44 61 -91 l64 -85 -81 -81 -82 -80 -50 39 c-27 21 -66 50 -85 64 l-35 24 -69 -35 c-38 -19 -92 -41 -121 -49 l-52 -16 -13 -104 -13 -105 -117 0 -117 0 -13 105 -13 104 -52 16 c-29 8 -83 30 -121 49 l-69 35 -35 -24 c-19 -14 -57 -43 -85 -64 l-50 -39 -82 80 -81 81 64 85 c35 47 63 88 61 91 -23 41 -78 165 -86 197 l-11 41 -105 13 -105 13 0 117 0 117 105 13 105 13 11 41 c8 32 63 156 86 197 2 3 -26 44 -61 91 l-64 85 81 81 82 80 50 -39 c28 -21 66 -50 85 -64 l35 -24 69 35 c38 19 92 41 121 49 l52 16 13 104 13 105 117 0 117 0 13 -105z' />
					<path d='M1350 1806 c-164 -35 -281 -157 -312 -324 -48 -257 199 -502 455 -452 314 61 437 429 221 660 -53 57 -142 105 -223 120 -64 12 -70 12 -141 -4z m181 -67 c49 -12 122 -63 160 -110 46 -58 69 -128 69 -209 0 -92 -28 -165 -88 -229 -128 -137 -334 -142 -475 -12 -99 91 -131 252 -75 372 75 163 228 233 409 188z' />
					<path d='M1037 353 c-12 -11 -7 -41 7 -46 21 -8 50 17 43 37 -6 16 -38 22 -50 9z' />
				</g>
			</svg>
			<div className='widget-text'>Support Request</div>
		</div>
	);
};

export default SupportRequestWidget;
