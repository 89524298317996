import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const NavigationHome = () => {
	const navigate = useNavigate();

	return (
		<nav className='navbar'>
			<div className='navigation-home' onClick={() => navigate('/home')}>
				<FaChevronLeft />
				<span className='navigation-home-text'>Home</span>
			</div>
		</nav>
	);
};

export default NavigationHome;
