import { useState, useEffect } from 'react';
import useJwt from '../../hooks/useJwt';
import BuyerAccountStatusWidget from '../../components/widgets/BuyerAccountStatusWidget';
import CustomLoader from '../../components/CustomLoader';
import NavigationBack from '../../components/NavigationBack';
import ScreenHeading from '../../components/ScreenHeading';
// import { useNavigate } from 'react-router-dom';

const BuyerAccountWidgetsScreen = () => {
    const [widgetsLoading, setWidgetsLoading] = useState(false);
    // const [hasData, setHasData] = useState(false);
    const [buyerAccountWidgetData, setBuyerAccountWidgetData] = useState({
        buyerData: [],
        markets: [],
        buyerNumbers: [],
        marketNames: [],
    });

    // const navigate = useNavigate();
    const jwt = useJwt();

    useEffect(
        () => {
            getBuyerWidgetData();
        }, // eslint-disable-next-line
        []
    );

    const getBuyerWidgetData = async () => {
        setWidgetsLoading(true);
        let postFormData = new FormData();

        postFormData.append('uuid', jwt.data.id);

        // Send request to app backend
        const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/get-buyer-widget-data', {
            method: 'POST',
            body: postFormData,
        });

        const responseData = await response.json();

        if (responseData.response !== 'none') {
            // set the state of the data
            setBuyerAccountWidgetData({
                ...buyerAccountWidgetData,
                buyerData: responseData.response.buyerData,
                markets: responseData.response.markets,
                buyerNumbers: responseData.response.buyerNumbers,
                marketNames: responseData.response.marketNames,
            });

            setWidgetsLoading(false);
        } else {
            setWidgetsLoading(false);
        }
    };

    return (
        <>
            {widgetsLoading && <CustomLoader text='Fetching buyer widgets...' />}
            <NavigationBack />
            <ScreenHeading heading='Buyer Account Widgets' />
            <div className='buyer-widgets'>
                <div className='buyer-widgets-container'>
                    {buyerAccountWidgetData.buyerData.length > 0 &&
                        buyerAccountWidgetData.buyerData.map((bd, index) => {
                            return (
                                <BuyerAccountStatusWidget
                                    data={buyerAccountWidgetData.buyerData[index]}
                                    market={buyerAccountWidgetData.markets[index]}
                                    marketDescription={buyerAccountWidgetData.marketNames[index]}
                                    buyerNumber={buyerAccountWidgetData.buyerNumbers[index]}
                                    key={index}
                                />
                            );
                        })}
                    {buyerAccountWidgetData.buyerData.length === 0 && (
                        <>
                            <div className='no-widget-data'>
                                It appears you have not added any buyer account widgets to this screen yet. If you wish to do so, please navigate to the Balance
                                Enquiry Screen, select the market and account number you would like to query, and then click the <strong>Add Widget</strong>{' '}
                                button at the bottom.
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default BuyerAccountWidgetsScreen;
