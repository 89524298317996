import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useJwt from '../../hooks/useJwt';

const BuyerAccountStatusWidget = ({ data, market, buyerNumber, marketDescription }) => {
	const [balance, setBalance] = useState(data.result.balance.toFixed(2));
	const [widgetLoading, setWidgetLoading] = useState(false);
	const navigate = useNavigate();
	const jwt = useJwt();

	const formatNumber = (number) => {
		var numberParts = number.toString().split('.');
		numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		return numberParts.join('.');
	};

	const getDate = () => {
		const d = new Date();
		let year = '' + d.getFullYear();
		let month = '' + (d.getMonth() + 1);
		let day = '' + d.getDate();

		if (month.length === 1) {
			month = '0' + month;
		}

		const concatDate = year + '-' + month + '-' + day;

		const time = d.toLocaleTimeString();

		const formattedDate = new Date(concatDate).toLocaleString('en-us', { month: 'short', year: 'numeric', day: 'numeric' });

		const hour = time.split(':')[0];
		let descriptor = '';

		if (parseInt(hour) < 12) {
			descriptor = 'AM';
		} else {
			descriptor = 'PM';
		}

		const formattedTime = time + ' ' + descriptor;

		return formattedDate + ' ' + formattedTime;
	};

	const goToTransactions = () => {
		navigate('/buyer-balance', {
			state: { result: data.result, buyerNumber: buyerNumber, market: marketDescription, marketCode: market },
		});
	};

	const refreshWidget = async () => {
		setWidgetLoading(true);
		let postFormData = new FormData();

		postFormData.append('uuid', jwt.data.id);
		postFormData.append('buyer_number', buyerNumber);
		postFormData.append('market', market);

		// Send request to app backend
		const response = await fetch('https://staffapi.freshmarksystems.co.za/buyer/get-single-buyer-account-balance', {
			method: 'POST',
			body: postFormData,
		});

		const responseData = await response.json();

		if (responseData.respone !== 'none') {
			setBalance(responseData.response.result.balance.toFixed(2));

			setWidgetLoading(false);
		}
	};

	return (
		<>
			{widgetLoading ? (
				<div className='buyer-home-widget-loader-container'>
					<img className='loader-gif' src='/img/loading.gif' alt='Loader-Gif' />
					<p className='loader-text'>Refreshing widget...</p>
				</div>
			) : (
				<div className='buyer-account-status-widget'>
					<h3 className='buyer-account-market'>{marketDescription}</h3>
					<h6 className='latest-snapshot'>Latest Snapshot: {getDate()}</h6>
					<div className='buyer-account-info-container'>
						<p className='buyer-account-info-text'>Available Balance</p>
						<p className='buyer-account-amount'>{`R ${formatNumber(balance)}`}</p>
					</div>
					<div className='buyer-account-widget-actions'>
						<input type='button' className='btn-sm btn-primary-solid' value='Refresh' disabled={false} onClick={refreshWidget} />
						<input type='button' className='btn-sm btn-primary-solid' value='Transactions' disabled={false} onClick={goToTransactions} />
					</div>
				</div>
			)}
		</>
	);
};

export default BuyerAccountStatusWidget;
