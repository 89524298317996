import { useState } from 'react';

import toast from 'react-hot-toast';

const SupplierNumberInputs = ({ formData, setFormData }) => {
	const [inputFields, setInputFields] = useState([{ supplierNumber: '' }]);

	const handleInputChange = (event, index) => {
		const values = [...inputFields];

		values[index].supplierNumber = event.target.value;

		setInputFields(values);
		setFormData({ ...formData, supplierNumbers: inputFields });
	};

	const addSupplierNumber = () => {
		if (inputFields.length === 5) {
			toast.error('Maximum of 5 Supplier Numbers allowed per request.');
		} else {
			setInputFields([...inputFields, { supplierNumber: '' }]);
			setFormData({ ...formData, supplierNumbers: [...formData.supplierNumbers, inputFields] });
		}
	};

	const removeSupplierNumber = () => {
		inputFields.pop();

		setInputFields([...inputFields]);
	};

	const handleControlChanges = () => {
		if (inputFields.length === 1) {
			return <input type='button' value='Add Supplier Number' className='btn btn-primary-outline mt-md' onClick={addSupplierNumber} />;
		}

		if (inputFields.length > 1 && inputFields.length < 5) {
			// Side by side
			return (
				<div className='inputs-control'>
					<input type='button' value='Add Supplier Number' className='btn-md btn-primary-outline mt-md' onClick={addSupplierNumber} />
					<input type='button' value='Remove Supplier Number' className='btn-md btn-danger-outline mt-md' onClick={removeSupplierNumber} />
				</div>
			);
		}

		if (inputFields.length === 5) {
			return <input type='button' value='Remove Supplier Number' className='btn btn-danger-outline mt-md' onClick={removeSupplierNumber} />;
		}
	};

	return (
		<div className='registration-control-container'>
			{inputFields.map((inputField, index) => {
				return (
					<div className='registration-control' key={index}>
						<label className='registration-control-label'>Supplier Number</label>
						<input
							type='text'
							className='registration-code-input'
							value={inputField.buyerNumber}
							onChange={(event) => handleInputChange(event, index)}
						/>
					</div>
				);
			})}
			{handleControlChanges()}
		</div>
	);
};

export default SupplierNumberInputs;
